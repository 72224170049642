import React, {Component} from 'react';
import {AvForm} from 'availity-reactstrap-validation';
import {FormGroup} from "reactstrap";

import {get_css_class_and_with_variant, retrieveLanguageValue} from "../utils/helper";
import Strings from "../utils/strings";
import RMSFormField from "./RMSFormField";

import "./RMSForm.css";

import _ from 'lodash';

const ReservationInfoHeader = (props) => {
    return (
        <>
            <div className="enter-info-title">{props.headerText} </div>
        </>
    )
}

const ReservationInfo = (props) => {
    const imageCalendar = require('../images/kalenteri-ikoni.svg');
    const imageTime = require('../images/aika-iso-ikoni.svg');
    const imageOrganization = require('../images/organisaatio-iso-ikoni.svg');
    let mobileCss = "";
    if (props.isMobileReal) {
        mobileCss = "mobile-";
    }
    return (
        <>
            <div className="enter-major-items">
                <div className={mobileCss + "enter-service-location-info-header"}>
                    <img src={imageCalendar} className="confirm-thumbnail" alt="prop-img"/>
                    <div className={get_css_class_and_with_variant('enter-service-location-info')}>
                        <p>{props.reservationInfo.dateWeekDay}</p>
                        <p>{props.reservationInfo.date}</p>
                    </div>
                </div>
                <div className={mobileCss + "enter-service-location-info-header"}>
                    <img src={imageTime} className="confirm-thumbnail" alt="prop-img"/>
                    <div className={get_css_class_and_with_variant('enter-service-location-info')}>
                        <p>{retrieveLanguageValue(props.language, Strings.calendar.at)}</p>
                        <p>{props.reservationInfo.time}</p>
                    </div>
                </div>
                <div className={mobileCss + "enter-service-location-info-header"}>
                    <img src={imageOrganization} className="confirm-thumbnail" alt="prop-img"/>
                    <div className={get_css_class_and_with_variant('enter-service-location-info')}>
                        <p>{props.reservationInfo.selectedService}</p>
                        <p>{props.reservationInfo.selectedOffice}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

const StaticProperties = (props) => {
    if (props.mapped) {
        let staticBlocks = [];
        let blockNum = 1;
        for (const [key, value] of props.mapped.staticProperties) {

            let blockProperties = [];
            for (const valueSub of value.subProperties.values()) {
                const propValue = props.mapped.valueMap.get(valueSub.propertyId);
                blockProperties.push(
                    <>
                        <FormGroup key={key + "-" + valueSub.propertyId}>
                            <div className="form-inline">
                                <div className="form-group col-md-12 col-md-static">
                                    <label htmlFor={valueSub.propertyId}
                                           className="col-md-3 static-label-style">{retrieveLanguageValue(props.language, valueSub.name)}{`${valueSub.required ? " *" : ""}`}</label>
                                    <span className="col-md-9 form-control">{propValue.propertyValue}</span>
                                </div>
                            </div>
                        </FormGroup>
                    </>
                );
            }

            let sectionName = retrieveLanguageValue(props.language, value.name);
            staticBlocks.push(
                <>
                    <div className="sub-title">
                        {blockNum}. {sectionName}
                    </div>
                    {blockProperties}
                </>
            );
            blockNum++;
        }

        return (
            <>
                {staticBlocks}
            </>
        )
    } else {
        return (
            <>
            </>
        )
    }
}

const EditableProperties = (props) => {

    if (props.mapped && props.formValues) {

        let editableBlocks = [];
        let blockProperties = [];
        let blockNum = 1;
        if (props.editInfo === false) {
            blockNum += props.mapped.staticProperties.size;
        }
        for (const [key, value] of props.mapped.properties) {
            for (const valueSub of value.subProperties.values()) {

                blockProperties.push(
                    <>
                        <FormGroup key={key}>
                            <RMSFormField
                                language={props.language}
                                valueSub={valueSub}
                                formValues={props.formValues}
                                required={props.required}
                                onChangeCallback={props.formFieldChanged}
                                onBlurValidCallback={props.onBlurValidCallback}
                            />
                        </FormGroup>
                    </>
                );
            }

            let sectionName = retrieveLanguageValue(props.language, value.name);
            editableBlocks.push(
                <>
                    <div className="sub-title">
                        {blockNum}. {sectionName}
                    </div>
                    {blockProperties}
                </>
            );
            blockNum++;
        }
        return (
            <>
                <AvForm
                    className={"e-form"}
                    onValidSubmit={props.formValidSubmit}
                    onInvalidSubmit={props.formInvalidSubmit}
                >
                    {editableBlocks}
                    <div>
                        <div>
                        <span
                            className={`${props.mandatoryFieldMissing ? "rms-form-mandatoryFieldMissing" : "rms-form-mandatoryFieldResolved"}`}>
                            {retrieveLanguageValue(props.language, Strings.enterInfo.mandatoryFieldMissing)}
                        </span>
                        </div>
                        <div>
                            <button className={`${get_css_class_and_with_variant('rms-form-submit-Btn-bg')} `}>
                                {props.submitButtonText}
                            </button>
                        </div>
                    </div>

                </AvForm>
            </>
        )
    } else {
        return (
            <></>
        )
    }
}

class RMSForm extends Component {
    
    constructor(props) {
        super(props);

		this.state = {
			required: {...props.required},
			mandatoryFieldMissing: false,
			formValues: {...props.formValues},
			validChanged: false
		}
	}

    setNewRequired = (newRequired) => {
        this.setState({required: newRequired});
    }

    setNewFormValues = (newValues) => {
        this.setState({formValues: newValues});
    }
    
    findProperty = (name) => {
	
    	if (this.props.mapped) {
        	for (const [key, value] of this.props.mapped.properties) {
            	for (const valueSub of value.subProperties.values()) {
					if (valueSub.propertyId === name) {
						return valueSub;
					}
                }
			}
		}
		return null;
	}

    onBlurValidCallBack = (event) => {
        if (this.state.validChanged === false) {
			let prop = this.findProperty('useMessageSendingMethodSelection');
			if (prop && prop.options) {
				let validOption = _.some(prop.options, (opt) => opt.value === '2');
				if (validOption) {
					let newFormValues = {...this.state.formValues};
					newFormValues['useMessageSendingMethodSelection'] = '2';
					this.setNewFormValues(newFormValues);
				}
			}
            this.setState({validChanged: true });
        }

    }
    
    formFieldChangedCallBack = (event) => {
        if (event.target) {

            const newValue = event.target.value;
            const formField = event.target.name;

            let newFormValues = {...this.state.formValues};
            newFormValues[formField] = newValue;
            this.setNewFormValues(newFormValues);

            if (event.target.name === 'useMessageSendingMethodSelection') {
				this.afterMessageSendingMethodSet(event.target.value);
            }
        }
    }
    
    afterMessageSendingMethodSet = (value) => {
        let requiredFields = {...this.props.required};
        if (value === '0') {
            requiredFields['email'] = this.props.required['email'];
            requiredFields['phoneNumber'] = this.props.required['phoneNumber'];
        }
        if (value === '1') {
            requiredFields['email'] = true;
            requiredFields['phoneNumber'] = this.props.required['phoneNumber'];
        }
        if (value === '2') {
            requiredFields['email'] = this.props.required['email'];
            requiredFields['phoneNumber'] = true;
        }
        this.setNewRequired(requiredFields);
	} 

    formValidSubmitCallBack = () => {
        this.props.json.values.forEach((value) => {
            value.propertyValue = this.state.formValues[value.propertyId];
        });

        const submitData = {
            mapped: this.props.mapped,
            data: this.props.json,
        }
        this.props.handleSubmitForm(submitData);
    }

    formInvalidSubmitCallBack = () => {

    }
    
    componentDidMount() {
	    let prop = this.findProperty('useMessageSendingMethodSelection');
		if (prop && prop.defaultValue) {
			this.afterMessageSendingMethodSet(prop.defaultValue);
		}
	}
	
	render()  {		
	    return (
	        <div className={"enter-info"}>
	            <ReservationInfoHeader
	                headerText={this.props.rmsFormProps.headerText}
	                isMobileReal={this.props.rmsFormProps.isMobileReal}/>
	
	            <ReservationInfo
	                language={this.props.language}
	                reservationInfo={this.props.rmsFormProps.reservationInfo}
	                isMobileReal={this.props.rmsFormProps.isMobileReal}/>
	
	            <span>{retrieveLanguageValue(this.props.language, Strings.enterInfo.legend)}</span>
	            {
	                this.props.rmsFormProps.editInfo === false ?
	                    <StaticProperties
	                        language={this.props.language}
	                        mapped={this.props.mapped}/>
	                    : ""
	            }
	            <EditableProperties
	                editInfo={this.props.rmsFormProps.editInfo}
	                formFieldChanged={this.formFieldChangedCallBack}
	                formInvalidSubmit={this.formInvalidSubmitCallBack}
	                formValidSubmit={this.formValidSubmitCallBack}
	                onBlurValidCallback={this.onBlurValidCallBack}
	                formValues={this.state.formValues}
	                language={this.props.language}
	                mapped={this.props.mapped}
	                mandatoryFieldMissing={this.state.mandatoryFieldMissing}
	                required={this.state.required}
	                submitButtonText={this.props.rmsFormProps.submitButtonText}
	            />
	        </div>
	    )

	}
}

export default RMSForm;
