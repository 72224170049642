import Conf from "./conf";

/* DVV background images */
import henkilöoikeudellisetPalvelut from '../images/henkilöoikeudelliset-tt.png';
import holhoustoimenEdunvalvontapalvelut from '../images/lapsenkuva-tt.png';
import todistajapalvelut from '../images/todistaminen-tt.png';
import vihkiminen from '../images/vihkiminen-tt.png';
import muutPalvelut from '../images/muut-tt.png';

export function retrieveLanguageValueWithReplacements(lang, valueObj, replacements) {
	let text = retrieveLanguageValue(lang, valueObj);
	if (text) {
		text = text.replace(/%\w+%/g, function(all) {
			return replacements[all] || all;
		});
	}
	return text || '';
}

export function retrieveLanguageValue(lang, valueObj) {
	let valueLang;
	switch(lang) {
		case 'FI':
			valueLang = "valueFinnish";
			break;
		case 'SV':
			valueLang = "valueSwedish";
			break;
		case 'EN':
			valueLang = "valueEnglish";
			break;
		default:
			valueLang = "valueDefault";
	}

	for(let i in valueObj) {
	   if(valueLang === i) {
		   if(valueObj[i] !== null && valueObj[i].length !== 0) {
		    	return valueObj[i];
		   } else {
		   		return valueObj["valueDefault"];
		   }
		}
	}
}

export function retrieveImage(path) {
	var images = ["henkilöoikeudelliset_palvelut","holhoustoimen_edunvalvontapalvelut","todistajapalvelut","vihkiminen","muut_palvelut"];
	let myPath;
	for(let i=0; i<images.length; i++) {
		if(images[i] === path.toLowerCase()) {
			myPath = path.toLowerCase();
		}
	}
	if(myPath === null || myPath === undefined) {
		return "muut_palvelut";
	} else {
		return myPath;
	}

}

export function retrieveLargeImage(path) {
	let myPath;
	switch(path.toLowerCase()) {
		case 'henkilöoikeudelliset palvelut':
			myPath = "henkilöoikeudelliset-tt.png";
			break;
		case 'holhoustoimen edunvalvontapalvelut':
			myPath = "lapsenkuva-tt.png";
			break;
		case 'todistajapalvelut':
			myPath = "todistaminen-tt.png";
			break;
		case 'vihkiminen':
			myPath = "vihkiminen-tt.png";
			break;
		case 'muut palvelut':
			myPath = 'muut-tt.png'
			break;
		default:
			myPath = "muut-tt.png";
	}
	return myPath;
}

export function getStaticImage(path) {
	if (!path) {
		return muutPalvelut;
	}
	
	var img;
	switch(path) {
		case '../images/henkilöoikeudelliset-tt.png':
		img = henkilöoikeudellisetPalvelut;
		break;
	case '../images/lapsenkuva-tt.png':
		img = holhoustoimenEdunvalvontapalvelut;
		break;
	case '../images/todistaminen-tt.png':
		img = todistajapalvelut;
		break;
	case '../images/vihkiminen-tt.png':
		img = vihkiminen;
		break;
	case '../images/muut-tt.png':
		img = muutPalvelut;
		break;
	default:
		img = muutPalvelut;
	}
	return img;
}

export function splitText(text) {
	let newText = text.split('\n').map((item, i) => {
	    return item;
	});
	return newText;
}

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON, status from the response
 */
function parseJSON(response) {
  return new Promise((resolve) => response.json()
    .then((json) => resolve({
      status: response.status,
      ok: response.ok,
      json,
    })));
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {Promise}           The request promise
 */
export function request(url, options) {
  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then(parseJSON)
      .then((response) => {
        if (response.ok) {
          return resolve(response.json);
        }
        // extract the error from the server's json
        return reject(response.json.meta.error);
      })
      .catch((error) => reject({
        networkError: error.message,
      }));
  });
}

export function get_ui_variant() {
//    console.log('UI variant: ', Conf.name);
    return Conf.name;
}

export function get_css_class_and_with_variant(baseClass) {
    let CSS_VARIANT = '-' + get_ui_variant();
    let className = baseClass + ' ' + baseClass + CSS_VARIANT;
    return className;
}

export function get_week_number(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
}

export function get_weekday_name_from_date(date) {
//    console.log('>> date', date);

    const withTime = date + " 00:01";
//    console.log('>> withTime', withTime);

    const theDate = new Date(withTime)
//    console.log('>> theDate', theDate);


    const names = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    const dayNum = theDate.getDay();
    return names[dayNum];
}
