import React, {Component} from 'react';
import store from 'store';
import homepath from '../utils/homepath';


import MyMenu from '../Menu/MyMenu';
import TopNavigation from '../TopNavigation/TopNavigation';
import CookiePolicy from '../CookiePolicy/CookiePolicy';
import {Redirect} from 'react-router';
import url from '../utils/path';
import {
    get_ui_variant,
    get_weekday_name_from_date,
    getStaticImage,
    retrieveLanguageValue,
    retrieveLargeImage
} from '../utils/helper';
import Strings from '../utils/strings';

import {confirmAlert} from 'react-confirm-alert';

import 'react-confirm-alert/src/react-confirm-alert.css';

import './EditInformation.css';

import {isMobile} from "react-device-detect";

import _ from 'lodash';
import {EDIT_INFORMATION, GlobalSetMenuState, GlobalSetStateEntered} from "../utils/components/GlobalMenuState";
import WindowResizeHandler from "../utils/components/WindowResizeHandler";
import {getInitialFormValues, getInitialRequired, mapJsonData} from "./FormDataMapper";
import RMSForm from "./RMSForm";

class EditInformation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toConfirmInfo: false,
            dataUpdated: false,
            mandatoryFieldMissing: false,
            selectedImage: store.get('selectedImage'),
            errors: {},
            backgroundImage: null,
            gotoHome: false
        }
        if (this.props.location && this.props.location.state) {
            this.state = {
                data: this.props.location.state.data,
                serviceId: this.props.location.state.serviceId,
                sessionId: this.props.location.state.sessionId,
                reservationCode: this.props.location.state.reservationCode,
            }
        } else {
//            console.log('>>> NO this.props.location !!');
            this.state = {
                gotoHome: true
            }
        }

        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.handleWindowChange = this.handleWindowChange.bind(this);
    }


    handleSubmitForm(submitData) {

  //      console.log('--> submitData', submitData);

        let reservationFields = _.assign({}, submitData.data);
        //remove service and serviceProvider fields as server will generate those
        delete reservationFields['service'];
        delete reservationFields['serviceProvider'];

        fetch(url.concat('/reservation/').concat(submitData.data.reservationCode).concat('?sessionId=').concat(this.state.sessionId), {
            method: 'put',
            headers: {'Content-type': 'application/json', 'Accept': 'application/json'},
            body: JSON.stringify({"reservation": reservationFields})
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            if (response.status === 403) {
                //Session has expired
                confirmAlert({
                    message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.sessionHasExpired),
                    buttons: [{
                        label: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.returnToFrontPage),
                        onClick: () => this.homePage()
                    }],
                    closeOnEscape: false,
                    closeOnClickOutside: false,
                });
            }
            if (response.status === 404) {
                //Cannot create reservation
                confirmAlert({
                    message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.reservationSessionExpired),
                    buttons: [{
                        label: retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.ok),
                        onClick: () => this.props.history.goBack()
                    }],
                    closeOnEscape: true,
                    closeOnClickOutside: true,
                });
            }
            if (response.status === 409) {
                //Cannot create reservation
                confirmAlert({
                    message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.reservationValidationFailed),
                    buttons: [{
                        label: retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.ok),
                        onClick: () => this.props.history.goBack()
                    }],
                    closeOnEscape: true,
                    closeOnClickOutside: true,
                });
            }
//            this.props.globalBackEndError(true);
//            throw response.json();
        }).then(data => {
            store.set("updated", true); // used to display info to user on confirm page
            this.setState({
                data: data,
                errors: {},
                toConfirmInfo: true
            });
        });
    }

    componentDidMount() {

        GlobalSetMenuState(EDIT_INFORMATION, {accessed: true})
        GlobalSetStateEntered(EDIT_INFORMATION);

        window.scrollTo(0, 0);

        fetch(url.concat('/reservation/').concat(this.state.reservationCode).concat('/?sessionId=').concat(this.state.sessionId), {
            method: 'get',
            headers: {
                'Content-type': 'application/json; charset=utf-8',
                'Accept': 'application/json; charset=utf-8'
            }
        }).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
            }
        ).then(data => {
                this.setState({
                    data: data,
                    backgroundImage: data.service.backgroundImage
                });
            }
        );
    }

    renderWindowResizeHandler(callBack) {
        return (
            <>
                <WindowResizeHandler callBack={callBack}/>
            </>
        )
    }

    handleWindowChange(dimensions) {
        this.setState({
            dimensions: dimensions
        })
    }

    render() {
        if (store.get('sessionId') === null || store.get('sessionId') === undefined || this.state.goHome) {
            console.log('>>> No sessionId, window.location.assign ', homepath);
            window.location.assign(homepath);
            return "";
        } else if (this.state.toConfirmInfo === true) {

//            console.log('----> toConfirmInfo', this);

            return <Redirect to={{
                pathname: '/confirm-information',
                state: {
                    reservationCode: this.state.data.reservationCode,
                    serviceId: this.state.serviceId,
                    sessionId: this.state.sessionId,
                    data: this.state.data
                }
            }}
            />
        } else {
            let isMobileReal = isMobile;
            if (!isMobile) {
                if (window.innerWidth < this.props.state.mobileBreakPoint) {
                    isMobileReal = true;
                }
            }

            let img = '';
            if (get_ui_variant() === 'ISLAB') {
                if (this.state.backgroundImage) {
                    img = 'data:image/' + this.state.backgroundImage.type + ';base64,' + this.state.backgroundImage.data;
                } else {
                    console.log('Logo not loaded yet!');
                }
            } else {
                if (this.state.selectedImage) {
                    img = getStaticImage(this.state.selectedImage);
                } else if (this.state.data) {
                    let service = this.state.data.service;
                    let largeImage = retrieveLargeImage(service.name.valueDefault);
                    img = getStaticImage(largeImage);
                }
            }

            if (this.state.data === undefined) {
                this.homePage();
                return "";
            }


            const mapped = mapJsonData(this.state.data, true);
            const required = getInitialRequired(this.state.data);
            const formValues = getInitialFormValues(this.state.data);

            if (mapped && required && formValues) {

                let date = "";
                if (this.state.data) {
                    let split = this.state.data.date.split("-");
                    date = split[2] + '.' + split[1] + '.' + split[0];
                }

                const reservationInfo = {
                    dateWeekDay: this.state.data ? retrieveLanguageValue(this.props.state.language, Strings.calendar.weekday[get_weekday_name_from_date(this.state.data.date)]) : "",
                    date: date,
                    time: this.state.data ? this.state.data.startTime + " - " + this.state.data.endTime : "",
                    selectedService: retrieveLanguageValue(this.props.state.language, this.state.data.service.name),
                    selectedOffice: retrieveLanguageValue(this.props.state.language, this.state.data.serviceProvider.name)
                }

                const rmsFormProps = {
                    editInfo: true,
                    headerText: retrieveLanguageValue(this.props.state.language, Strings.enterInfo.reservationCustomerInfo),
                    reservationInfo: reservationInfo,
                    isMobileReal: isMobileReal,
                    submitButtonText: retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.saveChanges)
                }

                let service = this.props.state.services;
                if (isMobileReal) {
                    return (
                        <div>
                            <TopNavigation name="editInformation"
                                           globalGetNavigationState={this.props.globalGetNavigationState}
                                           globalSetNavigationState={this.props.globalSetNavigationState}
                                           globalLang={this.props.globalLang} setSessionId={this.props.setSessionId}
                                           state={this.props.state}/>
                            <MyMenu state={this.props.state} name="editInformation"/>
                            {this.renderWindowResizeHandler(this.handleWindowChange)}
                            <div className="mobile-edit-info-wrapper">
                                <div className="mobile-edit-form">
                                    <div className="mobile-edit-o">
                                        <RMSForm language={this.props.state.language}
                                                 handleSubmitForm={this.handleSubmitForm}
                                                 rmsFormProps={rmsFormProps}
                                                 json={this.state.data}
                                                 mapped={mapped}
                                                 required={required}
                                                 formValues={formValues}/>
                                        <CookiePolicy state={this.props.state} cookiePolicyExpiration={service != null ? service.cookiePolicyExpiration : null} cookiePolicy={service != null ? service.cookiePolicy : null} cookiePolicyTitle={service != null ? service.cookiePolicyTitle : null}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div>
                            <TopNavigation name="editInformation"
                                           globalGetNavigationState={this.props.globalGetNavigationState}
                                           globalSetNavigationState={this.props.globalSetNavigationState}
                                           globalLang={this.props.globalLang} setSessionId={this.props.setSessionId}
                                           state={this.props.state}/>
                            <MyMenu state={this.props.state} name="editInformation"/>
                            {this.renderWindowResizeHandler(this.handleWindowChange)}
                            <div className="edit-info-wrapper">
                                <div className="info-wrapper">
                                    <img src={img} className="edit-info-top-image" alt=""/>

                                    <div className={"edit-form"}>
                                        <RMSForm language={this.props.state.language}
                                                 handleSubmitForm={this.handleSubmitForm}
                                                 rmsFormProps={rmsFormProps}
                                                 json={this.state.data}
                                                 mapped={mapped}
                                                 required={required}
                                                 formValues={formValues}/>
                                        <CookiePolicy state={this.props.state} cookiePolicyExpiration={service != null ? service.cookiePolicyExpiration : null} cookiePolicy={service != null ? service.cookiePolicy : null} cookiePolicyTitle={service != null ? service.cookiePolicyTitle : null}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
            }

        }
    }
}

export default EditInformation;