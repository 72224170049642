import React, {Component} from 'react';
import MyMenu from '../Menu/MyMenu';
import store from 'store';
import TopNavigation from '../TopNavigation/TopNavigation';
import CookiePolicy from '../CookiePolicy/CookiePolicy';

import {get_ui_variant, getStaticImage, retrieveLanguageValue, retrieveLargeImage} from '../utils/helper';
import homepath from '../utils/homepath';

import "./ChooseATime.css";

//import {isMobile} from "react-device-detect";
import {confirmAlert} from "react-confirm-alert";
import Strings from "../utils/strings";
import {
    CHOOSE_A_TIME,
    CHOOSE_AN_OFFICE,
    GlobalGetMenuStateObject, GlobalSetMenuState, GlobalSetStateEntered
} from "../utils/components/GlobalMenuState";
import NewCalendar from "../NewCalendar/NewCalendar";
import url from "../utils/path";
import {Redirect} from "react-router-dom";

class ChooseATime extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dates: [],
            currentWeek: '',
            currentMonth: '',
            selectedImage: store.get('selectedImage'),
            sessionExpired: false,
            expiredError: null,
            backgroundImage: null,
            data: null,
            toEnterInfo: false
        }

        this.timeSelected = this.timeSelected.bind(this);

    }

    setExpiredSession = (value, error) => {
        this.setState({
            sessionExpired: value,
            expiredError: error
        });
    };

    getServiceData(serviceUrl) {

        fetch(serviceUrl, {
            method: 'get',
            headers: {'Content-Type': 'application/json; charset=utf-8', 'Accept': 'application/json; charset=utf-8'},

        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            if (response.status === 404) {
                confirmAlert({
                    message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.noAvailableServiceProvidersFound),
                    buttons: [{label: retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.ok)}],
                    closeOnEscape: true,
                    closeOnClickOutside: true,
                });
            }
            throw response.json();
        }).then(data => {
            this.setState({
                backgroundImage: data.backgroundImage
            });

        }).catch(error => {
            return error;
        });
    }

    componentDidMount() {
        let sessionId = store.get('sessionId');
        if (sessionId === null || sessionId === undefined) {
            console.log('sessionId missing -> redirect home!')
            this.props.history.push(homepath);
            return;
        }

        let serviceUrl = store.get('serviceUrl');
        this.getServiceData(serviceUrl);

        let menuStateObject = GlobalGetMenuStateObject();
        if (menuStateObject.states[CHOOSE_AN_OFFICE].completed === false) {
            console.log('Previous state not completed: CHOOSE_AN_OFFICE -> redirect home!')
            this.props.history.push(homepath);
            return;
        }

        GlobalSetMenuState(CHOOSE_A_TIME, {accessed: true})
        GlobalSetStateEntered(CHOOSE_A_TIME);

    }

    homePage = () => {
        let logoutUrl = store.get('logoutUrl');
        store.clearAll();
        store.set('logoutUrl', logoutUrl);
        this.props.history.push(homepath);
    }


    timeSelected(eventClick) {
        const serviceData = JSON.parse(store.get('serviceData'));

        let serviceId = serviceData.serviceId;
        let sessionId = serviceData.sessionId;

        fetch(
            url
                .concat('/reservation/').concat(serviceId)
                .concat('/reserve').concat('?sessionId=').concat(sessionId)
                .concat('&lang=').concat(this.props.state.language.toLowerCase()), {
                method: 'post',
                headers: {'Content-type': 'application/json', 'Accept': 'application/json'},
                body: JSON.stringify({
                    "slot": {
                        "slotId": eventClick.slotId,
                        "startTime": eventClick.startTime,
                        "date": eventClick.date
                    },
                }),
            }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            if (response.status === 403) {
                //Session has expired
                confirmAlert({
                    message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.sessionHasExpired),
                    buttons: [{
                        label: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.returnToFrontPage),
                        onClick: () => this.homePage()
                    }],
                    closeOnEscape: false,
                    closeOnClickOutside: false,
                });
            }
            if (response.status === 409) {
                //Cannot create reservation
                confirmAlert({
                    message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.reservationCannotBeCreated),
                    buttons: [{label: retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.ok)}],
                    closeOnEscape: true,
                    closeOnClickOutside: true,
                });
            }
            throw response.json();
        }).then(tentativeReservation => {

            store.remove('TimeSelect');
            store.remove("ChangeDate");
            store.remove('CheckInformation');

            this.setState({
                data: tentativeReservation,
                toEnterInfo: true
            });
        }).catch(error => {
            console.log(error);
            return error;
        });


    }


    render() {

        if (this.state.toEnterInfo === true) {
            const serviceData = JSON.parse(store.get('serviceData'));

            let state = this.props.globalGetNavigationState();
            let calendarData = {};
            calendarData.serviceId = serviceData.serviceId;
            calendarData.sessionId = serviceData.sessionId;
            calendarData.data = this.state.data;
            calendarData.selectedService = this.state.selectedService;
            calendarData.selectedOffice = this.state.selectedOffice;
            state.calendarData = calendarData;
            this.props.globalSetNavigationState(state);

            GlobalSetMenuState(CHOOSE_A_TIME, {completed: true})

            return (
                <Redirect push to={{
                    pathname: '/enter-information',
                    state: {
                        serviceId: serviceData.serviceId,
                        sessionId: serviceData.serviceId,
                        data: this.state.data,
                        selectedService: this.state.selectedService,
                        selectedOffice: this.state.selectedOffice
                    }
                }}/>
            );
        }


        if (store.get('sessionId') === null || store.get('sessionId') === undefined) {
            window.location.assign(homepath);
            return "";
        } else {
            let img = '';
            if (get_ui_variant() === 'ISLAB') {
                if (this.state.backgroundImage) {
                    img = 'data:image/' + this.state.backgroundImage.type + ';base64,' + this.state.backgroundImage.data;
                } else {
//                    console.log('Logo not loaded yet!');
                }
            } else {
                if (this.state.selectedImage) {
                    img = getStaticImage(this.state.selectedImage);
                } else if (this.state.data) {
                    let service = this.state.data.service;
                    let largeImage = retrieveLargeImage(service.name.valueDefault);
                    img = getStaticImage(largeImage);
                }
            }

            /*            let isMobileReal = isMobile;
                        if (!isMobile) {
                            if (window.innerWidth < this.props.state.mobileBreakPoint) {
                                isMobileReal = true;
                            }
                        }
            */

            const serviceData = JSON.parse(store.get('serviceData'));
            const calendarData = {
                dayMaxEventRows: 5,
                height: 'auto',
                initialView: null,
                firstDay: 1,
                language: this.props.state.language,
//                service: retrieveLanguageValue(this.props.state.language, this.props.history.location.state.selectedService),
                service: retrieveLanguageValue(this.props.state.language, serviceData.selectedService),
//                serviceId: this.props.location.state.serviceId,
                serviceId: serviceData.serviceId,
                sessionId: serviceData.sessionId,
//                servicePlace: retrieveLanguageValue(this.props.state.language, this.props.history.location.state.selectedOffice),
                servicePlace: retrieveLanguageValue(this.props.state.language, serviceData.selectedOffice),
                serviceTitle: retrieveLanguageValue(this.props.state.language, Strings.calendar.selectAvailableTime),
                showNonCurrentDates: false,
                slotDuration: '00:10:00',
                weekends: true,
                weekNumbers: false
            }

            let service = this.props.state.services;
            return (
                <div>
                    <TopNavigation globalGetNavigationState={this.props.globalGetNavigationState}
                                   globalSetNavigationState={this.props.globalSetNavigationState}
                                   globalLang={this.props.globalLang} setSessionId={this.props.setSessionId}
                                   state={this.props.state}/>
                    <div id={'islab-color-' + get_ui_variant()}>
                        <MyMenu state={this.props.state} name="chooseATime"/>
                    </div>
                    <div className="choose-time-wrapper">
                        <div className="time-wrapper">
                            <img src={img} className="choose-time-bg-image" alt="[bg-img]"/>
                            <div className="time-block">
                                <div>
                                    <NewCalendar changeSelectedTime={this.timeSelected} calendarData={calendarData}/>
                                    <CookiePolicy state={this.props.state} cookiePolicyExpiration={service != null ? service.cookiePolicyExpiration : null} cookiePolicy={service != null ? service.cookiePolicy : null} cookiePolicyTitle={service != null ? service.cookiePolicyTitle : null}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}


export default ChooseATime;