import React, {Component} from 'react';
import store from 'store';
import MyMenu from '../Menu/MyMenu';
import TopNavigation from '../TopNavigation/TopNavigation';
import CookiePolicy from '../CookiePolicy/CookiePolicy';
import {Redirect} from 'react-router';
import Strings from '../utils/strings';
import url from '../utils/path';
import homepath from '../utils/homepath';

import {
    get_ui_variant,
    get_weekday_name_from_date,
    getStaticImage,
    retrieveLanguageValue,
    retrieveLargeImage
} from '../utils/helper';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './EnterInformation.css';


import {isMobile} from "react-device-detect";
import {ENTER_INFO, GlobalSetMenuState, GlobalSetStateEntered,} from "../utils/components/GlobalMenuState";
import WindowResizeHandler from "../utils/components/WindowResizeHandler";
import RMSForm from "./RMSForm";
import {getInitialFormValues, getInitialRequired, mapJsonData} from "./FormDataMapper";

class EnterInformation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toCheckInformation: false,
            submitBtnDisabled: false,
            mandatoryFieldMissing: false,
            data: null,
            serviceId: '',
            sessionId: '',
            selectedImage: store.get('selectedImage'),
            selectedService: '',
            selectedOffice: '',
            errors: {},
            backgroundImage: null,
            uiVariant: get_ui_variant()
        }

        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.handleWindowChange = this.handleWindowChange.bind(this);
    }

    homePage = () => {
        let logoutUrl = store.get('logoutUrl');
        store.clearAll();
        store.set('logoutUrl', logoutUrl);
        this.props.history.push(homepath);
    }

    cleanData = () => {
        store.remove('CheckInformation');
    }

    handleSubmitForm(submitData) {
        let state = this.props.globalGetNavigationState();
        state.enterInformationData = submitData.data;
        this.props.globalSetNavigationState(state);


        fetch(url.concat('/reservation/').concat(this.state.data.reservationCode).concat('/validate?sessionId=').concat(this.state.sessionId), {
            method: 'post',
            headers: {'Content-type': 'application/json', 'Accept': 'application/json'},
            body: JSON.stringify({"reservation": submitData.data})
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            if (response.status === 403) {
                //Session has expired
                confirmAlert({
                    message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.sessionHasExpired),
                    buttons: [{
                        label: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.returnToFrontPage),
                        onClick: () => this.homePage()
                    }],
                    closeOnEscape: false,
                    closeOnClickOutside: false,
                });
            }
            if (response.status === 404) {
                //Reservation code was not found
                confirmAlert({
                    message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.reservationNotFound),
                    buttons: [
                        {
                            label: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.reloadPage),
                            onClick: () => window.location.reload()
                        },
                        {
                            label: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.returnToFrontPage),
                            onClick: () => this.homePage()
                        }
                    ],
                    closeOnEscape: false,
                    closeOnClickOutside: false,
                });
            }
//			console.log('error response, ', response);
//			this.props.globalBackEndError(true);
        }).then(data => {
//            console.log('--> Validated data', data);
            this.setState({
                data: submitData.data,
                errors: {},
                toCheckInformation: true
            });
        });

    }

    getServiceData(serviceUrl) {

//        console.log('serviceUrl', serviceUrl);

        fetch(serviceUrl, {
            method: 'get',
            headers: {'Content-Type': 'application/json; charset=utf-8', 'Accept': 'application/json; charset=utf-8'},

        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            if (response.status === 404) {
                confirmAlert({
                    message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.noAvailableServiceProvidersFound),
                    buttons: [{label: retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.ok)}],
                    closeOnEscape: true,
                    closeOnClickOutside: true,
                });
            }
            throw response.json();
        }).then(data => {
            this.setState({
                backgroundImage: data.backgroundImage
            });

        }).catch(error => {
            return error;
        });
    }

    componentDidMount() {
        GlobalSetMenuState(ENTER_INFO, {accessed: true})
        GlobalSetStateEntered(ENTER_INFO);

        let sessionId = store.get('sessionId');

        if (sessionId === null || sessionId === undefined) {
            this.props.history.push(homepath);
            return;
        }

        window.scrollTo(0, 0);

        let serviceUrl = store.get('serviceUrl');
        this.getServiceData(serviceUrl);

        let state = this.props.globalGetNavigationState();
//        console.log('>> from state', state);
        let data = state.calendarData.data;
        if (state.enterInformationData) {
            data = state.enterInformationData;
        }
        this.setState({
            data: data,
            serviceId: state.calendarData.serviceId,
            sessionId: state.calendarData.sessionId,
            selectedService: state.calendarData.selectedService,
            selectedOffice: state.calendarData.selectedOffice
        });

    }

    componentWillUnmount() {
//        console.log('componentWillUnmount, this.state', this.state);
        store.set('EnterInformation', JSON.stringify(this.state));
    }

    renderWindowResizeHandler(callBack) {
        return (
            <>
                <WindowResizeHandler callBack={callBack}/>
            </>
        )
    }

    handleWindowChange(dimensions) {
        this.setState({
            dimensions: dimensions
        })
    }

    render() {

        if (store.get('sessionId') === null || store.get('sessionId') === undefined) {
            console.log('>>> No sessionId, window.location.assign ', homepath);
            window.location.assign(homepath);
        } else if (this.state.toCheckInformation === true) {
            return <Redirect push to={{
                pathname: '/check-information',
                state: {
                    reservationCode: this.state.data.reservationCode,
                    serviceId: this.state.serviceId,
                    sessionId: this.state.sessionId,
                    data: this.state.data,
                    selectedService: this.state.selectedService,
                    selectedOffice: this.state.selectedOffice
                }
            }}
            />
        } else {
            let img = '';
            if (get_ui_variant() === 'ISLAB') {
                if (this.state.backgroundImage) {
                    img = 'data:image/' + this.state.backgroundImage.type + ';base64,' + this.state.backgroundImage.data;
                } else {
//                    console.log('Logo not loaded yet!');
                }
            } else {
                if (this.state.selectedImage) {
                    img = getStaticImage(this.state.selectedImage);
                } else if (this.state.data) {
                    let service = this.state.data.service;
                    let largeImage = retrieveLargeImage(service.name.valueDefault);
                    img = getStaticImage(largeImage);
                }
            }

            let isMobileReal = isMobile;
            if (!isMobile) {
                if (window.innerWidth < this.props.state.mobileBreakPoint) {
                    isMobileReal = true;
                }
            }

            const mapped = mapJsonData(this.state.data, false);
            const required = getInitialRequired(this.state.data);
            const formValues = getInitialFormValues(this.state.data);

            if (mapped && required && formValues) {

                const serviceData = JSON.parse(store.get('serviceData'));
                let date = "";
                if (this.state.data) {
                    let split = this.state.data.date.split("-");
                    date = split[2] + '.' + split[1] + '.' + split[0];
                }

                const reservationInfo = {
                    dateWeekDay: this.state.data ? retrieveLanguageValue(this.props.state.language, Strings.calendar.weekday[get_weekday_name_from_date(this.state.data.date)]) : "",
                    date: date,
                    time: this.state.data ? this.state.data.startTime + " - " + this.state.data.endTime : "",
                    selectedService: retrieveLanguageValue(this.props.state.language, serviceData.selectedService),
                    selectedOffice: retrieveLanguageValue(this.props.state.language, serviceData.selectedOffice)
                }

                const rmsFormProps = {
                    editInfo: false,
                    headerText: retrieveLanguageValue(this.props.state.language, Strings.enterInfo.reservationCustomerInfo),
                    isMobileReal: isMobileReal,
                    reservationInfo: reservationInfo,
                    submitButtonText: retrieveLanguageValue(this.props.state.language, Strings.enterInfo.continueToConfirmBtn)
                }

                let service = this.props.state.services;
                if (isMobileReal) {
                    return (
                        <div>
                            <TopNavigation globalGetNavigationState={this.props.globalGetNavigationState}
                                           globalSetNavigationState={this.props.globalSetNavigationState}
                                           globalLang={this.props.globalLang} setSessionId={this.props.setSessionId}
                                           state={this.props.state}/>
                            <MyMenu state={this.props.state} name="enterInfo"/>
                            {this.renderWindowResizeHandler(this.handleWindowChange)}
                            <div className="mobile-enter-info-wrapper">
                                <div className="mobile-enter-form">
                                    <div className="mobile-enter-info">
                                        <RMSForm language={this.props.state.language}
                                                 handleSubmitForm={this.handleSubmitForm}
                                                 rmsFormProps={rmsFormProps}
                                                 json={this.state.data}
                                                 mapped={mapped}
                                                 required={required}
                                                 formValues={formValues}/>
                                    </div>
                                </div>
                                <CookiePolicy state={this.props.state} cookiePolicyExpiration={service != null ? service.cookiePolicyExpiration : null} cookiePolicy={service != null ? service.cookiePolicy : null} cookiePolicyTitle={service != null ? service.cookiePolicyTitle : null}/>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div>
                            <TopNavigation globalGetNavigationState={this.props.globalGetNavigationState}
                                           globalSetNavigationState={this.props.globalSetNavigationState}
                                           globalLang={this.props.globalLang} setSessionId={this.props.setSessionId}
                                           state={this.props.state}/>
                            <div id={'islab-color-' + get_ui_variant()}>
                                <MyMenu state={this.props.state}/>
                            </div>
                            {this.renderWindowResizeHandler(this.handleWindowChange)}
                            <div className="enter-info-wrapper">
                                <div className="info-wrapper">
                                    <img src={img} className="enter-info-top-image" alt="[bg-img]"/>
                                    <div className="enter-form">
                                        <RMSForm language={this.props.state.language}
                                                 handleSubmitForm={this.handleSubmitForm}
                                                 rmsFormProps={rmsFormProps}
                                                 json={this.state.data}
                                                 mapped={mapped}
                                                 required={required}
                                                 formValues={formValues}/>
                                        <CookiePolicy state={this.props.state} cookiePolicyExpiration={service != null ? service.cookiePolicyExpiration : null} cookiePolicy={service != null ? service.cookiePolicy : null} cookiePolicyTitle={service != null ? service.cookiePolicyTitle : null}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
            } else {
                return (
                    <></>
                )
            }
        }
    }
}

export default EnterInformation;