const Conf = {

    name: "ISLAB",

    ReviewService: {
        useScore: false,
        useServiceProvider: false,
        useServiceRecipient: false
    }

}

export default Conf;