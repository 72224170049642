//import React from 'react';
import store from "store";
import url from "../path";
import {confirmAlert} from "react-confirm-alert";
import {retrieveLanguageValue} from "../helper";
import Strings from "../strings";
import homepath from "../homepath";


function clearAllData(parentThis) {
    store.clearAll();
    parentThis.props.history.push('/');
}

function doLogoutUrl(logoutUrl) {
    console.log('Doing logout with url: ', logoutUrl);
    store.clearAll();
    if (logoutUrl !== null && logoutUrl !== undefined) {
        window.location.replace(logoutUrl);
    } else {
        window.location.assign(homepath);
    }
}


export function doGlobalLogout(parentThis) {
    console.log('>> doLogout', parentThis);

    //	    this.props.globalLogout('test');

    let uniquerServiceId = store.get('uniqueServiceId');
    let logoutUrl = url.concat('/logout?sessionId='.concat(parentThis.props.state.sessionId));
    if (uniquerServiceId) {
        logoutUrl = logoutUrl.concat('&serviceId=').concat(uniquerServiceId);
    }

    console.log('>> logoutUrl', logoutUrl);

    fetch(logoutUrl, {
        method: 'post',
        headers: {'Content-type': 'application/json', 'Accept': 'application/json'}
    }).then(response => {
        if (response.status === 200) {
            return response.json();
        }
        if (response.status === 403) {
            //Session has expired
            confirmAlert({
                message: retrieveLanguageValue(parentThis.props.state.language, Strings.errorMessages.sessionHasExpired),
                buttons: [{
                    label: retrieveLanguageValue(parentThis.props.state.language, Strings.errorMessages.returnToFrontPage),
                    onClick: () => clearAllData(parentThis)
                }],
                closeOnEscape: false,
                closeOnClickOutside: false,
            });
        }
        if (response.status === 404) {
            //Cannot create reservation
            confirmAlert({
                message: retrieveLanguageValue(parentThis.props.state.language, Strings.errorMessages.sessionIdNotFound),
                buttons: [{
                    label: retrieveLanguageValue(parentThis.props.state.language, Strings.topNav.signOut),
                    onClick: () => clearAllData(parentThis)
                }],
                closeOnEscape: false,
                closeOnClickOutside: false,
            });
        }
        throw response.json();
    }).then(_data => {
        let url = _data.logoutUrl;
        console.log('logout url = ', url);
        confirmAlert({
            message: retrieveLanguageValue(parentThis.props.state.language, Strings.errorMessages.logoutSuccess),
            buttons: [{
                label: retrieveLanguageValue(parentThis.props.state.language, Strings.topNav.cancel),
                onClick: () => doLogoutUrl(url)
            }],
            closeOnEscape: false,
            closeOnClickOutside: false,
            onKeypressEscape: () => {
                doLogoutUrl(url)
            },
            onClickOutside: () => {
                doLogoutUrl(url)
            }
        });
    }).catch(error => {
        return error;
    });

    let state = parentThis.props.globalGetNavigationState();
    state.loggedIn = false;
    parentThis.props.globalSetNavigationState(state);

    store.remove('uniqueServiceId');

}
