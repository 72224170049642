import React from 'react';
import url from "../utils/path";

const Bench = (props) => {

//    const fetchStart = '2021-10-13'
//    const fetchEnd = '2022-10-03'

    const fetchStart = '2021-10-13'
    const fetchEnd = '2021-11-01'

//?sessionId=
// hki
//    const serviceId = 'XBS4OJ';
//    const sessionId = '2BC0JLS';

// hlinna
    const serviceId = 'XIH29T';
    const sessionId = '2BC0JYR';

    const availableTimes = (e) => {
        console.log('>>>> availableTimes')

        console.log('fetching events: ' + fetchStart + " - " + fetchEnd);

        fetch(url.concat('/available-times/').concat(serviceId).concat('?sessionId=').concat(sessionId), {
            method: 'post',
            headers: {'Content-type': 'application/json', 'Accept': 'application/json'},
            body: JSON.stringify({"startDate": fetchStart, "endDate": fetchEnd})
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
        }).then(data => {

            console.log('loaded events data: ', data);

        }).catch(error => {
            console.log(error);
            return error;
        });

    }

    const availableEvents = (e) => {
        console.log('>>>> availableEvents')
        console.log('fetching events: ' + fetchStart + " - " + fetchEnd);

        fetch(url.concat('/available-events/').concat(serviceId).concat('?sessionId=').concat(sessionId), {
            method: 'post',
            headers: {'Content-type': 'application/json', 'Accept': 'application/json'},
            body: JSON.stringify({"startDate": fetchStart, "endDate": fetchEnd})
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
        }).then(data => {

            console.log('loaded events data: ', data);

        }).catch(error => {
            console.log(error);
            return error;
        });

    }

    return (
        <>
            <div>
                Old vs New api test: <br/>
                <button onClick={availableTimes}>Available times</button>
                <br/>
                <button onClick={availableEvents}>Available events</button>
            </div>
        </>
    )
}

export default Bench;