import React, {Component} from 'react';
import MyMenu from '../Menu/MyMenu';
import {Button, Modal} from 'reactstrap';
import {AvForm} from 'availity-reactstrap-validation'
import TopNavigation from '../TopNavigation/TopNavigation';
import {Link} from 'react-router-dom';
import url from '../utils/path';
import {
    get_css_class_and_with_variant,
    get_ui_variant,
    get_weekday_name_from_date,
    getStaticImage,
    retrieveLanguageValue
} from '../utils/helper';
import Strings from '../utils/strings';
import store from 'store';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPrint} from '@fortawesome/free-solid-svg-icons'
import {confirmAlert} from 'react-confirm-alert';
import ReactToPrint from 'react-to-print';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './ConfirmInformation.css';
import {isMobile} from "react-device-detect";
import {
    CONFIRM_INFO,
    GlobalSetMenuState,
    GlobalSetStateEntered
} from "../utils/components/GlobalMenuState";
import {doGlobalLogout} from "../utils/components/GlobalLogoutHandler";
import homepath from '../utils/homepath';
import WindowResizeHandler from "../utils/components/WindowResizeHandler";
import {mapJsonData} from "../EnterInformation/FormDataMapper";

function getPropertyValue(mapped, property, language) {
//    console.log('>> mapped: ', mapped);
//    console.log('>> property: ', property);
    const mappedValue = mapped.valueMap.get(property.propertyId).propertyValue;
    if (property.propertyType === 'radio' || property.propertyType === 'pulldown') {
        let value = null;
        property.options.forEach((option) => {
            if (option.value === mappedValue) {
                value = retrieveLanguageValue(language, option.name);
            }
        })
        return value;
    } else {
        return mappedValue;
    }
}

class ConfirmInformation extends Component {
    constructor(props) {

        super(props);

        try {
            this.state = {
                checked: false,
                data: null,
                mapped: null,
                reservationCode: this.props.location.state.reservationCode,
                serviceId: this.props.location.state.serviceId,
                sessionId: this.props.location.state.sessionId,
                showDeleteModal: false,
                selectedImage: store.get('selectedImage'),
                deleteError: null,
                printClicked: false,
                updated: null,
                backgroundImage: null,
                goHome: false
            }

        } catch (e) {
//            console.log('>> init errror', e);
            this.state = {
                goHome: true
            }
        }

        this.showDeleteModal = this.showDeleteModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.deleteReservation = this.deleteReservation.bind(this);
        this.handleWindowChange = this.handleWindowChange.bind(this);

    }

    getServiceData(serviceUrl) {

        console.log('serviceUrl', serviceUrl);

        fetch(serviceUrl, {
            method: 'get',
            headers: {'Content-Type': 'application/json; charset=utf-8', 'Accept': 'application/json; charset=utf-8'},

        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            if (response.status === 404) {
                confirmAlert({
                    message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.noAvailableServiceProvidersFound),
                    buttons: [{label: retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.ok)}],
                    closeOnEscape: true,
                    closeOnClickOutside: true,
                });
            }
            throw response.json();
        }).then(data => {
            this.setState({
                backgroundImage: data.backgroundImage
            });

        }).catch(error => {
            return error;
        });
    }

    logout = () => {
        doGlobalLogout(this);
    }

    showDeleteModal() {
        this.setState({
            showDeleteModal: true
        });
    }

    handleCloseModal() {
        this.setState({
            showDeleteModal: false
        });
    }

    homePage = () => {

        console.log('Go homepage');
        this.setState({
            goHome: true
        })

    }

    deleteReservation() {
        //delete reservation here
        fetch(url.concat('/reservation/').concat(this.state.reservationCode).concat('?sessionId=').concat(this.state.sessionId), {
            method: 'delete',
            headers: {'Content-type': 'application/json', 'Accept': 'application/json'}
        }).then(response => {
            this.setState({
                showDeleteModal: false
            });

            console.log('delete response ->', response);

            if (response.status === 200) {
                confirmAlert({
                    message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.reservationDeleted),
                    buttons: [{
                        label: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.returnToFrontPage),
                        onClick: () => this.homePage()
                    }],
                    closeOnEscape: false,
                    closeOnClickOutside: false,
                });
            }
            if (response.status === 403) {
                //Session has expired
                /*                confirmAlert({
                                    message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.sessionHasExpired),
                                    buttons: [{
                                        label: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.returnToFrontPage),
                                        onClick: () => this.homePage()
                                    }],
                                    closeOnEscape: false,
                                    closeOnClickOutside: false,
                                });*/
            }
            if (response.status === 409) {
                console.log('409 delete!')
                //Cannot delete reservation
                confirmAlert({
                    message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.cannotDeleteReservationMarginals),
                    buttons: [{label: retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.ok)}],
                    closeOnEscape: true,
                    closeOnClickOutside: true,
                });

            }
//            return  error;
//            this.props.globalBackEndError(true);
//            throw response.json();
        }).then(data => {
            /*            confirmAlert({
                            message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.reservationDeleted),
                            buttons: [{
                                label: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.returnToFrontPage),
                                onClick: () => this.homePage()
                            }],
                            closeOnEscape: false,
                            closeOnClickOutside: false,
                        });*/
        }).catch(error => {
            return error;

        }).then(err => {
            this.setState({
                deleteError: ''
            })
//            console.log(err);
        });
    }

    componentDidMount() {

        if (this.state.goHome === true) {
            return;
        }

        GlobalSetMenuState(CONFIRM_INFO, {accessed: true})
        GlobalSetStateEntered(CONFIRM_INFO);

        store.set('backclick', false);
        if (this.props.location !== undefined && this.props.location.state !== undefined && this.props.location.state.updated !== null) {
            this.setState({
                updated: this.props.location.state.updated
            });
        }
        if (this.state.data === undefined || this.state.data === null) {
            fetch(url.concat('/reservation/').concat(this.state.reservationCode).concat('/?sessionId=').concat(this.state.sessionId), {
                method: 'get',
                headers: {
                    'Content-type': 'application/json; charset=utf-8',
                    'Accept': 'application/json; charset=utf-8'
                }
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                if (response.status === 403) {
                    //Session has expired
                    /*                    confirmAlert({
                                            message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.sessionHasExpired),
                                            buttons: [{
                                                label: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.returnToFrontPage),
                                                onClick: () => this.homePage()
                                            }],
                                            closeOnEscape: false,
                                            closeOnClickOutside: false,
                                        });*/
                }
                if (response.status === 404) {
                    //Reservation code was not found
                    confirmAlert({
                        message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.reservationNotFound),
                        buttons: [
                            {
                                label: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.reloadPage),
                                onClick: () => window.location.reload()
                            },
                            {
                                label: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.returnToFrontPage),
                                onClick: () => this.homePage()
                            }
                        ],
                        closeOnEscape: false,
                        closeOnClickOutside: false,
                    });
                }
                throw response.json();
            }).then(data => {

                this.setState({
                    data: data,
                    mapped: mapJsonData(data),
                    backgroundImage: data.service.backgroundImage
                });

                if (this.state.serviceId === null) {
                    this.setState({
                        serviceId: data.service.id
                    });
                }

                store.set('serviceData', JSON.stringify({
                    serviceId: data.service.id,
                    selectedService: data.service.name,
                    selectedOffice: data.serviceProvider.name,
                    selectedOfficeId: data.serviceProvider.id
                }));
            }).catch(error => {
                return error;
            });
        }
    }

    renderWindowResizeHandler(callBack) {
        return (
            <>
                <WindowResizeHandler callBack={callBack}/>
            </>
        )
    }

    handleWindowChange(dimensions) {
        this.setState({
            dimensions: dimensions
        })
    }

    render() {

        if (this.state.goHome === true || store.get('sessionId') === null || store.get('sessionId') === undefined) {
            console.log('>>> No sessionId, window.location.assign ', homepath);
            window.location.assign(homepath);
            return "";
        } else {

            let img = '';
            if (get_ui_variant() === 'ISLAB') {
                if (this.state.backgroundImage) {
                    img = 'data:image/' + this.state.backgroundImage.type + ';base64,' + this.state.backgroundImage.data;
                } else {
//                    console.log('Logo not loaded yet!');
                }
            } else {
                img = getStaticImage(this.state.selectedImage);
            }

            let isMobileReal = isMobile;
            if (!isMobile) {
                if (window.innerWidth < this.props.state.mobileBreakPoint) {
                    isMobileReal = true;
                }
            }

            let userInfo = [];
            let locationInfo;
            let contactInformationTitle;
            let contactInformationContent = [];

            if (this.state.data !== null) {
                let split = this.state.data.date.split("-");
                let date = split[2] + '.' + split[1] + '.' + split[0];

                let time = this.state.data.startTime + " - " + this.state.data.endTime;
                locationInfo =
                    <div>
                        <CheckHeader isMobile={isMobileReal}
                                     title={retrieveLanguageValue(this.props.state.language, Strings.calendar.weekday[get_weekday_name_from_date(this.state.data.date)])}
                                     info={date} image={require('../images/kalenteri-ikoni.svg')}
                        />
                        <CheckHeader isMobile={isMobileReal}
                                     title={retrieveLanguageValue(this.props.state.language, Strings.calendar.at)}
                                     info={time} image={require('../images/aika-iso-ikoni.svg')}
                        />
                        <CheckHeader isMobile={isMobileReal}
                                     title={retrieveLanguageValue(this.props.state.language, this.state.data.service.name)}
                                     info={retrieveLanguageValue(this.props.state.language, this.state.data.serviceProvider.name)}
                                     image={require('../images/organisaatio-iso-ikoni.svg')}
                        />
                    </div>

                let properties = this.state.data.properties;
                properties.map((property, m) => {
                    if (property.name.valueDefault === 'Yhteystiedot') {
                        contactInformationTitle = retrieveLanguageValue(this.props.state.language, property.name);
                        let contactProps = property.properties;
                        contactProps.sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : (a.orderNumber === b.orderNumber) ? ((a.propertyId > b.propertyId) ? 1 : -1) : -1);
                        contactInformationContent.push(contactProps.map(prop => {
                            let values = this.state.data.values;
                            for (let i = 0; i < values.length; i++) {
                                if (values[i].propertyId === prop.propertyId) {
                                    return <p
                                        key={prop.propertyId}>{retrieveLanguageValue(this.props.state.language, prop.name)}{": "} {values[i].propertyValue}</p>
                                }
                            }
                            return null;
                        }));
                    } else {
                        property.properties.forEach((subProperty) => {
                            const value = getPropertyValue(this.state.mapped, subProperty, this.props.state.language);
                            userInfo.push(
                                <>
                                    <p key={subProperty.propertyId}> {retrieveLanguageValue(this.props.state.language, subProperty.name)}
                                        {": "}
                                        {value}
                                    </p>
                                </>
                            );
                        });
                    }
                    return null;
                });
            }

            if (store.get("updated") !== undefined && store.get("updated") !== null) {
                let updated = store.get("updated");
                if (updated) {
                    confirmAlert({
                        message: retrieveLanguageValue(this.props.state.language, Strings.generic.updatedStatus),
                        buttons: [{label: "OK"}],
                        closeOnEscape: true,
                        closeOnClickOutside: true,
                    });
                }
                store.set("updated", null);
            }

            if (isMobileReal) {
                return (
                    <div>
                        <TopNavigation globalGetNavigationState={this.props.globalGetNavigationState}
                                       globalSetNavigationState={this.props.globalSetNavigationState}
                                       name="confirmInformation" globalLang={this.props.globalLang}
                                       setSessionId={this.props.setSessionId} state={this.props.state}/>
                        <MyMenu name="confirmInfo" state={this.props.state}/>
                        {this.renderWindowResizeHandler(this.handleWindowChange)}
                        <div className="mobile-confirm-info-wrapper">
                            <div className="mobile-confirm-content-wrapper">
                                <div
                                    className="mobile-confirm-top-title">{retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.summaryOfReservation)}</div>
                                <p>
                                    <button className="mobile-delete-reservation-btn"
                                            onClick={this.showDeleteModal}>
                                        {retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.deleteReservation)}
                                    </button>
                                    <button className="mobile-close-session-btn"
                                            onClick={this.logout}>{retrieveLanguageValue(this.props.state.language, Strings.topNav.signOut)}
                                    </button>
                                </p>
                                <div className="mobile-confirm-major-desc">
                                    <p>
                                        <strong>{retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.reservationCode)}</strong> {this.state.reservationCode}
                                    </p>
                                    <p>{retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.modifyDescriptiveText)}</p>
                                    <p>{retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.modifyDescriptiveText2)}</p>
                                    <p>{retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.modifyDescriptiveText3)}</p>
                                </div>
                                <div>
                                    <div
                                        className="mobile-confirm-major-title">1. {retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.reservationTimeAndOffice)}
                                        (<Link style={{color: '#1e6fca'}} to={{
                                            pathname: '/change-reservation-time',
                                            state: {
                                                data: this.state.data,
                                                reservationCode: this.state.reservationCode,
                                                sessionId: this.state.sessionId,
                                                serviceId: this.state.serviceId
                                            }
                                        }}{...this.props}>{retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.modify)}</Link>)
                                    </div>
                                    <div className="confirm-major-items">
                                        {locationInfo}
                                    </div>
                                </div>
                                <div>
                                    <div
                                        className="mobile-confirm-major-title">2. {retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.reservationInfo)}
                                        &nbsp;(<Link style={{color: '#1e6fca'}} to={{
                                            pathname: '/edit-information',
                                            state: {
                                                data: this.state.data,
                                                reservationCode: this.state.reservationCode,
                                                sessionId: this.state.sessionId,
                                                serviceId: this.state.serviceId
                                            }
                                        }}>{retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.modify)}</Link>)
                                    </div>
                                    <div className="confirm-major-address">
                                        {userInfo}
                                    </div>
                                </div>
                                <div>
                                    <div className="mobile-confirm-major-title">3. {contactInformationTitle}</div>
                                    <div className="confirm-major-address">
                                        {contactInformationContent}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Modal isOpen={this.state.showDeleteModal} toggle={this.handleCloseModal} centered={true}>
                            <p className="modal-heading">{retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.deleteReservationConfirmation)}</p>
                            <span style={{
                                display: 'inline',
                                color: 'red',
                                fontSize: '13px'
                            }}>{this.state.deleteError !== null ? retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.loginError) : ''}</span>
                            <AvForm>
                                <div className="bttns">
                                    <Button color="primary" type="submit" onClick={this.deleteReservation}>
                                        {retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.deleteReservation)}
                                    </Button>
                                    <Button outline color="secondary" onClick={this.handleCloseModal}>
                                        {retrieveLanguageValue(this.props.state.language, Strings.topNav.cancel)}
                                    </Button>
                                </div>

                            </AvForm>
                        </Modal>
                    </div>
                );
            } else {
                return (
                    <div>
                        <TopNavigation name="confirmInformation"
                                       globalGetNavigationState={this.props.globalGetNavigationState}
                                       globalSetNavigationState={this.props.globalSetNavigationState}
                                       globalLang={this.props.globalLang}
                                       setSessionId={this.props.setSessionId} state={this.props.state}/>
                        <MyMenu name="confirmInformation" state={this.props.state}/>
                        {this.renderWindowResizeHandler(this.handleWindowChange)}
                        <div className="confirm-info-wrapper">
                            <div className="confirm-wrapper">
                                <img src={img} className="check-info-top-image" alt=""/>
                                <div className="confirm-content-wrapper">
                                    <div
                                        className="confirm-top-title">{retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.summaryOfReservation)}
                                        <i className="print-reservation">
                                            <ReactToPrint
                                                trigger={() => <FontAwesomeIcon icon={faPrint}/>}
                                                content={() => this.componentRef}
                                                closeAfterPrint={true}
                                                bodyClass="printMargin"
                                            />

                                        </i>
                                    </div>

                                    <div className="bttns"
                                         style={{
                                             position: "absolute",
                                             top: "80px",
                                             right: "40px"
                                         }}
                                    >

                                        <button className={get_css_class_and_with_variant("office-button-ISLAB")}
                                                style={{
                                                    color: "#fff"
                                                }}
                                                onClick={this.logout}>{retrieveLanguageValue(this.props.state.language, Strings.topNav.signOut)}
                                        </button>

                                        <button className="delete-reservation-btn text-center"
                                                onClick={this.showDeleteModal}>{retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.deleteReservation)}
                                        </button>
                                    </div>
                                    <ToPrint ref={el => (this.componentRef = el)} {...this.props} cstate={this.state}/>
                                </div>
                            </div>
                        </div>

                        <Modal isOpen={this.state.showDeleteModal} toggle={this.handleCloseModal} centered={true}>
                            <p className="modal-heading">{retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.deleteReservationConfirmation)}</p>
                            <span style={{
                                display: 'inline',
                                color: 'red',
                                fontSize: '13px'
                            }}>{this.state.deleteError !== null ? retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.loginError) : ''}</span>
                            <AvForm>
                                <div className="bttns">
                                    <Button color="primary" type="submit" onClick={this.deleteReservation}>
                                        {retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.deleteReservation)}
                                    </Button>
                                    <Button outline color="secondary" onClick={this.handleCloseModal}>
                                        {retrieveLanguageValue(this.props.state.language, Strings.topNav.cancel)}
                                    </Button>
                                </div>

                            </AvForm>
                        </Modal>
                    </div>
                );
            }
        }
    }
}

function CheckHeader(props) {
    if (props.isMobile) {
        return (
            <div className="mobile-confirm-header">
                <img src={props.image} className="confirm-thumbnail" alt="prop-img"/>
                <div className="confirm-date-time-loc">
                    <p>{props.title}</p>
                    <p>{props.info}</p>
                </div>
            </div>
        );
    } else {
        return (
            <div className="confirm-header">
                <img src={props.image} className="confirm-thumbnail" alt="prop-img"/>
                <div className="confirm-date-time-loc">
                    <p>{props.title}</p>
                    <p>{props.info}</p>
                </div>
            </div>
        );
    }
}

class ToPrint extends Component {

    render() {
        let userInfo = [];
        let locationInfo;
        let contactInformationTitle;
        let contactInformationContent = [];

        if (this.props.cstate !== null && this.props.cstate.data !== null) {
            let time = this.props.cstate.data.startTime + " - " + this.props.cstate.data.endTime;
            let split = this.props.cstate.data.date.split("-");
            let date = split[2] + '.' + split[1] + '.' + split[0];
            locationInfo =
                <div>
                    <CheckHeader
                        title={retrieveLanguageValue(this.props.state.language, Strings.calendar.weekday[get_weekday_name_from_date(this.props.cstate.data.date)])}
                        info={date} image={require('../images/kalenteri-ikoni.svg')}
                    />
                    <CheckHeader title={retrieveLanguageValue(this.props.state.language, Strings.calendar.at)}
                                 info={time} image={require('../images/aika-iso-ikoni.svg')}
                    />
                    <CheckHeader
                        title={retrieveLanguageValue(this.props.state.language, this.props.cstate.data.service.name)}
                        info={retrieveLanguageValue(this.props.state.language, this.props.cstate.data.serviceProvider.name)}
                        image={require('../images/organisaatio-iso-ikoni.svg')}
                    />
                </div>

            let properties = this.props.cstate.data.properties;
            properties.map((property, m) => {
                if (property.name.valueDefault === 'Yhteystiedot') {
                    contactInformationTitle = retrieveLanguageValue(this.props.state.language, property.name);
                    let contactProps = property.properties;
                    contactProps.sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : (a.orderNumber === b.orderNumber) ? ((a.propertyId > b.propertyId) ? 1 : -1) : -1);
                    contactInformationContent.push(contactProps.map(prop => {
                            let values = this.props.cstate.data.values;
                            for (let i = 0; i < values.length; i++) {
                                if (values[i].propertyId === prop.propertyId) {
                                    return <p
                                        key={prop.propertyId}>{retrieveLanguageValue(this.props.state.language, prop.name)}{": "} {values[i].propertyValue}</p>
                                }
                            }
                            return null;
                        })
                    );
                } else {
                    property.properties.forEach((subProperty) => {
                        const value = getPropertyValue(this.props.cstate.mapped, subProperty, this.props.state.language);
                        userInfo.push(
                            <>
                                <p key={subProperty.propertyId}> {retrieveLanguageValue(this.props.state.language, subProperty.name)}
                                    {": "}
                                    {value}
                                </p>
                            </>
                        );

                    });
                }
                return null;
            });
        }

        return (
            <div>
                <div className="confirm-major-desc">
                    <p>
                        <strong>{retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.reservationCode)}</strong> {this.props.cstate.reservationCode}
                    </p>
                    <p>{retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.modifyDescriptiveText)}</p>
                    <p>{retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.modifyDescriptiveText2)}</p>
                    <p>{retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.modifyDescriptiveText3)}</p>
                </div>
                <div>
                    <div
                        className="confirm-major-title">1. {retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.reservationTimeAndOffice)}
                        &nbsp;(<Link style={{color: '#1e6fca'}} to={{
                            pathname: '/change-reservation-time',
                            state: {
                                data: this.props.cstate.data,
                                reservationCode: this.props.cstate.reservationCode,
                                sessionId: this.props.cstate.sessionId,
                                serviceId: this.props.cstate.serviceId
                            }
                        }}>{retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.modify)}</Link>)
                    </div>
                    <div className="confirm-major-items">
                        {locationInfo}
                    </div>
                </div>
                <div>
                    <div
                        className="confirm-major-title">2. {retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.reservationInfo)}
                        &nbsp;(<Link style={{color: '#1e6fca'}} to={{
                            pathname: '/edit-information',
                            state: {
                                data: this.props.cstate.data,
                                reservationCode: this.props.cstate.reservationCode,
                                sessionId: this.props.cstate.sessionId,
                                serviceId: this.props.cstate.serviceId
                            }
                        }}>{retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.modify)}</Link>)
                    </div>
                    <div className="confirm-major-address">
                        {userInfo}
                    </div>
                </div>
                <div>
                    <div className="mobile-confirm-major-title">3. {contactInformationTitle}</div>
                    <div className="confirm-major-address">
                        {contactInformationContent}
                    </div>
                </div>
            </div>
        );
    }
}

export default ConfirmInformation;
