// profile islab2-frontend-build, copied by maven-resources-plugin
// to src/main/frontend/src/utils/
const Strings = {

    newCalendar: {
        "today": {
            "valueDefault":"Tänään",
            "valueFinnish":"Tänään",
            "valueSwedish":"I dag",
            "valueEnglish":"Today"
        },
        "month": {
            "valueDefault":"Kuukausi",
            "valueFinnish":"Kuukausi",
            "valueSwedish":"Månad",
            "valueEnglish":"Month"
        },
        "week": {
            "valueDefault":"Viikko",
            "valueFinnish":"Viikko",
            "valueSwedish":"Vecka",
            "valueEnglish":"Week"
        },
        "day": {
            "valueDefault":"Päivä",
            "valueFinnish":"Päivä",
            "valueSwedish":"Dag",
            "valueEnglish":"Day"
        },
        "moreLinksAvailable": {
            "valueDefault":"saatavilla",
            "valueFinnish":"saatavilla",
            "valueSwedish":"tillgängliga",
            "valueEnglish":"available"
        },
    },

    menu: {
        "chooseAService": {
            "valueDefault":"Valitse palvelu",
            "valueFinnish":"Valitse palvelu",
            "valueSwedish":"Välj en tjänst",
            "valueEnglish":"Choose a service"
        },

        "chooseAnOffice": {
            "valueDefault":"Valitse toimipiste",
            "valueFinnish":"Valitse toimipiste",
            "valueSwedish":"Välj ett serviceställe",
            "valueEnglish":"Choose a service location"
        },

        "chooseATime": {
            "valueDefault":"Valitse aika",
            "valueFinnish":"Valitse aika",
            "valueSwedish":"Välj tid",
            "valueEnglish":"Choose a time"
        },

        "chooseNewTime": {
            "valueDefault":"VALITSE UUSI AIKA",
            "valueFinnish":"VALITSE UUSI AIKA",
            "valueSwedish":"VÄLJA EN NY TID",
            "valueEnglish":"SELECT A NEW TIME",
        },

        "enterInfo": {
            "valueDefault":"Täytä tiedot",
            "valueFinnish":"Täytä tiedot",
            "valueSwedish":"Ange information",
            "valueEnglish":"Enter information"
        },
        "confirmInfo": {
            "valueDefault":"Varauksen tiedot",
            "valueFinnish":"Varauksen tiedot",
            "valueSwedish":"Bokningsinformation",
            "valueEnglish":"Reservation info",
        },

        "editInfo": {
            "valueDefault":"Muokkaa tietoja",
            "valueFinnish":"Muokkaa tietoja",
            "valueSwedish":"Redigera information",
            "valueEnglish":"Edit Information",
        },

        "checkInfo": {
            "valueDefault":"Tarkista tiedot",
            "valueFinnish":"Tarkista tiedot",
            "valueSwedish":"Kolla information",
            "valueEnglish":"Check information"
        },

        "Valitse päivämäärä": {
            "valueDefault":"Valitse päivämäärä",
            "valueFinnish":"Valitse päivämäärä",
            "valueSwedish":"Välja datum",
            "valueEnglish":"Choose date"
        },
    },
	
	topNav: {
		"reservationCode": {
			"valueDefault":"Muuta tai peru varaus",
			"valueFinnish":"Muuta tai peru varaus",
			"valueSwedish":"Ge bokningsreferens",
			"valueEnglish":"Reservation code"
		},
		"login": {
			"valueDefault":"Jatka",
			"valueFinnish":"Jatka",
			"valueSwedish":"Fortsätta",
			"valueEnglish":"Proceed"
		},
		"cancel": {
			"valueDefault":"Sulje",
			"valueFinnish":"Sulje",
			"valueSwedish":"Sluta",
			"valueEnglish":"Close"
		},
		"popupTitle": {
			"valueDefault":"Syötä varaustunnus",
			"valueFinnish":"Syötä varaustunnus",
			"valueSwedish":"Ge bokningsreferens",
			"valueEnglish":"Please enter a reservation code"
		},
		"popupDescription": {
			"valueDefault":"Syötä sinulle lähetetty varaustunnus, mikäli haluat tarkastella tai muokata varauksesi tietoja.",
			"valueFinnish":"Syötä sinulle lähetetty varaustunnus, mikäli haluat tarkastella tai muokata varauksesi tietoja.",
			"valueSwedish":"Vänligen ange bokningskoden du har fått om du vill visa eller ändra bokningsinformationen.",
			"valueEnglish":"Please enter the reservation code you have received if you'd like to view or change the reservation information."
		},
		"signOut" : {
			"valueDefault":"Sulje istunto",
			"valueFinnish":"Sulje istunto",
			"valueSwedish":"Stäng session",
			"valueEnglish":"Close session"
		}, 
		"goToReview" : {
			"valueDefault":"Anna palautetta",
			"valueFinnish":"Anna palautetta",
			"valueSwedish":"Ge feedback",
			"valueEnglish":"Give feedback"
		},
		"cannotBeEmpty" : {
			"valueDefault":"Ei voi olla tyhjä",
			"valueFinnish":"Ei voi olla tyhjä",
			"valueSwedish":"Kan inte vara tom",
			"valueEnglish":"Cannot be empty"
		},
		"loginError" : {
			"valueDefault":"Kirjautuminen varaustunnuksen avulla ei onnistunut. Varausta ei löytynyt, varaustunnus on virheellinen tai varaus on peruttu.",
			"valueFinnish":"Kirjautuminen varaustunnuksen avulla ei onnistunut. Varausta ei löytynyt, varaustunnus on virheellinen tai varaus on peruttu.",
			"valueSwedish":"Logga in med hjälp av bokningskoden misslyckades. Bokningen kunde inte hittas, bokningen är ogiltig eller bokningen har annullerats.",
			"valueEnglish":"Logging in using the reservation code failed. The reservation was not found, reservation is invalid or the reservation has been canceled."
		},
		"backEndError" : {
			"valueDefault":"Tapahtui tekninen virhe %TIME_AND_DATE%. Jos ongelma toistuu, ilmoita palvelun tarjoajalle.",
			"valueFinnish":"Tapahtui tekninen virhe %TIME_AND_DATE%. Jos ongelma toistuu, ilmoita palvelun tarjoajalle.",
			"valueSwedish":"Tekniskt fel %TIME_AND_DATE%. Kontakta tjänsteleverantören om ett fel uppstår igen.",
			"valueEnglish":"Technical error at %TIME_AND_DATE%. If error re-occurs, contact service provider."
		},

	},

	chooseService: {
		"title": {
			"valueDefault":"Tervetuloa Digi- ja väestötietoviraston ajanvaraukseen",
			"valueFinnish":"Tervetuloa Digi- ja väestötietoviraston ajanvaraukseen",
			"valueSwedish":"Välkommen till tidsbokning för Myndigheten för digitalisering och befolkningsdata",
			"valueEnglish":"Welcome to the time reservation of Digital and Population Data Services Agency"
		},
		"description": {
			"valueDefault":"Digi- ja väestötietoviraston ajanvarausjärjestelmässä voit tehdä varauksen sähköisesti viraston tarjoamiin palveluihin paikkakuntakohtaisesti. Valitse alta palvelu, jonka jälkeen voit valita toimipisteen ja jatkaa varauksen tekemistä. Jos haluat tarkastella jo tehtyä varausta tai muuttaa sitä, kirjaudu palveluun sivun yläpalkista sinulle lähetetyllä varaustunnuksella. Huom! Digi- ja väestötietoviraston ajanvarausjärjestelmän välityksellä tarjoamat palvelut ovat toimipistekohtaisia, eivätkä kaikki palvelut ole saatavilla kaikissa toimipisteissä. Lisätietoja Digi- ja väestötietoviraston sähköisen ajanvarauksen välityksellä tarjoamista palveluista saat Digi- ja väestötietovirastosta. <a href='https://www.dvv.fi'>www.dvv.fi</a>",
			"valueFinnish":"Digi- ja väestötietoviraston ajanvarausjärjestelmässä voit tehdä varauksen sähköisesti viraston tarjoamiin palveluihin paikkakuntakohtaisesti. Valitse alta palvelu, jonka jälkeen voit valita toimipisteen ja jatkaa varauksen tekemistä. Jos haluat tarkastella jo tehtyä varausta tai muuttaa sitä, kirjaudu palveluun sivun yläpalkista sinulle lähetetyllä varaustunnuksella. Huom! Digi- ja väestötietoviraston ajanvarausjärjestelmän välityksellä tarjoamat palvelut ovat toimipistekohtaisia, eivätkä kaikki palvelut ole saatavilla kaikissa toimipisteissä. Lisätietoja Digi- ja väestötietoviraston sähköisen ajanvarauksen välityksellä tarjoamista palveluista saat Digi- ja väestötietovirastosta. <a href='https://www.dvv.fi'>www.dvv.fi</a>",
			"valueSwedish":"Du kan boka online en tjänst som Myndigheten för digitalisering och befolkningsdata tillhandahåller på sina serviceställen. Välj nedan en tjänst, varefter du kan välja serviceställe och fortsätta med bokningen. Om du vill granska eller ändra en befintlig reservation, logga in på tjänsten med boknings-ID som skickats till dig högst upp på sidan. Obs! Tjänsterna som tillhandahålls via bokningssystemet är specifika för servicestället och alla tjänster är inte tillgängliga på alla serviceställen. Du kan få mer information från Myndigheten för digitalisering och befolkningsdata om de tjänster som finns tillgängliga via online-bokningssystemet. <a href='https://www.dvv.fi/sv'>www.dvv.fi/sv</a>",
			"valueEnglish":"You can make a reservation for the services provided by the Digital and Population Data Services Agency per each city. Choose a service after which you can select the service location and proceed in making the reservation. If you would like to view or change an existing reservation, sign in to the service with the reservation ID sent to you, at the top of the page. Note! The services provided via the booking system are specific to each service location, and not all services are available at all locations. You can get more information from The Digital and Population Data Services Agency about the services that are available via online reservation system. <a href='https://www.dvv.fi/en'>www.dvv.fi/en</a>"
		},
		"serviceListings": {
			"valueDefault":"Valitse palvelu jatkaaksesi toimipisteen valintaan",
			"valueFinnish":"Valitse palvelu jatkaaksesi toimipisteen valintaan",
			"valueSwedish":"Välj en tjänst för att fortsätta att välja serviceställe",
			"valueEnglish":"Select a service to proceed to selecting the service location"
		},
		"otherServices": {
			valueDefault: "Muut palvelut",
			valueFinnish: "Muut palvelut",
			valueSwedish: "Andra tjänster",
			valueEnglish: "Other Services"
		}

	},

	chooseOffice: {
        "selectedServiceGroup": {
            "valueDefault":"Valittu palveluryhmä",
            "valueFinnish":"Valittu palveluryhmä",
            "valueSwedish":"Vald tjänst grup",
            "valueEnglish":"Selected service group"
        },
        "selectedService": {
			"valueDefault":"Valittu palvelu",
			"valueFinnish":"Valittu palvelu",
			"valueSwedish":"Vald tjänst",
			"valueEnglish":"Selected service"
		},
		"selectOffice": {
			"valueDefault":"Valitse toimipiste",
			"valueFinnish":"Valitse toimipiste",
			"valueSwedish":"Välj serviceställe",
			"valueEnglish":"Select service location"
		},
		"selectOfficeNote": {
			"valueDefault":"",
			"valueFinnish":"",
			"valueSwedish":"",
			"valueEnglish":""
		},
		"selectTime": {
			"valueDefault":"Jatka ajan valintaan",
			"valueFinnish":"Jatka ajan valintaan",
			"valueSwedish":"Välj tid",
			"valueEnglish":"Select time"
		},
		"selectedServiceInfo": {
			"valueDefault": "Tietoa valitusta palvelusta",
			"valueFinnish":"Tietoa valitusta palvelusta",
			"valueSwedish":"Information om den valda tjänsten",
			"valueEnglish":"Information on the selected service"
		},

	},

	calendar: {
		"bookThisTime": {
			"valueDefault":"Varaa tämä aika",
			"valueFinnish":"Varaa tämä aika",
			"valueSwedish":"Boka den här tiden",
			"valueEnglish":"Book this time"
		}, 
		"mobileBookThisTime": {
			"valueDefault":"Varaa tämä",
			"valueFinnish":"Varaa tämä",
			"valueSwedish":"Boka detta",
			"valueEnglish":"Book this"
		},
		"availableTimeTitle": {
			"valueDefault":"Valitse vapaa aika päivämäärälle:",
			"valueFinnish":"Valitse vapaa aika päivämäärälle:",
			"valueSwedish":"Välj tillgänglig tid för datumet",
			"valueEnglish":"Select available time for the date:",
		},
		"noTimes": {
			"valueDefault":"EI AIKOJA",
			"valueFinnish":"EI AIKOJA",
			"valueSwedish":"Inga tider",
			"valueEnglish":"No times",
		},
		"reserved": {
			"valueDefault":"VARATTU",
			"valueFinnish":"VARATTU",
			"valueSwedish":"Reserverad",
			"valueEnglish":"Reserved",
		},
		"at": {
			"valueDefault":"Kello",
			"valueFinnish":"Kello",
			"valueSwedish":"Kl",
			"valueEnglish":"At",
		},
		"bookThis": {
			"valueDefault":"Valitse tämä aika",
			"valueFinnish":"Valitse tämä aika",
			"valueSwedish":"Boka detta",
			"valueEnglish":"Book this",
		},
		"available": {
			"valueDefault":"saatavilla",
			"valueFinnish":"saatavilla",
			"valueSwedish":"tillgängliga",
			"valueEnglish":"available",
		},
		"mobileAvailable": {
			"valueDefault":"AIKAA",
			"valueFinnish":"AIKAA",
			"valueSwedish":"TIDER",
			"valueEnglish":"AVAIL",
		},
		"time": {
			"valueDefault":"AIKA",
			"valueFinnish":"AIKA",
			"valueSwedish":"Tid",
			"valueEnglish":"Time",
		},
		"selectAvailableTime": {
			"valueDefault":"Valitse vapaa aika",
			"valueFinnish":"Valitse vapaa aika",
			"valueSwedish":"Välj tillgänglig tid",
			"valueEnglish":"Select available time",
		},
		"week": {
			"valueDefault":"Viikko ",
			"valueFinnish":"Viikko ",
			"valueSwedish":"Vecka",
			"valueEnglish":"Week ",
		},
		"month": {
			"valueDefault":"Kuukausi ",
			"valueFinnish":"Kuukausi ",
			"valueSwedish":"Månad",
			"valueEnglish":"Month",
		},
		weekday: {
			"sunday": {
				"valueDefault":"Sunnuntai",
				"valueFinnish":"Sunnuntai",
				"valueSwedish":"Söndag",
				"valueEnglish":"Sunday",
			},
			"monday": {
				"valueDefault":"Maanantai",
				"valueFinnish":"Maanantai",
				"valueSwedish":"Måndag",
				"valueEnglish":"Monday",
			},
			"tuesday": {
				"valueDefault":"Tiistai",
				"valueFinnish":"Tiistai",
				"valueSwedish":"Tisdag",
				"valueEnglish":"Tuesday",
			},
			"wednesday": {
				"valueDefault":"Keskiviikko",
				"valueFinnish":"Keskiviikko",
				"valueSwedish":"Onsdag",
				"valueEnglish":"Wednesday",
			},
			"thursday": {
				"valueDefault":"Torstai",
				"valueFinnish":"Torstai",
				"valueSwedish":"Torsdag",
				"valueEnglish":"Thursday",
			},
			"friday": {
				"valueDefault":"Perjantai",
				"valueFinnish":"Perjantai",
				"valueSwedish":"Fredag",
				"valueEnglish":"Friday",
			},
			"saturday": {
				"valueDefault":"Lauantai",
				"valueFinnish":"Lauantai",
				"valueSwedish":"Lördag",
				"valueEnglish":"Saturday",
			}
		},
		mobileweekday: {
			"mo": {
				"valueDefault":"MA",
				"valueFinnish":"MA",
				"valueSwedish":"MO",
				"valueEnglish":"MO",
			},
			"tu": {
				"valueDefault":"TI",
				"valueFinnish":"TI",
				"valueSwedish":"TI",
				"valueEnglish":"TU",
			},
			"we": {
				"valueDefault":"KE",
				"valueFinnish":"KE",
				"valueSwedish":"ON",
				"valueEnglish":"WE",
			},
			"th": {
				"valueDefault":"TO",
				"valueFinnish":"TO",
				"valueSwedish":"TO",
				"valueEnglish":"TH",
			},
			"fr": {
				"valueDefault":"PE",
				"valueFinnish":"PE",
				"valueSwedish":"FR",
				"valueEnglish":"FR",
			},
			"sa": {
				"valueDefault":"LA",
				"valueFinnish":"LA",
				"valueSwedish":"LÖ",
				"valueEnglish":"SA",
			}
		},
        months: {
            "m0": {
                "valueDefault":"Tammikuu",
                "valueFinnish":"Tammikuu",
                "valueSwedish":"Januari",
                "valueEnglish":"January",
            },
            "m1": {
                "valueDefault":"Helmikuu",
                "valueFinnish":"Helmikuu",
                "valueSwedish":"Februari",
                "valueEnglish":"February",
            },
            "m2": {
                "valueDefault":"Maaliskuu",
                "valueFinnish":"Maaliskuu",
                "valueSwedish":"Mars",
                "valueEnglish":"March",
            },
            "m3": {
                "valueDefault":"Huhtikuu",
                "valueFinnish":"Huhtikuu",
                "valueSwedish":"April",
                "valueEnglish":"April",
            },
            "m4": {
                "valueDefault":"Toukokuu",
                "valueFinnish":"Toukokuu",
                "valueSwedish":"Maj",
                "valueEnglish":"May",
            },
            "m5": {
                "valueDefault":"Kesäkuu",
                "valueFinnish":"Kesäkuu",
                "valueSwedish":"Juni",
                "valueEnglish":"June",
            },
            "m6": {
                "valueDefault":"Heinäkuu",
                "valueFinnish":"Heinäkuu",
                "valueSwedish":"Juli",
                "valueEnglish":"July",
            },
            "m7": {
                "valueDefault":"Elokuu",
                "valueFinnish":"Elokuu",
                "valueSwedish":"Augusti",
                "valueEnglish":"August",
            },
            "m8": {
                "valueDefault":"Syyskuu",
                "valueFinnish":"Syyskuu",
                "valueSwedish":"September",
                "valueEnglish":"September",
            },
            "m9": {
                "valueDefault":"Lokakuu",
                "valueFinnish":"Lokakuu",
                "valueSwedish":"Oktober",
                "valueEnglish":"October",
            },
            "m10": {
                "valueDefault":"Marraskuu",
                "valueFinnish":"Marraskuu",
                "valueSwedish":"November",
                "valueEnglish":"November",
            },
            "m11": {
                "valueDefault":"Joulukuu",
                "valueFinnish":"Joulukuu",
                "valueSwedish":"December",
                "valueEnglish":"December",
            }
        },
		monthshort: {
			"jan": {
				"valueDefault":"Tammi",
				"valueFinnish":"Tammi",
				"valueSwedish":"Jan",
				"valueEnglish":"Jan",
			},
			"feb": {
				"valueDefault":"Helmi",
				"valueFinnish":"Helmikuu",
				"valueSwedish":"Feb",
				"valueEnglish":"Feb",
			},
			"mar": {
				"valueDefault":"Maalis",
				"valueFinnish":"Maalis",
				"valueSwedish":"Mars",
				"valueEnglish":"Mar",
			},
			"apr": {
				"valueDefault":"Huhti",
				"valueFinnish":"Huhti",
				"valueSwedish":"Apr",
				"valueEnglish":"Apr",
			},
			"may": {
				"valueDefault":"Touko",
				"valueFinnish":"Touko",
				"valueSwedish":"Maj",
				"valueEnglish":"May",
			},
			"jun": {
				"valueDefault":"Kesä",
				"valueFinnish":"Kesä",
				"valueSwedish":"Jun",
				"valueEnglish":"Jun",
			},
			"jul": {
				"valueDefault":"Heinä",
				"valueFinnish":"Heinä",
				"valueSwedish":"Jul",
				"valueEnglish":"Jul",
			},
			"aug": {
				"valueDefault":"Elo",
				"valueFinnish":"Elo",
				"valueSwedish":"Aug",
				"valueEnglish":"Aug",
			},
			"sep": {
				"valueDefault":"Syys",
				"valueFinnish":"Syys",
				"valueSwedish":"Sep",
				"valueEnglish":"Sep",
			},
			"oct": {
				"valueDefault":"Loka",
				"valueFinnish":"Loka",
				"valueSwedish":"Okt",
				"valueEnglish":"Oct",
			},
			"nov": {
				"valueDefault":"Marras",
				"valueFinnish":"Marras",
				"valueSwedish":"Nov",
				"valueEnglish":"Nov",
			},
			"dec": {
				"valueDefault":"Joulu",
				"valueFinnish":"Joulu",
				"valueSwedish":"Dec",
				"valueEnglish":"Dec",
			}
		}
	},
	enterInfo: {
		"continueToConfirmBtn": {
			"valueDefault":"Tietojen tarkistukseen",
			"valueFinnish":"Tietojen tarkistukseen",
			"valueSwedish":"Fortsätt till bekräftelse",
			"valueEnglish":"Continue to confirmation",
		},
		"reservationCustomerInfo": {
			"valueDefault":"Varauksen tiedot",
			"valueFinnish":"Varauksen tiedot",
			"valueSwedish":"Bokningsinformation",
			"valueEnglish":"Reservation information",
		},
		"legend": {
			"valueDefault":"Kaikki ( * ) merkityt tiedot ovat pakollisia.",
			"valueFinnish":"Kaikki ( * ) merkityt tiedot ovat pakollisia.",
			"valueSwedish":"Alla fält markerade ( * ) är obligatoriska.",
			"valueEnglish":"All items marked ( * ) are mandatory.",
		},
		"mandatoryFieldMissing": {
			"valueDefault":"Pakollisia tietoja puuttuu.",
			"valueFinnish":"Pakollisia tietoja puuttuu.",
			"valueSwedish":"Obligatorisk information saknas.",
			"valueEnglish":"Mandatory information missing.",
		},
	},
	checkInfo: {
		"checkReservationInfo": {
			"valueDefault":"Tarkista varauksen tiedot ja tallenna varaus",
			"valueFinnish":"Tarkista varauksen tiedot ja tallenna varaus",
			"valueSwedish":"Kolla bokningsinformationen och spara bokningen",
			"valueEnglish":"Check the reservation info and save the reservation",
		},
		"crossCheckInfoText": {
			"valueDefault":"Tarkista, että täyttämäsi tiedot ovat oikein. Mikäli havaitset virheitä, palaa muokkaamaan yläpalkin murupolusta. Mikäli tiedot ovat oikein, tallenna varaus",
			"valueFinnish":"Tarkista, että täyttämäsi tiedot ovat oikein. Mikäli havaitset virheitä, palaa muokkaamaan yläpalkin murupolusta. Mikäli tiedot ovat oikein, tallenna varaus",
			"valueSwedish":"Kontrollera att informationen du har angett är korrekt. Om du ser några fel, återgå till att ändra informationen genom att använda länkarna i sökvägen ovan. Om informationen är korrekt, spara reservationen.",
			"valueEnglish":"Check that the information you have entered is correct. If you see any errors, return to modify the information by using the links in the path above. If the information is correct, save the reservation.",
		},
		"timeOffice": {
			"valueDefault":"Varattava aika ja toimipiste",
			"valueFinnish":"Varattava aika ja toimipiste",
			"valueSwedish":"Bokningstid och kontor",
			"valueEnglish":"Reservation time and office",
		},
		"reservationInfo": {
			"valueDefault":"Varauksen tiedot",
			"valueFinnish":"Varauksen tiedot",
			"valueSwedish":"Bokningsinformation",
			"valueEnglish":"Reservation info",
		},
		"saveReservation": {
			"valueDefault":"Tallenna varaus",
			"valueFinnish":"Tallenna varaus",
			"valueSwedish":"Spara din bokning",
			"valueEnglish":"Save the reservation",
		},
        "doSave": {
            "valueDefault":" hyväksyn ehdot:",
            "valueFinnish":" hyväksyn ehdot:",
            "valueSwedish":" jag accepterar villkoren:",
            "valueEnglish":" I accept the terms:",
        },
        "consent_url_placeholder": {
            "valueDefault":"Varaamalla ajan tällä lomakkeella, annat luvan Itä-Suomen laboratoriokeskukselle henkilötietojesi käsittelyä varten. Olet lukenut ja hyväksyt Itä-Suomen laboratoriokeskuksen <a href='%URL%' target='_blank' rel='noopener noreferrer'>tietosuoja- ja rekisteriselosteet</a>.",
            "valueFinnish":"Varaamalla ajan tällä lomakkeella, annat luvan Itä-Suomen laboratoriokeskukselle henkilötietojesi käsittelyä varten. Olet lukenut ja hyväksyt Itä-Suomen laboratoriokeskuksen <a href='%URL%' target='_blank' rel='noopener noreferrer'>tietosuoja- ja rekisteriselosteet</a>.",
            "valueSwedish":"När du bokar en bokning med detta formulär ger du Itä-Suomen laboratoriokeskus tillstånd att hantera din personliga information. Du har läst och godkänt Itä-Suomen laboratoriokeskus <a href='%URL%' target='_blank' rel='noopener noreferrer'>dataskydd och registerbeskrivningar</a>.",
            "valueEnglish":"When you book a reservation using this form, you grant Itä-Suomen laboratoriokeskus the permission to manage your personal information. You have read and approved Itä-Suomen laboratoriokeskus’s <a href='%URL%' target='_blank' rel='noopener noreferrer'>data protection and registry descriptions.</a>.",
        },
        "consent": {
            "valueDefault":"Varaamalla ajan tällä lomakkeella, annat luvan Itä-Suomen laboratoriokeskukselle henkilötietojesi käsittelyä varten. Olet lukenut ja hyväksyt Itä-Suomen laboratoriokeskuksen <a href='https://www.islab.fi/tietoa-islabista/tietosuojaseloste_laboratoriotietojarjestelma' target='_blank' rel='noopener noreferrer'>tietosuoja- ja rekisteriselosteet</a>.",
            "valueFinnish":"Varaamalla ajan tällä lomakkeella, annat luvan Itä-Suomen laboratoriokeskukselle henkilötietojesi käsittelyä varten. Olet lukenut ja hyväksyt Itä-Suomen laboratoriokeskuksen <a href='https://www.islab.fi/tietoa-islabista/tietosuojaseloste_laboratoriotietojarjestelma' target='_blank' rel='noopener noreferrer'>tietosuoja- ja rekisteriselosteet</a>.",
            "valueSwedish":"När du bokar en bokning med detta formulär ger du Itä-Suomen laboratoriokeskus tillstånd att hantera din personliga information. Du har läst och godkänt Itä-Suomen laboratoriokeskus <a href='https://www.islab.fi/tietoa-islabista/tietosuojaseloste_laboratoriotietojarjestelma' target='_blank' rel='noopener noreferrer'>dataskydd och registerbeskrivningar</a>.",
            "valueEnglish":"When you book a reservation using this form, you grant Itä-Suomen laboratoriokeskus the permission to manage your personal information. You have read and approved Itä-Suomen laboratoriokeskus’s <a href='https://www.islab.fi/tietoa-islabista/tietosuojaseloste_laboratoriotietojarjestelma' target='_blank' rel='noopener noreferrer'>data protection and registry descriptions</a>.",
        },
		"additionalInfo": {
			"valueDefault":"Jos tiedot ovat oikein, paina Tallenna varaus -painiketta vahvistaaksesi ja tallentaaksesi ajanvarauksen.",
			"valueFinnish":"Jos tiedot ovat oikein, paina Tallenna varaus -painiketta vahvistaaksesi ja tallentaaksesi ajanvarauksen.",
			"valueSwedish":"Om informationen är korrekt klickar du på knappen Spara bokning för att bekräfta och spara bokningen.",
			"valueEnglish":"If the information is correct, click ‘Save reservation’ button in order to confirm and save the reservation.",
		},
		"save": {
			"valueDefault":"Tallenna",
			"valueFinnish":"Tallenna",
			"valueSwedish":"Spara",
			"valueEnglish":"Save",
		},
		"approvalTooltip": {
			"valueDefault":"Tietojen käsittelyn hyväksyntä puuttuu",
			"valueFinnish":"Tietojen käsittelyn hyväksyntä puuttuu",
			"valueSwedish":"Godkännande för behandling av information saknas",
			"valueEnglish":"Approval for processing information is missing",
		},
	},
	confirmInfo: {
		"summaryOfReservation": {
			"valueDefault":"Tallennetun varauksen yhteenveto",
			"valueFinnish":"Tallennetun varauksen yhteenveto",
			"valueSwedish":"Sammanfattning av en befintlig reservation",
			"valueEnglish":"Summary of an existing reservation",
		},
		"reservationCode": {
			"valueDefault":"Varaustunnus",
			"valueFinnish":"Varaustunnus",
			"valueSwedish":"Bokningsbekräftelse",
			"valueEnglish":"Reservation ID",
		},
        "modifyDescriptiveText": {
            "valueDefault":"Tietosi ovat nyt tallentuneet varausjärjestelmään. Tulosta sivu tai kirjoita varaustunnus ja vuoronumero muistiin! Tarvitset varaustunnusta, jos haluat myöhemmin tarkistaa ajan tai muuttaa ajankohtaa.",
            "valueFinnish":"Tietosi ovat nyt tallentuneet varausjärjestelmään. Tulosta sivu tai kirjoita varaustunnus ja vuoronumero muistiin! Tarvitset varaustunnusta, jos haluat myöhemmin tarkistaa ajan tai muuttaa ajankohtaa.",
            "valueSwedish":"Din bokningsinformation sparas nu i systemet. Skriv ut den här sidan eller anteckna bokningskoden och könumret! Du behöver bokningskod om du vill kontrollera eller ändra bokningen senare.",
            "valueEnglish":"Your reservation information is now saved to the system. Print this page or take a note of reservation code and queue number! You will need reservation code if you want later check or modify reservation.",
        },
        "modifyDescriptiveText2": {
            "valueDefault":"Mikäli haluat muokata varauksen tietoja nyt, valitse alta sen osa-alueen muokkaus-toiminto, jota haluat muuttaa.",
            "valueFinnish":"Mikäli haluat muokata varauksen tietoja nyt, valitse alta sen osa-alueen muokkaus-toiminto, jota haluat muuttaa.",
            "valueSwedish":"Om du vill ändra bokningsinformation nu väljer du ändringsåtgärd nedan.",
            "valueEnglish":"If you want to modify reservation information now then select modify action below.",
        },
        "modifyDescriptiveText3": {
            "valueDefault":"Sulje lopuksi istunto.",
            "valueFinnish":"Sulje lopuksi istunto.",
            "valueSwedish":"Stäng sessionen när du är klar.",
            "valueEnglish":"When you are done please close the session.",
        },
		"reservationTimeAndOffice": {
			"valueDefault":"Varattava aika ja toimipiste",
			"valueFinnish":"Varattava aika ja toimipiste",
			"valueSwedish":"Bokningstid och kontor",
			"valueEnglish":"Reservation time and office",
		},
		"reservationInfo": {
			"valueDefault":"Varauksen tiedot ",
			"valueFinnish":"Varauksen tiedot ",
			"valueSwedish":"Bokningsinformation ",
			"valueEnglish":"Reservation info ",
		},
		"modify": {
			"valueDefault":"muokkaa",
			"valueFinnish":"muokkaa",
			"valueSwedish":"ändra",
			"valueEnglish":"modify",
		},
		"saveChanges": {
			"valueDefault":"Tallenna muutokset",
			"valueFinnish":"Tallenna muutokset",
			"valueSwedish":"Spara ändringar",
			"valueEnglish":"Save changes",
		},
		"noChangesMade": {
			"valueDefault":"Varaukseen ei ole tehty mitään muutoksia",
			"valueFinnish":"Varaukseen ei ole tehty mitään muutoksia",
			"valueSwedish":"Inga ändringar gjorda i bokningen",
			"valueEnglish":"No changes made to the reservation",
		},
		"changesMade": {
			"valueDefault":"Varaukseen on tehty muutoksia",
			"valueFinnish":"Varaukseen on tehty muutoksia",
			"valueSwedish":"Ändringar har gjorts i bokningen",
			"valueEnglish":"Some changes have been made to the reservation",
		},
		"saveChangeOrMakeCopy": {
			"valueDefault":"Tallenna muutokset tai kopioi varaus",
			"valueFinnish":"Tallenna muutokset tai kopioi varaus",
			"valueSwedish":"Spara ändringarna eller gör en kopia",
			"valueEnglish":"Save the changes or make a copy",
		},
		"copyReservation": {
			"valueDefault":"Kopioi varaus",
			"valueFinnish":"Kopioi varaus",
			"valueSwedish":"Spara ändringarna eller gör en kopia",
			"valueEnglish":"Copy reservation",
		},
		"copyReservationInfo": {
			"valueDefault":"Jos haluat kopioida tämän varauksen tiedot ja luoda niistä uuden ajanvarauksen eri ajankohdalle, valitse tämä toiminto.",
			"valueFinnish":"Jos haluat kopioida tämän varauksen tiedot ja luoda niistä uuden ajanvarauksen eri ajankohdalle, valitse tämä toiminto.",
			"valueSwedish":"Om du vill kopiera bokningsinformation och skapa en ny bokning med dem till en annan tid, välj den här åtgärden.",
			"valueEnglish":"If you want to copy reservation info and create a new reservation with them to a different time, choose this action.",
		},
		"deleteReservation": {
			"valueDefault":"Peru ajanvaraus",
			"valueFinnish":"Peru ajanvaraus",
			"valueSwedish":"Avboka bokning",
			"valueEnglish":"Delete reservation",
		},
		"deleteReservationConfirmation": {
			"valueDefault":"Oletko varma, että haluat poistaa tämän varauksen?",
			"valueFinnish":"Oletko varma, että haluat poistaa tämän varauksen?",
			"valueSwedish":"Är du säker på att du vill radera den här bokningen?",
			"valueEnglish":"Are you sure you want to delete this reservation?",
		},
		"ok": {
			"valueDefault":"Ok",
			"valueFinnish":"Ok",
			"valueSwedish":"Ok",
			"valueEnglish":"Ok",
		},
		"checkReservation": {
			"valueDefault": "Ajanvarausta ei ole tallennettu, haluatko varmasti poistua?",
			"valueFinnish": "Ajanvarausta ei ole tallennettu, haluatko varmasti poistua?",
			"valueSwedish": "Ajanvarausta ei ole tallennettu, haluatko varmasti poistua?",
			"valueEnglish": "Please note that your reservation is not yet saved.",
		}
	},
	editInfo: {
		"menuTitle": {
			"valueDefault":"Muokkaa tietoja",
			"valueFinnish":"Muokkaa tietoja",
			"valueSwedish":"Redigera information",
			"valueEnglish":"Edit Information",
		},
		"returnWithoutChanges": {
			"valueDefault":"palaa tekemättä muutoksia",
			"valueFinnish":"palaa tekemättä muutoksia",
			"valueSwedish":"återvända utan att göra ändringar",
			"valueEnglish":"return without making changes",
		},
		"modify": {
			"valueDefault":"muokkaa",
			"valueFinnish":"muokkaa",
			"valueSwedish":"ändra",
			"valueEnglish":"modify",
		}
	},
	reviewService: {
		"menuTitle": {
			"valueDefault":"ANNA PALAUTETTA ASIAKASKOKEMUKSESTASI",
			"valueFinnish":"ANNA PALAUTETTA ASIAKASKOKEMUKSESTASI",
			"valueSwedish":"GE RESPONS PÅ DIN KUNDUPPLEVELSE",
			"valueEnglish":"GIVE FEEDBACK ON YOUR CUSTOMER EXPERIENCE",
		},
		"serviceExperience": {
			"valueDefault":"Kuinka asiointi palvelussamme sujui?",
			"valueFinnish":"Kuinka asiointi palvelussamme sujui?",
			"valueSwedish":"Hur var din erfarenhet av att använda vår service",
			"valueEnglish":"How was your experience in using our service?",
		},
		"excellent": {
			"valueDefault":"Loistavasti",
			"valueFinnish":"Loistavasti",
			"valueSwedish":"Excellent",
			"valueEnglish":"Excellent",
		},
		"good": {
			"valueDefault":"Hyvin",
			"valueFinnish":"Hyvin",
			"valueSwedish":"Bra",
			"valueEnglish":"Good",
		},
		"ok": {
			"valueDefault":"Ihan ok",
			"valueFinnish":"Ihan ok",
			"valueSwedish":"Ok",
			"valueEnglish":"Ok",
		},
		"notGood": {
			"valueDefault":"Ei hyvin",
			"valueFinnish":"Ei hyvin",
			"valueSwedish":"Not bra",
			"valueEnglish":"Not good",
		},
		"bad": {
			"valueDefault":"Huonosti",
			"valueFinnish":"Huonosti",
			"valueSwedish":"Dåligt",
			"valueEnglish":"Bad",
		},
		"feedbackDescText": {
			"valueDefault":"Palaute on meille tärkeää palvelun kehittämisen kannalta - kiitos palautteen jättämisestä! ",
			"valueFinnish":"Palaute on meille tärkeää palvelun kehittämisen kannalta - kiitos palautteen jättämisestä! ",
			"valueSwedish":"Feedback är viktigt för oss när det gäller att förbättra servicen - tack för din feedback!",
			"valueEnglish":"Feedback is important for us in improving the service - thank you for your feedback! ",
		},
		"backToFrontPage": {
			"valueDefault":"Palaa etusivulle",
			"valueFinnish":"Palaa etusivulle",
			"valueSwedish":"Tillbaka till framsidan",
			"valueEnglish":"Back to the front page",
		},
		"sendFeedbackMsg": {
			"valueDefault":"Lähetä palauteviesti",
			"valueFinnish":"Lähetä palauteviesti",
			"valueSwedish":"Skicka feedbackmeddelande",
			"valueEnglish":"Send feedback message",
		},
        "goBack": {
            "valueDefault":"Palaa takaisin",
            "valueFinnish":"Palaa takaisin",
            "valueSwedish":"Tillbaka",
            "valueEnglish":"Go back",
        },
        "chooseOffice": {
			"valueDefault":"* Valitse toimipiste",
			"valueFinnish":"* Valitse toimipiste",
			"valueSwedish":"* Välja serviceställe",
			"valueEnglish":"* Choose office location",
		},
		"feedbackRecipient": {
			"valueDefault":"* Palautteen vastaanottaja",
			"valueFinnish":"* Palautteen vastaanottaja",
			"valueSwedish":"* Feedback mottagare",
			"valueEnglish":"* Feedback recipient",
		},
		"feedbackSubject": {
			"valueDefault":"* Palautteen otsikko",
			"valueFinnish":"* Palautteen otsikko",
			"valueSwedish":"* Feedback ämne",
			"valueEnglish":"* Feedback subject",
		},
		"email": {
			"valueDefault":"* Sähköpostiosoitteeni",
			"valueFinnish":"* Sähköpostiosoitteeni",
			"valueSwedish":"* Min e-postadress",
			"valueEnglish":"* My email address",
		},
		"feedbackMsg": {
			"valueDefault":"* Palautteesi",
			"valueFinnish":"* Palautteesi",
			"valueSwedish":"* Ditt feedback meddelande",
			"valueEnglish":"* Your feedback message",
		},
		"officeStaff": {
			"valueDefault":"Toimipisteen henkilökunta",
			"valueFinnish":"Toimipisteen henkilökunta",
			"valueSwedish":"Kontorspersonal",
			"valueEnglish":"Office staff",
		},
		"sysAdmin": {
			"valueDefault":"Ylläpito",
			"valueFinnish":"Ylläpito",
			"valueSwedish":"Systemadministratörer",
			"valueEnglish":"System administrators",
		},
	},
	changeReservationDate: {
		"menuTitle": {
			"valueDefault":"VALITSE UUSI AIKA",
			"valueFinnish":"VALITSE UUSI AIKA",
			"valueSwedish":"VÄLJA EN NY TID",
			"valueEnglish":"SELECT A NEW TIME",
		}
	},
	generic: {
		"service": {
			"valueDefault":"Palvelu",
			"valueFinnish":"Palvelu",
			"valueSwedish":"Tjänsten",
			"valueEnglish":"Service"
		}, 
		"office": {
			"valueDefault":"Toimipiste",
			"valueFinnish":"Toimipiste",
			"valueSwedish":"Kontor",
			"valueEnglish":"Office"
		}, 
		"cannotBeEmpty" : {
			"valueDefault":"Ei voi olla tyhjä",
			"valueFinnish":"Ei voi olla tyhjä",
			"valueSwedish":"Kan inte vara tom",
			"valueEnglish":"Cannot be empty"
		},
        "phoneNumberFormat": {
            "valueDefault":"Syötä puhelinnumero muodossa 05012345678 tai +3585012345678, ilman välilyöntejä tai viivoja",
            "valueFinnish":"Syötä puhelinnumero muodossa 05012345678 tai +3585012345678, ilman välilyöntejä tai viivoja",
            "valueSwedish":"Ange ett telefonnummer i formatet 05012345678 eller +3585012345678, utan mellanslag eller bindestreck",
            "valueEnglish":"Enter a phone number in the format 05012345678 or +3585012345678, without spaces or dashes"
        },
		"numbersOnly" : {
			"valueDefault":"Syötä vain numeroita",
			"valueFinnish":"Syötä vain numeroita",
			"valueSwedish":"Ange bara siffror",
			"valueEnglish":"Enter numbers only"
		},
		"acceptedFormat": {
			"valueDefault":"Hyväksytty muoto: 04001234567",
			"valueFinnish":"Hyväksytty muoto: 04001234567",
			"valueSwedish":"Accepterat format: 04001234567",
			"valueEnglish":"Accepted format: 04001234567"
		},
		"emailExample": {
			"valueDefault":"Esimerkiksi: erkki.esimerkki@esimerkkiosoite.fi",
			"valueFinnish":"Esimerkiksi: erkki.esimerkki@esimerkkiosoite.fi",
			"valueSwedish":"Till exempel: erkki.esimerkki@esimerkkiosoite.fi",
			"valueEnglish":"Example: erkki.esimerkki@esimerkkiosoite.fi"
		},
		"maximum": {
			"valueDefault":"Enintään",
			"valueFinnish":"Enintään",
			"valueSwedish":"Högst",
			"valueEnglish":"Maximum",
		},
		"characters": {
			"valueDefault":" merkkiä",
			"valueFinnish":" merkkiä",
			"valueSwedish":" tecken",
			"valueEnglish":" characters",
		},
		"dateFormat": {
			"valueDefault":"Syötä muodossa P.K.VVVV",
			"valueFinnish":"Syötä muodossa P.K.VVVV",
			"valueSwedish":"Ange formuläret D.M.ÅÅÅÅ",
			"valueEnglish":"Format needs to be D.M.YYYY",
		},
		"menu": {
			"valueDefault":"Valikko",
			"valueFinnish":"Valikko",
			"valueSwedish":"Meny",
			"valueEnglish":"Menu",
		},
		"chooseOne": {
			"valueDefault":"Valitse yksi vaihtoehto",
			"valueFinnish":"Valitse yksi vaihtoehto",
			"valueSwedish":"Var god välj ett alternativ",
			"valueEnglish":"Please select one option",
		},
		nextFreeTime: {
	     "valueDefault":"Seuraava vapaa aika:",
	     "valueFinnish":"Seuraava vapaa aika:",
	     "valueSwedish":"Nästa ledig tid:",
	     "valueEnglish":"The next available time:",
	   },
	   noTimesAvailable: {
	     "valueDefault":"Ei varattavia aikoja",
	     "valueFinnish":"Ei varattavia aikoja",
	     "valueSwedish":"Inga tillgängliga tider",
	     "valueEnglish":"No available times",
	   },
	   freeTime: {
         "valueDefault":"VAPAAT AJAT",
         "valueFinnish":"VAPAAT AJAT",
         "valueSwedish":"LEDIGA TIDER",
         "valueEnglish":"AVAILABLE TIMES",
        },
        updatedStatus: {
	     "valueDefault":"Muuttuneet tiedot tallennettiin onnistuneesti.",
	     "valueFinnish":"Muuttuneet tiedot tallennettiin onnistuneesti.",
	     "valueSwedish":"Den ändrade informationen sparades framgångsrikt.",
	     "valueEnglish":"The changed information was saved successfully.",
	   },
	},
	errorMessages: {
        cannotDeleteReservationMarginals: {
            "valueDefault":"Palvelun tai toimipisteen aikamarginaalit estävät varauksen poiston. Varausta ei voi poistaa.",
            "valueFinnish":"Palvelun tai toimipisteen aikamarginaalit estävät varauksen poiston. Varausta ei voi poistaa.",
            "valueSwedish":"Servicens eller kontors tidsmarginaler hindrar avlägsnandet av bokningen. Bokningen kan inte avlägsnas.",
            "valueEnglish":"Time margins of the service prevent deleting reservation. Reservation cannot be deleted.",
        },
        cannotDeleteReservation: {
            "valueDefault":"Varausta ei voi perua tässä. Ota yhteys ajanavarauksen ylläpitoon.",
            "valueFinnish":"Varausta ei voi perua tässä. Ota yhteys ajanavarauksen ylläpitoon.",
            "valueSwedish":"Bokning kan inte avbrytas här. Kontakta serviceadministrationen.",
            "valueEnglish":"Reservation cancellation cannot be done via this user interface. Please contact service administration.",
		},
		thanksForFeedback: {
			"valueDefault":"Kiitos palautteestasi!",
			"valueFinnish":"Kiitos palautteestasi!",
			"valueSwedish":"Tack för din feedback!",
			"valueEnglish":"Thank you for your feedback!",
		},
		reservationDeleted: {
			"valueDefault":"Varaus on peruttu",
			"valueFinnish":"Varaus on peruttu",
			"valueSwedish":"Bokningen är återkallad",
			"valueEnglish":"Reservation has been deleted succesfully",
		},
		returnToFrontPage: {
			"valueDefault":"Palaa etusivulle",
			"valueFinnish":"Palaa etusivulle",
			"valueSwedish":"Gå tillbaka till första sidan",
			"valueEnglish":"Return to the front page",
		},
		sessionHasExpired: {
           "valueDefault":"Istunto on päättynyt. Palaa etusivulle",
           "valueFinnish":"Istunto on päättynyt. Palaa etusivulle",
           "valueSwedish":"Din session har gått ut. Gå tillbacka återvänd till framsidan",
           "valueEnglish":"Your session has expired. Please return to the front page",
       },
       reservationNotFound: {
           "valueDefault":"Varaustunnus ei ole voimassa. Varaus voi olla poistettu tai tunnus voi olla virheellinen.",
           "valueFinnish":"Varaustunnus ei ole voimassa. Varaus voi olla poistettu tai tunnus voi olla virheellinen.",
           "valueSwedish":"Bokningskoden är inte giltig. Bokningen kan ha tagits bort eller koden är felaktig.",
           "valueEnglish":"Reservation code is not valid. Reservation may have been removed or the code is incorrect.",
       },
	   noAvailableServiceProvidersFound: {
           "valueDefault":"Toimipisteitä ei löytynyt",
           "valueFinnish":"Toimipisteitä ei löytynyt",
           "valueSwedish":"Inga tillgängliga kontor hittades",
           "valueEnglish":"No available offices found",
       },
       noAvailableServicesFound: {
           "valueDefault":"Palveluja ei löytynyt",
           "valueFinnish":"Palveluja ei löytynyt",
           "valueSwedish":"Inga tillgängliga tjänster hittades",
           "valueEnglish":"No available services found",
       },
       serviceDescriptionDataNotFound: {
           "valueDefault":"Palvelukuvausta ei löytynyt",
           "valueFinnish":"Palvelukuvausta ei löytynyt",
           "valueSwedish":"Servicebeskrivning hittades inte",
           "valueEnglish":"Service description not found",
       },
       reloadPage: {
           "valueDefault":"Lataa sivu uudelleen",
           "valueFinnish":"Lataa sivu uudelleen",
           "valueSwedish":"ladda om sidan",
           "valueEnglish":"Reload page",
       },
       reservationSessionExpired: {
           "valueDefault":"Varausistunto on päättynyt. Valitse uusi aika.",
           "valueFinnish":"Varausistunto on päättynyt. Valitse uusi aika.",
           "valueSwedish":"Din bokningssession har löpt ut. Välj en ny tid.",
           "valueEnglish":"Your reservation session has expired. Select a new time.",
       },
       reservationValidationFailed: {
           "valueDefault":"Varauksen validointi epäonnistui. Valitse uusi aika.",
           "valueFinnish":"Varauksen validointi epäonnistui. Valitse uusi aika.",
           "valueSwedish":"Bokningsvalideringen misslyckades. Välj en ny tid.",
           "valueEnglish":"The reservation validation failed. Select a new time.",
       },
	   reservationCannotBeCreated: {
           "valueDefault":"Varausta ei voida luoda.",
           "valueFinnish":"Varausta ei voida luoda.",
           "valueSwedish":"Bokningen kan inte skapas.",
           "valueEnglish":"The reservation cannot be created.",
       },
       serviceNotFound: {
           "valueDefault":"Palvelua ei löydy. Valitse uusi palvelu.",
           "valueFinnish":"Palvelua ei löydy. Valitse uusi palvelu.",
           "valueSwedish":"Tjänsten inte hittad. Välj en ny tjänst.",
           "valueEnglish":"The service was not found. Please select a new service.",
       },
       tentativeReservationCannotBeMoved: {
           "valueDefault":"Alustava varaus ei ole enää saatavilla tai sitä ei voida siirtää.",
           "valueFinnish":"Alustava varaus ei ole enää saatavilla tai sitä ei voida siirtää.",
           "valueSwedish":"Tentativ reservation är inte längre tillgänglig eller kan inte flyttas.",
           "valueEnglish":"Tentative reservation is no longer available or cannot be moved.",
       },
       feedbackSentOnce: {
           "valueDefault":"Voit lähettää palautetta vain kerran.",
           "valueFinnish":"Voit lähettää palautetta vain kerran.",
           "valueSwedish":"Du kan bara skicka feedback en gång.",
           "valueEnglish":"You can send feedback only once.",
       },
       incorrectDob: {
           "valueDefault":"Virheellinen syntymäaika",
           "valueFinnish":"Virheellinen syntymäaika",
           "valueSwedish":"Ogiltigt födelsedatum",
           "valueEnglish":"Incorrect date of birth",
       },
       logoutSuccess: {
         "valueDefault":"Istunto on suljettu ja olet kirjautunut ulos.",
         "valueFinnish":"Istunto on suljettu ja olet kirjautunut ulos.",
         "valueSwedish":"Din session är nu stängd och du har loggat ut.",
         "valueEnglish":"Your session is now closed and you have logged out.",
       },
       sessionIdNotFound: {
	     "valueDefault":"Istunnon tunnistetta ei löytynyt.",
	     "valueFinnish":"Istunnon tunnistetta ei löytynyt.",
	     "valueSwedish":"Sessions-id kunde inte hittas.",
	     "valueEnglish":"Session id was not found.",
	   },
	}

}

export default Strings;