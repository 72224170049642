import React from 'react';
import {get_week_number, retrieveLanguageValue} from "../utils/helper";
import Strings from "../utils/strings";

const ShowTimeHeader = (props) => {

    if (props.time) {
        const dd = new Date(props.time);
        const mm = "m" + dd.getMonth();
        let month = Strings["calendar"].months[mm];
        if (props.view === 'dayGridMonth') {
            return (
                <>
                    {retrieveLanguageValue(props.localeLanguage, month)} {dd.getFullYear()}
                </>
            )
        }
        if (props.view === 'timeGridWeek') {
            let weekNum = get_week_number(dd)[1];
            return (
                <>
                    {retrieveLanguageValue(props.localeLanguage, Strings.newCalendar.week)}: {weekNum}
                </>
            )
        }
    }
    return (
        <></>
    )
}

export default ShowTimeHeader;