import React, {Component} from 'react';
import store from 'store';
import {AvField, AvForm} from 'availity-reactstrap-validation';
import {
    Navbar, Nav, NavItem, DropdownMenu,
    DropdownItem, DropdownToggle, UncontrolledDropdown,
    Collapse, NavLink, Modal,
    Button, FormGroup
} from 'reactstrap';
import url from '../utils/path';
import rmsUrl from '../utils/rmsurl';
import homepath from '../utils/homepath';

import {Redirect, withRouter} from 'react-router';

import Strings from '../utils/strings';
import {
    get_ui_variant,
    retrieveLanguageValue,
    retrieveLanguageValueWithReplacements
} from '../utils/helper';

//import { confirmAlert } from 'react-confirm-alert';

import 'react-confirm-alert/src/react-confirm-alert.css';
import {format} from "date-fns";
import "./TopNavigation.css";
import {isMobile} from "react-device-detect";
import {doGlobalLogout} from "../utils/components/GlobalLogoutHandler";
import WindowResizeHandler from "../utils/components/WindowResizeHandler";

class TopNavigation extends Component {

    constructor(props) {
        super(props);

//        console.log('>> TopNavigation, props', props);
//        console.log('>>> document.documentElement.lang', document.documentElement.lang);
        this.state = {
            dimensions: null,
            isOpen: false,
            langs: ["FI", "SV", "EN"],
            selected:  document.documentElement.lang.toUpperCase(),
            show: false,
            showLogoutError: false,
            showBackendEndError: this.props.state.showBackendEndError,
            reservationCode: null,
            sessionId: this.props.state.sessionId,
            serviceId: null,
            reservationLimit: null,
            loginError: null,
            collapsed: true,
            logoutError: {},
            goToReviewError: false,
            width: window.innerWidth,
            rmsVersion: '',
            rmsStatus: '',
            logoData: null,
            logoLoaded: null,
            uiVariant: get_ui_variant()
        };

        this.toggle = this.toggle.bind(this);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.select = this.select.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.goToReview = this.goToReview.bind(this);
        this.handleWindowChange = this.handleWindowChange.bind(this);

        this.loadLogoData();
    }

    loadLogoData() {
        fetch(url.concat('/images').concat(rmsUrl).concat('/logo'))
            .then((response) => response.json())
            .then((responseJson) => {
                this.setState({logoData: responseJson})

            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                this.setState({logoLoaded: true})
            });
    }


    handleReservationCode = (event) => {
        this.setState({
            reservationCode: event.target.value
        });

        if (this.state.loginError !== null) {
            this.setState({
                loginError: null
            });
        }
    }

    homeBtnClick = (e) => {
        store.clearAll();
        if (this.state.uiVariant === 'ISLAB') {
            console.log('Going ISLAB url from home btn!');
            window.location.replace('https://www.islab.fi/');
        } else {
            let logoutUrl = store.get('logoutUrl');
            console.log('Going other url from home btn: ', logoutUrl);
            if (logoutUrl !== null && logoutUrl !== undefined) {
                window.location.replace(logoutUrl);
            } else {
                window.location.assign(homepath);
            }
        }
    }

    goToReview(e) {
        e.preventDefault();
        //push user to review using history.push
        this.props.history.push('/review-service');
    }

    handleClose() {
        if (this.state.loginError !== null) {
            this.setState({
                loginError: null
            });
        }

        this.setState({show: false});
    }

    handleBackEndClose() {
        if (this.state.showBackendEndError !== null) {
            this.setState({
                showBackendEndError: false
            });
        }
    }

    handleShow(e) {
        e.preventDefault();
        this.setState({show: true});
    }

    loginLogout(e) {
        let state = this.props.globalGetNavigationState();

//        if (this.props.state.sessionId !== null) {
        if (state.loggedIn) {
            this.logout();
        } else {
            if (this.state.reservationCode !== null && this.state.reservationCode !== "" && this.state.reservationCode !== " ") {
                this.login();
            }
        }
    }

    clearAllData = () => {
// 		store.clearAll();  TODO ??
        this.props.history.push('/');
    };

    backEndErrorClose = () => {
        this.props.globalBackEndError(false);
    }

    logoutUrl = (logoutUrl) => {
        console.log('Doing logout with url: ', logoutUrl);
        store.clearAll();
        if (logoutUrl !== null && logoutUrl !== undefined) {
            window.location.replace(logoutUrl);
        } else {
            window.location.assign(homepath);
        }
    }

    dismissAlert = (e) => {
        e.preventDefault();
        this.setState({
            goToReviewError: false
        });
    };

    dismissSessionExpiredAlert = (e) => {
        e.preventDefault();
        this.clearAllData();
    }

    logout = () => {

        doGlobalLogout(this);

    }

    login = () => {
        fetch(url.concat('/reservation').concat(rmsUrl).concat('/login'), {
            method: 'post',
            headers: {'Content-type': 'application/json', 'Accept': 'application/json'},
            body: JSON.stringify({"reservationCode": this.state.reservationCode})
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            /*			if(response.status === 404) {
                            confirmAlert({
                                message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.reservationNotFound),
                                buttons: [{label: 'Ok'}],
                                closeOnEscape: true,
                                closeOnClickOutside: true,
                            });
                        }*/
            throw response.json();
        }).then(data => {
            console.log('>>>> DO login() --> data', data);
            this.props.setSessionId(data.sessionId);
            this.setState({
                reservationLimit: data.reservationLimit,
                sessionId: data.sessionId,
                serviceId: data.serviceId,
                show: false
            });
            let state = this.props.globalGetNavigationState();
            state.loggedIn = true;
            this.props.globalSetNavigationState(state);
            store.set('uniqueServiceId', data.serviceId);
        }).catch(error => {
            return error;
        }).then(err => {
//            console.log(err);
            this.setState({
                loginError: 'Reservation code not found'
            });
        });
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    toggleNavbar(e) {
        e.preventDefault();
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    select(event) {
        let lng = event.target.innerText;
        this.setState({
            selected: lng
        });
        this.props.globalLang(lng);
    }

    componentDidMount() {
        if (this.state.sessionId !== null) {
            this.setState({
                loggedIn: true
            });
        }

        fetch(url.concat('/rms-status').concat(rmsUrl), {
            method: 'get',
            headers: {'Content-type': 'application/json', 'Accept': 'application/json'}
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            throw response.json();
        }).then(data => {
            this.setState({
                rmsStatus: data.status,
                rmsVersion: data.version
            });
        }).catch(error => {
            return error;
        });
    }

    renderWindowResizeHandler(callBack) {
        return (
            <>
                <WindowResizeHandler callBack={callBack}/>
            </>
        )
    }

    handleWindowChange(dimensions) {
        this.setState({
            dimensions: dimensions
        })
    }

    render() {

        // kun tehty login ja reservation code syötetty tällä ohjataan näyttämään tiedot
        if (this.state.reservationCode !== null && this.state.sessionId !== null) {
            return (
                <Redirect to={{
                    pathname: "/confirm-information",
                    state: {
                        serviceId: this.state.serviceId,
                        sessionId: this.state.sessionId,
                        reservationCode: this.state.reservationCode,
                        data: null
                    }
                }}/>
            );
        }

        let isMobileReal = isMobile;
        if (!isMobile) {
            if (window.innerWidth < this.props.state.mobileBreakPoint) {
                isMobileReal = true;
            }
        }
        let btnToDisplay = (
            <></>
        );
        let mobileSignButtonText = (
            <></>
        );
        let mobileSingButtonCallback = (
            <></>
        );

        let navigationState = this.props.globalGetNavigationState();
        if (navigationState && navigationState.loggedIn === true) {
//            console.log('>> navigationState', navigationState);
//            console.log('>> navigationState.loggedIn = ', navigationState.loggedIn);
            if (isMobileReal) {
                mobileSignButtonText = retrieveLanguageValue(this.props.state.language, Strings.topNav.signOut);
                mobileSingButtonCallback = this.logout;
            } else {
                btnToDisplay =
                    <NavLink href="#" className="signInButton" onClick={this.logout}>
                        <p className="signInTextReviewService">{retrieveLanguageValue(this.props.state.language, Strings.topNav.signOut)}</p>
                    </NavLink>
            }
        } else {
            if (isMobileReal) {
                mobileSignButtonText = retrieveLanguageValue(this.props.state.language, Strings.topNav.reservationCode);
                mobileSingButtonCallback = this.handleShow;
            } else {
                btnToDisplay =
                    <NavLink href="#" className="signInButton" onClick={this.handleShow}>
                        <img src={require("../images/kalenteri-ikoni.svg")} alt=""/>
                        <p className="signInText">{retrieveLanguageValue(this.props.state.language, Strings.topNav.reservationCode)}</p>
                    </NavLink>
            }
        }

        let imageUrl = '';
        if (this.state.logoLoaded) {
            if (this.state.logoData) {
                imageUrl = 'data:' + this.state.logoData.type + ';base64,' + this.state.logoData.data;
            } else {
                imageUrl = require('../images/no_logo.png');
            }
        }

        let formattedDate = format(new Date(), "HH:mm dd.MM.yyyy");
        let replacements = {"%TIME_AND_DATE%": formattedDate};

        if (isMobileReal) {
            return (
                <div class="mobileTopDiv">
                    {this.renderWindowResizeHandler(this.handleWindowChange)}
                    <table width="100%" border={0} style={{
                        "whiteSpace": "no-wrap"
                    }}>
                        <tbody>
                        <tr>
                            <td align="left" height="40px">
                                <div className="versionStatus">v.{this.state.rmsVersion}</div>
                            </td>
                            {this.props.name === 'reviewService' ? "" : (
                                <td align="right" height="40px">
                                    <button className="btn btn-outline-secondary nav-button" onClick={mobileSingButtonCallback}
                                            style={{
                                                "height": "40px"
                                            }}>
                                        {mobileSignButtonText}
                                    </button>
                                    <button onClick={this.goToReview} className={"btn btn-outline-secondary nav-button review-button-" + get_ui_variant()} style={{
                                        "height": "40px"
                                    }}>
                                        {retrieveLanguageValue(this.props.state.language, Strings.topNav.goToReview)}
                                    </button>
                                </td>
                            )}
                            <td align="left" width="30px" height="40px" class="mobileDropdown">
                                <UncontrolledDropdown>
                                    <DropdownToggle nav caret className="langDropdown" id="UncontrolledTooltipExample">
                                        {this.state.selected}
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        {
                                            this.state.langs.map((lang) => {
                                                    if (this.state.selected !== lang) {
                                                        return <DropdownItem key={lang}
                                                                             onClick={(event) => this.select(event)}>{lang}</DropdownItem>
                                                    }
                                                    return "";
                                                }
                                            )
                                        }
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    {this.get_modals(replacements)}

                </div>
            );
        } else {
            let to = '#';
            if (this.state.uiVariant === 'ISLAB') {
                to = 'https://www.islab.fi/';
            } else if (this.state.uiVariant === 'DVV') {
                to = 'https://dvv.fi/';
            }
            return (
                <div className="topDiv">
                    {this.renderWindowResizeHandler(this.handleWindowChange)}
                    <Navbar className="navbar navbar-expand-md " expand="sm">
                        <a href={to} onClick={this.homeBtnClick}>
                            <img src={imageUrl} alt={"Logo"}/>
                        </a>
                        <div className="versionStatus">
                            <p>v.{this.state.rmsVersion}</p>
                        </div>
                        <Collapse isOpen={this.state.isOpen} navbar>
                            <Nav className="ml-auto" navbar>
                                <NavItem>
                                    {btnToDisplay}
                                </NavItem>
                                <UncontrolledDropdown>
                                    <DropdownToggle nav caret className="langDropdown" id="UncontrolledTooltipExample">
                                        {this.state.selected}
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        {
                                            this.state.langs.map((lang) => {
                                                    if (this.state.selected !== lang) {
                                                        return <DropdownItem key={lang}
                                                                             onClick={(event) => this.select(event)}>{lang}</DropdownItem>
                                                    }
                                                    return "";
                                                }
                                            )
                                        }
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                                {this.props.name === 'reviewService' ? "" : (
                                    <NavItem>
                                        <NavLink href="#" className={"signInButton review-button-" + get_ui_variant()} onClick={this.goToReview}>
                                            <p className="signInTextReviewService">{retrieveLanguageValue(this.props.state.language, Strings.topNav.goToReview)}</p>
                                        </NavLink>
                                    </NavItem>
                                )}
                            </Nav>
                        </Collapse>
                    </Navbar>

                    {this.get_modals(replacements)}

                </div>
            );
        }
    }

    get_modals(replacements) {
        return (
            <div>
                <Modal isOpen={this.state.show} toggle={this.handleClose} centered={true}>
                    <strong
                        className="modal-heading">{retrieveLanguageValue(this.props.state.language, Strings.topNav.popupTitle)}</strong>
                    <p className="modal-description">{retrieveLanguageValue(this.props.state.language, Strings.topNav.popupDescription)}</p>
                    <AvForm>
                        <FormGroup className="modal-input">
                            <AvField type="text" name="loginCode" key="loginCode"
                                     onChange={this.handleReservationCode}
                                     validate={{
                                         required: {
                                             value: true,
                                             errorMessage: retrieveLanguageValue(this.props.state.language, Strings.generic.cannotBeEmpty)
                                         }
                                     }}/>
                            <span style={{
                                display: 'inline',
                                color: 'red',
                                fontSize: '13px'
                            }}>{this.state.loginError !== null ? retrieveLanguageValue(this.props.state.language, Strings.topNav.loginError) : ''}</span>
                        </FormGroup>
                        <div className="bttns">
                            <Button color="primary" type="submit" onClick={this.login}>
                                {retrieveLanguageValue(this.props.state.language, Strings.topNav.login)}
                            </Button>
                            <Button outline color="secondary" onClick={this.handleClose}>
                                {retrieveLanguageValue(this.props.state.language, Strings.topNav.cancel)}
                            </Button>
                        </div>

                    </AvForm>
                </Modal>

                <Modal isOpen={this.state.showLogoutError} backdrop="static" keyboard={false} centered={true}>

                    <p style={{
                        padding: '10px',
                        fontSize: '18px',
                        textAlign: 'center'
                    }}>{retrieveLanguageValue(this.props.state.language, this.state.logoutError)}</p>

                    <Button outline color="secondary" onClick={this.clearAllData}>
                        {retrieveLanguageValue(this.props.state.language, Strings.topNav.signOut)}
                    </Button>
                </Modal>

                <Modal isOpen={this.props.state.showBackEndError} backdrop="static" keyboard={false}
                       centered={true}>

                    <p style={{
                        padding: '10px',
                        fontSize: '18px',
                        textAlign: 'center'
                    }}>{retrieveLanguageValueWithReplacements(this.props.state.language, Strings.topNav.cancel, replacements)}</p>

                    <Button outline color="secondary" onClick={this.clearAllData}>
                        {retrieveLanguageValue(this.props.state.language, Strings.topNav.cancel)}
                    </Button>
                </Modal>
            </div>
        )
    }
}

export default withRouter(TopNavigation);
