export const getInitialRequired = (json) => {
    if (json === null) {
        return null;
    }

    let initialRequired = {};
    json.properties.forEach((property) => {
        property.properties.forEach((subProperty) => {
            initialRequired[subProperty.propertyId] = subProperty.required;
        });
    });
    return initialRequired;
}

export const getInitialFormValues = (json) => {
    if (json === null) {
        return null;
    }
    let initialFormValues = {};
    json.values.forEach((value) => {
        initialFormValues[value.propertyId] = value.propertyValue;
    });
    return initialFormValues;
}

export const mapJsonData = (json, edit) => {

//    console.log('--> mapping data', json);


    if (json === null) {
        return null;
    }

    let count = 0;

    let mapped = {
        staticProperties: new Map(),
        properties: new Map(),
        valueMap: new Map()
    }

    json.values.forEach((value) => {
        mapped.valueMap.set(value.propertyId, value);
    });

    json.properties.forEach((property) => {

        let mappedProperty = {
            name: property.name,
            subProperties: new Map(),
        }

        let isStatic = false;
        property.properties.forEach((subProperty) => {
            if (subProperty.propertyType === 'static') {
                isStatic = true;
            }
            mappedProperty.subProperties.set(subProperty.propertyId, subProperty);
        });
        if (isStatic === true) {
            mapped.staticProperties.set(count, mappedProperty);
        } else {
            mapped.properties.set(count, mappedProperty);
        }
        count++;
    });

    return mapped;
}


