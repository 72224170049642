import React from "react";
import {get_ui_variant} from "./utils/helper";

export  const isOldBrowser = () => {
    /*!
    * copy from is.js : https://is.js.org/
    * Author: Aras Atasaygin
    */
    const navigator = window && window.navigator;
    const userAgent = (navigator && navigator.userAgent || '').toLowerCase();
    const vendor = (navigator && navigator.vendor || '').toLowerCase();
    const comparator = {
        '<': function(a, b) { return a < b; },
        '<=': function(a, b) { return a <= b; },
        '>': function(a, b) { return a > b; },
        '>=': function(a, b) { return a >= b; }
    };
    function compareVersion(version, range) {
        var string = (range + '');
        var n = +(string.match(/\d+/) || NaN);
        var op = string.match(/^[<>]=?|/)[0];
        return comparator[op] ? comparator[op](version, n) : (version == n || n !== n);
    }
    function opera(range) {
        var match = userAgent.match(/(?:^opera.+?version|opr)\/(\d+)/);
        return match !== null && compareVersion(match[1], range);
    }
    function chrome(range) {
        var match = /google inc/.test(vendor) ? userAgent.match(/(?:chrome|crios)\/(\d+)/) : null;
        return match !== null && !opera() && compareVersion(match[1], range);
    }
    function edge(range) {
        var match = userAgent.match(/edge\/(\d+)/);
        return match !== null && compareVersion(match[1], range);
    }
    function firefox(range) {
        var match = userAgent.match(/(?:firefox|fxios)\/(\d+)/);
        return match !== null && compareVersion(match[1], range);
    }
    function ie(range) {
        var match = userAgent.match(/(?:msie |trident.+?; rv:)(\d+)/);
        return match !== null && compareVersion(match[1], range);
    }
    function safari(range) {
        var match = userAgent.match(/version\/(\d+).+?safari/);
        return match !== null && compareVersion(match[1], range);
    }

    if (ie('<1000')) { // no ANY IE allowed here!
        return true;
    } else {
        return false;
    }

}

export const OldBrowser = (props) => {

    const renderBrowserErrorISLAB = () => {

        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="error-template col-center">

                            <h1>Hups!</h1>
                            <h2>Pahoittelemme, emme tue Internet Explorer -selainta.</h2>
                            <h3>
                                Käyttäkää Edge-, Chrome- tai Firefox-selainta. Voitte varata ajan, muuttaa tai perua varatun ajan myös puhelimitse ajanvarausnumerosta 044-7178888 ma-pe 12-14 (ei arkipyhinä).
                            </h3>
                        </div>
                        <br/>
                        <div className="error-template col-center">
                            <h1>Hoppsan!</h1>
                            <h2>Tyvärr, vi stöder inte Internet Explorer</h2>
                            <h3>
                                Använd Edge, Chrome eller Firefox. Du kan också boka, ändra eller avboka den bokade tiden via telefon från mötesnummer 044-7178888 må-fre 12-14 (inte på helgdagar).
                            </h3>
                        </div>
                        <br/>
                        <div className="error-template col-center">
                            <h1>Oops!</h1>
                            <h2>Sorry, we do not support Internet Explorer</h2>
                            <h3>
                                Use Edge, Chrome or Firefox. You can also book, change or cancel the booked time by phone from the appointment number 044-7178888 Mon-Fri 12-14 (not on public holidays).
                            </h3>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    const renderBrowserError = () => {

        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="error-template col-center">
                            <h1>Hups!</h1>
                            <h2>Pahoittelemme, emme tue Internet Explorer -selainta.</h2>
                            <div className="error-details">
                                Käytä jotain toista selainta, kiitos!
                            </div>
                        </div>
                        <div className="error-template col-center">
                            <h1>Hoppsan!</h1>
                            <h2>Tyvärr, vi stöder inte Internet Explorer</h2>
                            <div className="error-details">
                                Vänligen använd en annan webbläsare. Tack!
                            </div>
                        </div>

                        <div className="error-template col-center">
                            <h1>Oops!</h1>
                            <h2>Sorry, we do not support Internet Explorer</h2>
                            <div className="error-details">
                                Kindly use another browser. Thank you!
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    if (get_ui_variant() === 'ISLAB') {
        return renderBrowserErrorISLAB();
    } else {
        return renderBrowserError();
    }

}
