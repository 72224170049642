import React, {Component} from 'react';
import store from 'store';

import {Redirect} from 'react-router-dom';
import TopNavigation from '../TopNavigation/TopNavigation';
import CookiePolicy from '../CookiePolicy/CookiePolicy';
import MyMenu from '../Menu/MyMenu';
import url from '../utils/path';
import {registerLocale} from 'react-datepicker';
import sv from 'date-fns/locale/sv';
import fi from 'date-fns/locale/fi';
import en from 'date-fns/locale/en-US';

import 'react-confirm-alert/src/react-confirm-alert.css';

import './ChangeBookingCalendar.css';
import {CHANGE_RESERVATION_TIME, GlobalSetMenuState, GlobalSetStateEntered} from "../utils/components/GlobalMenuState";
import NewCalendar from "../NewCalendar/NewCalendar";
import {retrieveLanguageValue} from "../utils/helper";
import Strings from "../utils/strings";

registerLocale('sv', sv);
registerLocale('fi', fi);
registerLocale('en', en);

class ChangeBookingCalendar extends Component {
    constructor(props) {
        super(props);

        try {
            this.state = {
                dates: [],
                today: new Date(),
                currentWeek: new Date(),
                toggleValue: 1,
                reservationCode: null,
                updated: null,
                toConfirmInfo: false,
                toChangeDate: false,
                nextFreeTime: null,
                changeCalendarLoading: true,
                backgroundImage: null,
                gotoHome: false,

                currentMonth: new Date(this.props.location.state.data.date),
                selectedDate: new Date(this.props.location.state.data.date),
                serviceId: this.props.location.state.serviceId,
                sessionId: this.props.location.state.sessionId,
                data: this.props.location.state.data,
                goHome: false
            };

        } catch (e) {
            this.state = {
                goHome: true
            }
        }

        this.timeSelected = this.timeSelected.bind(this);

    }

    componentDidMount() {

        if (this.state.goHome === true) {
            return;
        }

        GlobalSetMenuState(CHANGE_RESERVATION_TIME, {accessed: true})
        GlobalSetStateEntered(CHANGE_RESERVATION_TIME);

    }

    receiveNewTimeAndMoveOld = async (eventClick) => {
        console.log('>> eventClick', eventClick);
        console.log('>> this', this);

        const tentativeReservationCode = await fetch(url.concat('/reservation/').concat(this.state.serviceId).concat('/reserve').concat('?sessionId=').concat(this.state.sessionId), {
            method: 'post',
            headers: {'Content-type': 'application/json', 'Accept': 'application/json'},
            body: JSON.stringify({
                "slot": {
                    "slotId": eventClick.slotId,
                    "startTime": eventClick.startTime,
                    "date": eventClick.date
                },
            }),
        }).then(res => res.json());

        console.log('reserved new tentativeReservation', tentativeReservationCode);

        const data = await fetch(url.concat('/reservation/').concat(this.state.data.reservationCode).concat('/move').concat('?sessionId=').concat(this.state.sessionId), {
            method: 'post',
            headers: {'Content-type': 'application/json', 'Accept': 'application/json'},
            body: JSON.stringify({"tentativeReservationCode": tentativeReservationCode.reservationCode})
        }).then(res => res.json());

        console.log('>> data', data);
        return data;

    }


    timeSelected(eventClick) {
        console.log('this ->', this);
        console.log('eventClick -> ', eventClick);

        this.receiveNewTimeAndMoveOld(eventClick)
            .then(data => {
                    console.log('>> data', data);
                    this.setState({
                        data: data,
                        reservationCode: data.reservationCode,
                        toConfirmInfo: true,
                        updated: true
                    });
                    store.set('updated', true);
                }
            ).catch(exeception => {
            console.log('Error from move, exeception', exeception);
        })
    }

    render() {

        if (this.state.toConfirmInfo === true) {
            GlobalSetMenuState(CHANGE_RESERVATION_TIME, {completed: true})
            return (
                <Redirect to={{
                    pathname: '/confirm-information',
                    state: {
                        reservationCode: this.state.reservationCode,
                        serviceId: this.state.serviceId,
                        sessionId: this.state.sessionId,
                        data: this.state.data
                    }
                }}/>
            );
        }

        const calendarData = {
            dayMaxEventRows: 5,
            height: 'auto',
            firstDay: 1,
            initialView: null,
            language: this.props.state.language,
            service: retrieveLanguageValue(this.props.state.language, this.state.data.service.name),
            serviceId: this.state.serviceId,
            sessionId: this.state.sessionId,
            servicePlace: retrieveLanguageValue(this.props.state.language, this.state.data.serviceProvider.name),
            serviceTitle: retrieveLanguageValue(this.props.state.language, Strings.calendar.selectAvailableTime),
            showNonCurrentDates: false,
            slotDuration: '00:10:00',
            weekends: true,
            weekNumbers: false
        }

        let service = this.props.state.services;
        return (
            <div>
                <TopNavigation globalGetNavigationState={this.props.globalGetNavigationState}
                               globalSetNavigationState={this.props.globalSetNavigationState}
                               globalLang={this.props.globalLang} setSessionId={this.props.setSessionId}
                               state={this.props.state}/>
                <MyMenu name="changeReservationDate" state={this.props.state}/>
                <div>
                    <NewCalendar changeSelectedTime={this.timeSelected} calendarData={calendarData}/>
                    <CookiePolicy state={this.props.state} cookiePolicyExpiration={service != null ? service.cookiePolicyExpiration : null} cookiePolicy={service != null ? service.cookiePolicy : null} cookiePolicyTitle={service != null ? service.cookiePolicyTitle : null}/>
                </div>

            </div>
        );

    }

}

export default ChangeBookingCalendar;