import React from 'react';
import NewCalendar from "./NewCalendar";
import {retrieveLanguageValue} from "../utils/helper";
import Strings from "../utils/strings";

const NewChooseATime = (props) => {

//    console.log('>> NewChooseATime props', props);

//    const [selectedTime, setSelectedTime] = useState('ei valittu');

    const changeSelectedTime = (time) => {
        console.log('>> changeSelectedTime -> ', time);
        //setSelectedTime(time);
    }
//?sessionId=
    const calendarData = {
        dayMaxEventRows: 5,
        firstDay: 1,
        height: 'auto',
        initialView: null,
        language: props.language,
        serviceId: 'XBS4OJ',
        sessionId: '2BC0F55',
        service: 'Veri- ja virtsakokeetxx',
        servicePlace: 'Heinävesi, terveyskeskuksen laboratorioxx',
        serviceTitle: retrieveLanguageValue(props.language, Strings.calendar.selectAvailableTime),
        showNonCurrentDates: false,
        slotDuration: '00:15:00',
        weekends: true,
        weekNumbers: false
    }

//    console.log('>> NewChooseATime return');

    return (
        <>
            <p>New calendar test</p>
            <br/>
            <a href="/new-calendar/bench">Bench</a>
            <br/>
            <br/>
            <NewCalendar changeSelectedTime={changeSelectedTime} calendarData={calendarData}/>
        </>
    )

}


export default NewChooseATime