import React, {useEffect, useState} from 'react';
import {get_ui_variant} from "../utils/helper";
import NewCalendarThemedCss from "./NewCalendarThemedCss";
import url from "../utils/path";

const NewCalendar = (props) => {

    const [initialDate, setInitialDate] = useState(null); // null = today
    const [date, setDate] = useState(new Date());
    const [eventData, setEventData] = useState(null);

    const IslabTheme = React.lazy(() => import('./islabTheme'));
    const DVVTheme = React.lazy(() => import('./dvvTheme'));

    const ThemeSelector = ({children}) => {
        return (
            <>
                <React.Suspense fallback={<></>}>
                    {(get_ui_variant() === 'ISLAB') && <IslabTheme/>}
                    {(get_ui_variant() === 'DVV') && <DVVTheme/>}
                </React.Suspense>
                {children}
            </>
        )
    }

    const fetchEvents = async () => {

        const serviceId = props.calendarData.serviceId;
        const sessionId = props.calendarData.sessionId;

        let now = new Date();
        let month = now.getMonth() + 1;
        let pad = month < 10 ? "0" : "";

        let date = now.getDate();
        if (date > 1) {
            date = date - 1;
        }
        let padDate = date < 10 ? "0" : "";

        const fetchStart = now.getFullYear() + "-" + pad + month + "-" + padDate + date;  // yyyy-mm-dd
        const fetchEnd = null;  // Use BrowsingLimitForCustomerDate in backend to calculate fetchEnd

        console.log('fetching events: ' + fetchStart + " - BrowsingLimitForCustomerDate");

        const eventData = await fetch(url.concat('/available-events/').concat(serviceId).concat('?sessionId=').concat(sessionId), {
            method: 'post',
            headers: {'Content-type': 'application/json', 'Accept': 'application/json'},
            body: JSON.stringify({"startDate": fetchStart, "endDate": fetchEnd})
        }).then(response => {
                return response.json();
        });

        return eventData;
    }


    useEffect(() => {
        fetchEvents().then(
            data => {
                console.log('>> got event data', data);
                setEventData(data);
            }
        )
    }, []);


    if (get_ui_variant() === 'ISLAB') {
//        props.calendarData.hiddenDays = [6, 0];
        props.calendarData.hiddenDays = [];
        props.calendarData.spinnerColor = '#1863e6';
    }
    if (get_ui_variant() === 'DVV') {
        props.calendarData.hiddenDays = [0];
        props.calendarData.spinnerColor = '#003479';
    }

    const handleNextFreeTime = (nextTime, currentView) => {
        setInitialDate(nextTime);
        setDate(new Date()); // force component update
        props.calendarData.initialView = currentView;
    }

    props.calendarData.handleNextFreeTime = handleNextFreeTime; // call back from parent to set new initial date
    props.calendarData.initialDate = initialDate;
    props.calendarData.date = date;  // trigger state change -> render()
    props.calendarData.calendarEvents = null;
    props.calendarData.eventsEarliestTime = null;
    props.calendarData.eventsLatestTime = null;
    props.calendarData.nextFreeTime = null;

    return (
        <>
            <ThemeSelector>
                <NewCalendarThemedCss changeSelectedTime={props.changeSelectedTime} calendarData={props.calendarData} eventData={eventData}/>
            </ThemeSelector>
        </>
    )
}

export default NewCalendar;