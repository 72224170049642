import React, {Component} from 'react';
import store from 'store';
import {format} from 'date-fns';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import ChooseAService from './ChooseAService/ChooseAService';
import ChooseAnOffice from './ChooseAnOffice/ChooseAnOffice';
import ChooseATime from './ChooseATime/ChooseATime';
import EnterInformation from './EnterInformation/EnterInformation';
import EditInformation from './EnterInformation/EditInformation';
import CheckInformation from './CheckInformation/CheckInformation';
import ConfirmInformation from './CheckInformation/ConfirmInformation';
import ReviewService from './ReviewService/ReviewService';
import ChangeBookingCalendar from './ChangeBookingDate/ChangeBookingCalendar';
import url from './utils/path';
import rmsUrl from './utils/rmsurl';
import homepath from './utils/homepath';
import {GlobalInitMenuStateObject} from "./utils/components/GlobalMenuState";
import Conf from './utils/conf';
import NewChooseATime from "./NewCalendar/NewChooseATime";
import Bench from "./NewCalendar/Bench";
import NewEnterInformation from "./EnterInformation/NewEnterInformation";
import {isOldBrowser, OldBrowser} from "./OldBrowser";

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            language: 'FI',
            selectedImage: null,
            mobileBreakPoint: 1200,
            sessionId: null,
            firstRun: true,
            showBackEndError: false,
            services: null
        };
        GlobalInitMenuStateObject();

    }

    globalLang = (languageUserSelected) => {
        let langLower = languageUserSelected.toLowerCase();
        if (langLower !== 'fi' && langLower !== 'sv' && langLower !=='en') {
            langLower = 'fi';
        }
        document.documentElement.lang = langLower;
        this.setState({
            language: langLower.toUpperCase()
        });
    };

    globalSetNavigationState = (navigationState) => {
        store.set('navigationState', navigationState);
    }

    globalGetNavigationState = () => {
        let navigationState = store.get('navigationState');
        if (!navigationState) {
            navigationState = {};
            navigationState.firstRun = true;
            navigationState.loggedIn = false;
        } else {
            navigationState.firstRun = false;
        }
        return navigationState;
    }

    globalBackEndError = (error) => {
        this.setState({
            showBackEndError: error
        });
    };

    selectedimage = (selectedImage) => {
        this.setState({
            selectedImage: selectedImage
        });
    };

    setSessionId = (sessionId) => {
        this.setState({
            sessionId: sessionId
        });

        store.set('sessionId', sessionId);
    };

    cleanData = () => {
        store.clearAll();
        GlobalInitMenuStateObject();
//        let menuArray = this.initMenuItemsObject();
//        store.set(MENU_ITEM_STORAGE_KEY, JSON.stringify(menuArray));
        window.location.assign(homepath);
        // window.location.reload();
    }


    componentDidMount() {
        window.addEventListener('beforeunload', this.componentCleanup);

        if (store.get('sessionId') !== null && store.get('sessionId') !== undefined) {
            let sessionId = store.get('sessionId');
            this.setState({sessionId: sessionId});

            if (this.state.firstRun) {
                this.setState({
                    firstRun: false
                });
                fetch(url.concat('/session').concat('?sessionId=').concat(sessionId), {
                    method: 'get',
                    headers: {'Content-type': 'application/json', 'Accept': 'application/json'}
                }).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    }
                    if (response.status === 404) {
                        // Session not found. Clear existing data in local storage
                        this.cleanData();
                    }

                    if (response.status === 403) {
                        // Session has expired
                        this.cleanData();
                    }
                    throw response.json();
                }).then(data => {
                    let expiresIn = data.expiresIn;
                    let currentTime = format(new Date(), "yyyy-MM-dd HH:mm");
                    if (currentTime > expiresIn) {
                        this.cleanData();
                    }
                    this.componentDidMount();
                }).catch(error => {
                    return error;
                });
            }
        }

        this.setState({loading: true});
        fetch(url.concat('/available-services').concat(rmsUrl), {
            method: 'post',
            headers: {'Content-Type':'application/json; charset=utf-8'}
        }).then(response => {
            if(!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(services => {
            this.setState({
                loading: false,
                services: services
            });
        }).catch(error => {
            this.setState({
                loading: false
            });
        });
    }

    componentCleanup = () => {
        let navigationState = this.globalGetNavigationState();
        if (navigationState.loggedIn) {
//            doGlobalLogout(this);
            store.clearAll();
        }
    }

    componentWillUnmount() {
        this.componentCleanup();
        window.removeEventListener('beforeunload', this.componentCleanup);
    }


    render() {
        if (isOldBrowser()) {
            return (
                <div>
                    <OldBrowser/>
                </div>
            );
        } else {
            let services = this.state.services;
            return (
                <Router basename={homepath}>
                    <div>
                        <Switch>
                            <Route exact path="/new-calendar/bench" render={(props) =>
                                <Bench language={this.state.language}/>}/>
                            <Route exact path="/new-calendar" render={(props) =>
                                <NewChooseATime language={this.state.language}/>}/>
                            <Route exact path="/new-enter-information" render={(props) =>
                                <NewEnterInformation language={this.state.language}/>}/>

                            <Route exact path="/" render={(props) =>
                                <ChooseAService {...props}
                                                globalGetNavigationState={this.globalGetNavigationState}
                                                globalSetNavigationState={this.globalSetNavigationState}
                                                setServiceCategory={this.setServiceCategory}
                                                globalBackEndError={this.globalBackEndError}
                                                globalLang={this.globalLang}
                                                setSessionId={this.setSessionId}
                                                selectedimage={this.selectedimage}
                                                state={this.state} />}/>
                            <Route path="?reservationCode=" render={(props) =>
                                <ChooseAService {...props}
                                                globalGetNavigationState={this.globalGetNavigationState}
                                                globalSetNavigationState={this.globalSetNavigationState}
                                                setServiceCategory={this.setServiceCategory}
                                                globalBackEndError={this.globalBackEndError}
                                                globalLang={this.globalLang}
                                                setSessionId={this.setSessionId}
                                                selectedimage={this.selectedimage}
                                                state={this.state}/>}/>
                            <Route path="/choose-an-office" render={(props) =>
                                <ChooseAnOffice {...props}
                                                globalGetNavigationState={this.globalGetNavigationState}
                                                globalSetNavigationState={this.globalSetNavigationState}
                                                globalBackEndError={this.globalBackEndError}
                                                globalLang={this.globalLang}
                                                setSessionId={this.setSessionId}
                                                state={this.state}/>}/>
                            <Route path="/choose-a-time" render={(props) =>
                                <ChooseATime {...props}
                                             globalGetNavigationState={this.globalGetNavigationState}
                                             globalSetNavigationState={this.globalSetNavigationState}
                                             globalBackEndError={this.globalBackEndError}
                                             globalLang={this.globalLang}
                                             setSessionId={this.setSessionId}
                                             state={this.state}/>}/>
                            <Route path="/enter-information" render={(props) =>
                                <EnterInformation {...props}
                                                  globalGetNavigationState={this.globalGetNavigationState}
                                                  globalSetNavigationState={this.globalSetNavigationState}
                                                  globalBackEndError={this.globalBackEndError}
                                                  globalLang={this.globalLang}
                                                  setSessionId={this.setSessionId}
                                                  state={this.state}/>}/>
                            <Route path="/edit-information" render={(props) =>
                                <EditInformation  {...props}
                                                  globalGetNavigationState={this.globalGetNavigationState}
                                                  globalSetNavigationState={this.globalSetNavigationState}
                                                  globalBackEndError={this.globalBackEndError}
                                                  globalLang={this.globalLang}
                                                  setSessionId={this.setSessionId}
                                                  state={this.state}/>}/>
                            <Route path="/check-information" render={(props) =>
                                <CheckInformation {...props}
                                                  globalGetNavigationState={this.globalGetNavigationState}
                                                  globalSetNavigationState={this.globalSetNavigationState}
                                                  globalBackEndError={this.globalBackEndError}
                                                  globalLang={this.globalLang}
                                                  setSessionId={this.setSessionId}
                                                  state={this.state}/>}/>
                            <Route path="/confirm-information" render={(props) =>
                                <ConfirmInformation  {...props}
                                                     globalGetNavigationState={this.globalGetNavigationState}
                                                     globalSetNavigationState={this.globalSetNavigationState}
                                                     globalBackEndError={this.globalBackEndError}
                                                     globalLang={this.globalLang}
                                                     setSessionId={this.setSessionId}
                                                     state={this.state}/>}/>
                            <Route path="/review-service" render={(props) =>
                                <ReviewService  {...props}
                                                globalGetNavigationState={this.globalGetNavigationState}
                                                globalSetNavigationState={this.globalSetNavigationState}
                                                globalBackEndError={this.globalBackEndError}
                                                globalLang={this.globalLang}
                                                setSessionId={this.setSessionId}
                                                state={this.state}/>}/>
                            <Route path="/change-reservation-time" render={(props) =>
                                <ChangeBookingCalendar {...props}
                                                       globalGetNavigationState={this.globalGetNavigationState}
                                                       globalSetNavigationState={this.globalSetNavigationState}
                                                       globalBackEndError={this.globalBackEndError}
                                                       globalLang={this.globalLang}
                                                       setSessionId={this.setSessionId}
                                                       state={this.state}/>}/>
                        </Switch>
                    </div>
                </Router>
            );
        }
    }

}

export default App;
