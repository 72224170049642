import {AvField, AvRadio, AvRadioGroup} from "availity-reactstrap-validation";
import React from "react";
import {retrieveLanguageValue} from "../utils/helper";
import Tooltip from "react-tooltip-lite";
import Strings from "../utils/strings";

import "./RMSFormField.css";
import {format} from "date-fns";

const getPropertyValidate = (props, required) => {
    let validateProp;
    if (props.valueSub.propertyType === 'text') {

        if (props.valueSub.propertyId === 'phoneNumber') {
            validateProp = {
                required: {
                    value: required,
                    errorMessage: retrieveLanguageValue(props.language, Strings.generic.cannotBeEmpty)
                },
                pattern: {
                    value: '^(\\+|0)[0-9]{3,15}$',
                    errorMessage: retrieveLanguageValue(props.language, Strings.generic.phoneNumberFormat)
                }
            };
        } else {
            validateProp = {
                required: {
                    value: required,
                    errorMessage: retrieveLanguageValue(props.language, Strings.generic.cannotBeEmpty)
                }
            };
        }
    } else if (props.valueSub.propertyType === 'date') {
        validateProp = {
            date: {
                format: 'D.M.YYYY',
                errorMessage: retrieveLanguageValue(props.language, Strings.generic.dateFormat)
            },
            dateRange:{
                format: 'D.M.YYYY',
                start: {value: '1.1.1900'},
                end: {value: format(new Date(), "d.M.yyyy")},
                errorMessage: retrieveLanguageValue(props.language, Strings.errorMessages.incorrectDob)
            },
            required: {
                value: required,
                errorMessage: retrieveLanguageValue(props.language, Strings.generic.cannotBeEmpty)
            }
        };

    }

    return validateProp;
}

const GetPlaceHolder = (props) => {
    let placeholder = '';
    if (props.valueSub.description) {
        placeholder = retrieveLanguageValue(props.language, props.valueSub.description);
    } else {
        placeholder = retrieveLanguageValue(props.language, props.valueSub.name);
    }
    return placeholder;
}


const DateField = (props, required) => {
    return (
        <AvField
            key={props.valueSub.propertyId}
            type="text"
            className="date-field"
            maxLength={props.valueSub.validation !== null ? props.valueSub.validation.maxLength : ''}
            name={props.valueSub.propertyId}
            id={props.valueSub.propertyId}
            value={props.formValues[props.valueSub.propertyId]}
            validate={getPropertyValidate(props, required)}
            onChange={props.onChangeCallback}
        >
        </AvField>
    );
}


const TextField = (props, required) => {

    const onBlur = (e) => {
        if (e.target) {
            if (e.target.name === 'phoneNumber') {
                const re = new RegExp('^([+0])[0-9]{3,15}$')
                if (re.test(e.target.value)) {
                    props.onBlurValidCallback(e);
                }
            }
        }
    }


    if (props.valueSub.propertyId === 'email') {
        return (
            <AvField
                key={props.valueSub.propertyId}
                id={props.valueSub.propertyId}
                type='email'
                errorMessage={retrieveLanguageValue(props.language, Strings.generic.emailExample)}
                name={props.valueSub.propertyId}
                maxLength={props.valueSub.validation !== null ? props.valueSub.validation.maxLength : ''}
                placeholder={GetPlaceHolder(props)}
                value={props.formValues[props.valueSub.propertyId]}
                validate={getPropertyValidate(props, required)}
                onChange={props.onChangeCallback}
            >
            </AvField>
        )

    } else {
        return (
            <AvField
                key={props.valueSub.propertyId}
                id={props.valueSub.propertyId}
                type='text'
                name={props.valueSub.propertyId}
                maxLength={props.valueSub.validation !== null ? props.valueSub.validation.maxLength : ''}
                placeholder={GetPlaceHolder(props)}
                value={props.formValues[props.valueSub.propertyId]}
                validate={getPropertyValidate(props, required)}
                onChange={props.onChangeCallback}
                onBlur={onBlur}
            >
            </AvField>
        )

    }
}


const TextArea = (props, required) => {
    return (
        <AvField
            key={props.valueSub.propertyId}
            type="textarea"
            className="text-area"
            maxLength={props.valueSub.validation !== null ? props.valueSub.validation.maxLength : ''}
            name={props.valueSub.propertyId}
            id={props.valueSub.propertyId}
            value={props.formValues[props.valueSub.propertyId]}
            validate={getPropertyValidate(props, required)}
            onChange={props.onChangeCallback}

        >
        </AvField>
    );
}

const RadioGroup = (props, required) => {
    return (
        <>
            <AvRadioGroup key={props.valueSub.propertyId}
                          name={props.valueSub.propertyId}
                          value={props.formValues[props.valueSub.propertyId]}
                          validate={getPropertyValidate(props, required)}
                          onChange={props.onChangeCallback}
            >

                {props.valueSub.options.map(option => {
                    return <AvRadio key={option.value}
                                    label={retrieveLanguageValue(props.language, option.name)}
                                    value={option.value}
                    />
                })}
            </AvRadioGroup>

        </>
    )
}

const PullDown = (props, required) => {
    return (
        <>
            <div className={"rms-form-field-pull-down-wrapper"}>
                <select className={"rms-form-field-pull-down"}
                        key={props.valueSub.propertyId}
                        name={props.valueSub.propertyId}
                        value={props.formValues[props.valueSub.propertyId]}
                        onChange={props.onChangeCallback}>

                    {
                        props.valueSub.options.map(option => {
                            return (
                                <option label={retrieveLanguageValue(props.language, option.name)}
                                        value={option.value}>{retrieveLanguageValue(props.language, option.name)}
                                </option>
                            )
                        })
                    }

                </select>
            </div>
        </>
    )
}


// ------------------

const FIELDS = new Map();
FIELDS.set('date', DateField);
FIELDS.set('pulldown', PullDown);
FIELDS.set('radio', RadioGroup);
FIELDS.set('text', TextField);
FIELDS.set('textarea', TextArea);

const RMSFormField = (props) => {

    let field;
    const fieldFunction = FIELDS.get(props.valueSub.propertyType);

    const required = props.required[props.valueSub.propertyId];

    let requiredMark = '';
    if (required === true) {
        requiredMark = ' *';
    }

    if (fieldFunction) {
        field = fieldFunction(props, required);
    } else {
        console.log('>> not implemented: props.valueSub', props.valueSub);
        field = (
            <>
                {props.valueSub.propertyType} - not implemented
            </>
        );
    }


    return (
        <>
            <label className="label-style" htmlFor={props.valueSub.propertyId}>
                {retrieveLanguageValue(props.language, props.valueSub.name)}{requiredMark}
            </label>
            {props.valueSub.tooltipText && props.valueSub.tooltipText.length > 0 &&
                <Tooltip tagName="span"
                         content={props.valueSub.tooltipText}
                         useDefaultStyles={true}
                         target={props.valueSub.propertyId}
                >
                    ⒤
                </Tooltip>
            }
            {field}
        </>
    )
}

export default RMSFormField;
