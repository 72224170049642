import React from 'react'

function debounce(fn, ms) {

    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

const WindowResizeHandler = (props) => {

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    React.useEffect(() => {

        const debouncedHandleResize = debounce(function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
            props.callBack(dimensions);
        }, 20)

        window.addEventListener('resize', debouncedHandleResize)

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)
        }

    })
//<div>Rendered at {dimensions.width} x {dimensions.height}</div>
    return (
        <></>
    )
}

export default WindowResizeHandler;