import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {FormGroup, Label, Input} from 'reactstrap';
import store from 'store';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import MyMenu from '../Menu/MyMenu';
import TopNavigation from '../TopNavigation/TopNavigation';
import CookiePolicy from '../CookiePolicy/CookiePolicy';
import Strings from '../utils/strings';
import {retrieveLanguageValue} from '../utils/helper';
import url from '../utils/path';
import rmsUrl from '../utils/rmsurl';
import homepath from '../utils/homepath';
import { confirmAlert } from 'react-confirm-alert';

import 'react-confirm-alert/src/react-confirm-alert.css';

import './ReviewService.css';
import {isMobile} from "react-device-detect";
import Conf from '../utils/conf';
import $ from "jquery";
import WindowResizeHandler from "../utils/components/WindowResizeHandler";

class ReviewService extends Component {
	constructor(props) {

		super(props);

		this.state = {
			score: -1,
			serviceProviderId: null,
			serviceProviderName: null,
			recipient: '',
			service: null,
			serviceId: null,
			message: '',
			title: '',
			email: '',
			serviceProviders: [],
			useScore: Conf.ReviewService.useScore,
			useServiceProvider: Conf.ReviewService.useServiceProvider,
			useServiceRecipient: Conf.ReviewService.useServiceRecipient
		};

		this.handleIconClick = this.handleIconClick.bind(this);
		this.chooseServiceProvider = this.chooseServiceProvider.bind(this);
		this.chooseRecipient = this.chooseRecipient.bind(this);
		this.handleFeedback = this.handleFeedback.bind(this);
		this.handleFeedbackTitle = this.handleFeedbackTitle.bind(this);
		this.handleFeedbackEmail = this.handleFeedbackEmail.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
        this.goBackFromReview = this.goBackFromReview.bind(this);
        this.handleWindowChange = this.handleWindowChange.bind(this);
	}

	backToFrontPage = (e) => {
		let logoutUrl = store.get('logoutUrl');
		store.clearAll();
		store.set('logoutUrl', logoutUrl);
		window.location.assign(homepath);
	}

	handleIconClick(e) {
		e.preventDefault();
		let icon = e.currentTarget.getAttribute('value');
		if(this.state.score === -1) {
			this.setState({
				score: icon
			});
			fetch(url.concat('/feedback').concat(rmsUrl), {
				method: 'post',
				headers: {'Content-type':'application/json', 'Accept': 'application/json'},
				body: JSON.stringify(
					{"feedback":
							{
								score: icon,
								title: null,
								service: {
									id: this.state.serviceId,
									name: this.state.service,
								},
								recipient: null,
								email: null,
								message: null,
								serviceProvider: {
									id: this.state.serviceProviderId,
									name: this.state.serviceProviderName
								},
							},
						"timestamp": new Date().toJSON()
					}
				)
			}).then(response => {
				if(response.status === 200) {
					return response.json();
				}
				if(response.status === 404) {
					confirmAlert({
						message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.serviceNotFound),
						buttons: [{label: 'Retry', onClick: () => window.history.reload()}],
						closeOnEscape: true,
						closeOnClickOutside: true,
					});
				}
				throw response.json();
			}).then(data => {
				confirmAlert({
					message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.thanksForFeedback),
					buttons: [{label: retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.ok), onClick: () => this.props.history.goBack()}],
					closeOnEscape: true,
					closeOnClickOutside: true,
				});
			}).catch(error => {
				return error;
			}).then(err => {
				console.log(err);
			});
		} else {
			confirmAlert({
				message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.feedbackSentOnce),
				buttons: [{label: retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.ok)}],
				closeOnEscape: true,
				closeOnClickOutside: true,
			});
		}
	}

	chooseRecipient(e) {
		e.preventDefault();
		let recipient = e.currentTarget.value;
		this.setState({
			recipient: recipient
		});
	}

	chooseServiceProvider(e) {
		e.preventDefault();
		let serviceProviderId = e.currentTarget.value;
		let selectedServiceProvider = null;
		this.state.serviceProviders.forEach(function(serviceProvider) {
			if (serviceProvider.id === serviceProviderId) {
				selectedServiceProvider = serviceProvider;
			}
		});
		if (selectedServiceProvider != null) {
			this.setState({
				serviceProviderId: selectedServiceProvider.id,
				serviceProviderName: selectedServiceProvider.name
			});
		}
	}

	handleFeedbackTitle(e) {
		e.preventDefault();
		let feedbackTitle = e.currentTarget.value;
		this.setState({
			title: feedbackTitle
		});
	}

	handleFeedbackEmail(e) {
		e.preventDefault();
		let senderEmail = e.currentTarget.value;
		this.setState({
			email: senderEmail
		});
	}

	handleFeedback(e) {
		e.preventDefault();
		let feedbackMsg = e.currentTarget.value;
		this.setState({
			message: feedbackMsg
		});
	}

	homePage = () => {
		store.clearAll();
		window.location.assign(homepath);
	}

	handleSubmit(e) {
		e.preventDefault();
		fetch(url.concat('/feedback').concat(rmsUrl).concat('?'), {
			method: 'post',
			headers: {'Content-type':'application/json; charset=utf-8', 'Accept': 'application/json; charset=utf-8'},
			body: JSON.stringify(
				{"feedback":
						{
							score: null,
							title: this.state.title,
							recipient: this.state.recipient,
							email: this.state.email,
							message: this.state.message,
							serviceProvider: {
								id: this.state.serviceProviderId,
								name: this.state.serviceProviderName
							},
						},
					"timestamp": new Date().toJSON()
				}
			)
		}).then(response => {
			if(response.status === 200) {
				return response.json();
			}
			if(response.status === 404) {
				confirmAlert({
					message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.serviceNotFound),
					buttons: [{label: 'Retry', onClick: () => window.history.reload()}],
					closeOnEscape: true,
					closeOnClickOutside: true,
				});
			}
			throw response.json();
		}).then(data => {
			confirmAlert({
				message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.thanksForFeedback),
				buttons: [{label: 'Ok', onClick: () => this.props.history.goBack()}],
				closeOnEscape: false,
				closeOnClickOutside: false,
			});
		}).catch(error => {
			return error;
		}).then(err => {
			console.log(err);
		});
	}

	componentDidMount() {
		let serviceData = null;
		if(store.get('serviceData') !== null && store.get('serviceData') !== undefined) {

			serviceData = store.get('serviceData');
			serviceData = JSON.parse(serviceData);
			console.log("service from store: ", serviceData);
			this.setState({
				serviceId: serviceData.serviceId
			});
			this.setState({
				service: serviceData.selectedService
			});
			this.setState({
				serviceProviderName: serviceData.selectedOffice
			});
			this.setState({
				serviceProviderId: serviceData.selectedOfficeId
			});
		}

		fetch(url.concat('/available-service-providers').concat(rmsUrl), {
			method: 'post',
			headers: {'Content-type':'application/json', 'Accept': 'application/json'}
		}).then(response => {
			if(response.status === 200) {
				return response.json();
			}
			if(response.status === 404) {
				if(response.status === 404) {
					confirmAlert({
						message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.noAvailableServiceProvidersFound),
						buttons: [{label: retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.ok), onClick: () => window.history.reload()}],
						closeOnEscape: true,
						closeOnClickOutside: true,
					});
				}
			}
			throw response.json();
		}).then(data => {
			this.setState({
				serviceProviders: data
			});
			if(serviceData === null) {
				for(let m=0; m<data.length; m++) {
					if(data[m].allowFeedback === true) {
						this.setState({
							serviceProviderId: data[m].id
						});
						this.setState({
							serviceProviderName: data[m].name
						});
						break;
					}
				}
			}
			this.setState({
				recipient: retrieveLanguageValue(this.props.state.language, Strings.reviewService.officeStaff)
			})
		}).catch(error => {
            console.log(error);
			return error;
		});
	}

    goBackFromReview(e) {
        e.preventDefault();
        console.log('go Back from review');
        this.props.history.goBack();
    }

    renderWindowResizeHandler(callBack) {
        return (
            <>
                <WindowResizeHandler callBack={callBack}/>
            </>
        )
    }

    handleWindowChange(dimensions) {
        this.setState({
            dimensions: dimensions
        })
    }

    render() {

		let serviceOffices = this.state.serviceProviders;
        let isMobileReal = isMobile;
        if (!isMobile) {
            if (window.innerWidth < this.props.state.mobileBreakPoint) {
                isMobileReal = true;
            }
        }

        let service = this.props.state.services;
		if (isMobileReal) {
			return (
				<div>
					<TopNavigation name="reviewService" globalGetNavigationState={this.props.globalGetNavigationState} globalSetNavigationState={this.props.globalSetNavigationState} globalLang={this.props.globalLang} setSessionId={this.props.setSessionId} state={this.props.state} />
					<MyMenu state={this.props.state} name="reviewService" />
                    {this.renderWindowResizeHandler(this.handleWindowChange)}
					<div className="mobile-review-service-wrapper">
						<div className="mobile-review-block">
							{this.props.state.sessionId !== null && this.props.state.sessionId !== undefined  && this.state.useScore ? (
								<div className="mobile-review-icons">
									<div className="mobile-review-icons-title">{retrieveLanguageValue(this.props.state.language, Strings.reviewService.serviceExperience)}</div>
									<div className="review-icons-grid">
										<div value='5' onClick={this.handleIconClick}>
											{this.state.score === 5 ? (
												<div className="icon1">
													<img src={require('../images/naama-1-iso-color.svg')} className="mobile-naama-1-iso" alt={retrieveLanguageValue(this.props.state.language, Strings.reviewService.excellent)} />
													<span className="mobile-loistavasti" style={{fontWeight: '500'}}>{retrieveLanguageValue(this.props.state.language, Strings.reviewService.excellent)}</span>
												</div>
											) : (
												<div className="icon1">
													<img src={require('../images/naama-1-iso.svg')} className="mobile-naama-1-iso" alt={retrieveLanguageValue(this.props.state.language, Strings.reviewService.excellent)} />
													<span className="mobile-loistavasti">{retrieveLanguageValue(this.props.state.language, Strings.reviewService.excellent)}</span>
												</div>
											)
											}

										</div>

										<div value='4' onClick={this.handleIconClick}>
											{this.state.score === 4 ? (
												<div className="icon2">
													<img src={require('../images/naama-2-iso-color.svg')} className="mobile-naama-1-iso" alt={retrieveLanguageValue(this.props.state.language, Strings.reviewService.good)} />
													<span className="mobile-loistavasti" style={{fontWeight: '500'}}>{retrieveLanguageValue(this.props.state.language, Strings.reviewService.good)}</span>
												</div>
											) : (
												<div className="icon2">
													<img src={require('../images/naama-2-iso.svg')} className="mobile-naama-1-iso" alt={retrieveLanguageValue(this.props.state.language, Strings.reviewService.good)} />
													<span className="mobile-loistavasti">{retrieveLanguageValue(this.props.state.language, Strings.reviewService.good)}</span>
												</div>
											)
											}
										</div>

										<div value='3' onClick={this.handleIconClick}>
											{this.state.score === 3 ? (
												<div className="icon3">
													<img src={require('../images/naama-3-iso-color.svg')} className="mobile-naama-1-iso" alt={retrieveLanguageValue(this.props.state.language, Strings.reviewService.ok)} />
													<span className="mobile-loistavasti" style={{fontWeight: '500'}}>{retrieveLanguageValue(this.props.state.language, Strings.reviewService.ok)}</span>
												</div>
											) : (
												<div className="icon3">
													<img src={require('../images/naama-3-iso.svg')} className="mobile-naama-1-iso" alt={retrieveLanguageValue(this.props.state.language, Strings.reviewService.ok)} />
													<span className="mobile-loistavasti">{retrieveLanguageValue(this.props.state.language, Strings.reviewService.ok)}</span>
												</div>
											)
											}
										</div>

										<div value='2' onClick={this.handleIconClick}>
											{this.state.score === 2 ? (
												<div className="icon4">
													<img src={require('../images/naama-4-iso-color.svg')} className="mobile-naama-1-iso" alt={retrieveLanguageValue(this.props.state.language, Strings.reviewService.notGood)}/>
													<span className="mobile-loistavasti" style={{fontWeight: '500'}}>{retrieveLanguageValue(this.props.state.language, Strings.reviewService.notGood)}</span>
												</div>
											) : (
												<div className="icon4">
													<img src={require('../images/naama-4-iso.svg')} className="mobile-naama-1-iso" alt={retrieveLanguageValue(this.props.state.language, Strings.reviewService.notGood)}/>
													<span className="mobile-loistavasti">{retrieveLanguageValue(this.props.state.language, Strings.reviewService.notGood)}</span>
												</div>
											)
											}
										</div>

										<div value='1' onClick={this.handleIconClick}>
											{this.state.score === 1 ? (
												<div className="icon5">
													<img src={require('../images/naama-5-iso-color.svg')} className="mobile-naama-1-iso" alt={retrieveLanguageValue(this.props.state.language, Strings.reviewService.bad)}/>
													<span className="mobile-loistavasti" style={{fontWeight:'500'}}>{retrieveLanguageValue(this.props.state.language, Strings.reviewService.bad)}</span>
												</div>
											) : (
												<div className="icon5">
													<img src={require('../images/naama-5-iso.svg')} className="mobile-naama-1-iso" alt={retrieveLanguageValue(this.props.state.language, Strings.reviewService.bad)}/>
													<span className="mobile-loistavasti">{retrieveLanguageValue(this.props.state.language, Strings.reviewService.bad)}</span>
												</div>
											)
											}
										</div>

									</div>
									<div className="mobile-review-icons-footer">
										{retrieveLanguageValue(this.props.state.language, Strings.reviewService.feedbackDescText)}
										<a href="/" className="text-style" onClick={this.backToFrontPage}>
											{retrieveLanguageValue(this.props.state.language, Strings.reviewService.backToFrontPage)}
										</a>
									</div>
								</div>

							) : ""}

							<div className="mobile-review-lower-block">
								<div className="mobile-review-lower-block-title">{retrieveLanguageValue(this.props.state.language, Strings.reviewService.sendFeedbackMsg)}</div>

								<div className="mobile-review-lower-block-divisions">

									<div className="mobile-review-lower-left">
										<AvForm onValidSubmit={this.handleSubmit}>
											{(this.state.useServiceProvider) ? (
												<FormGroup>
													<Label for="office-selection" className="mobile-label-style">{retrieveLanguageValue(this.props.state.language, Strings.reviewService.chooseOffice)}</Label>
													<Input type="select" name="select" id="office-selection" onChange={this.chooseServiceProvider} value={this.state.serviceProviderId}>
														{serviceOffices.forEach((office) => {
															if(office.allowFeedback === true) {

																return <option key={office.id} value={office.id}>{retrieveLanguageValue(this.props.state.language, office.name)}</option>
															}
														})}
													</Input>
												</FormGroup>
											) : ""}
											{(this.state.useRecipient) ? (
												<FormGroup>
													<Label for="feedback-recipient" className="mobile-label-style">{retrieveLanguageValue(this.props.state.language, Strings.reviewService.feedbackRecipient)}</Label>
													<AvField type="select" name="select" id="feedback-recipient" onChange={this.chooseRecipient} value={this.state.recipient}>
														<option>{retrieveLanguageValue(this.props.state.language, Strings.reviewService.officeStaff)}</option>
														<option>{retrieveLanguageValue(this.props.state.language, Strings.reviewService.sysAdmin)}</option>
													</AvField>
												</FormGroup>
											) : ""}

											<FormGroup>
												<Label for="reviewer-email-address" className="mobile-label-style">
													{retrieveLanguageValue(this.props.state.language, Strings.reviewService.email)}
												</Label>
												<AvField
													type="email"
													name="reviewer-email-address"
													id="reviewer-email-address"
													className="reviewer-email-address"
													errorMessage={retrieveLanguageValue(this.props.state.language, Strings.generic.emailExample)}
													validate={{ email: true, required: {value: true, errorMessage: retrieveLanguageValue(this.props.state.language, Strings.generic.cannotBeEmpty)} }}
													onChange={this.handleFeedbackEmail} />
											</FormGroup>

											<FormGroup>
												<Label for="feedback-title" className="mobile-label-style">{retrieveLanguageValue(this.props.state.language, Strings.reviewService.feedbackSubject)}</Label>
												<AvField
													type="text"
													name="feedback-title"
													id="feedback-title"
													className="feedback-title"
													validate={{ required: {value: true, errorMessage: retrieveLanguageValue(this.props.state.language, Strings.generic.cannotBeEmpty)} }}
													onChange={this.handleFeedbackTitle} />
											</FormGroup>

											<FormGroup>
												<Label for="feedback" className="mobile-label-style">{retrieveLanguageValue(this.props.state.language, Strings.reviewService.feedbackMsg)}</Label>
												<AvField
													className="review-text-area"
													type="textarea"
													name="feedback"
													id="feedback"
													maxLength="1000"
													validate={{ required: {value: true, errorMessage: retrieveLanguageValue(this.props.state.language, Strings.generic.cannotBeEmpty)} }}
													onChange={this.handleFeedback} />
											</FormGroup>

											<button className="review-feedback-button float-left">{retrieveLanguageValue(this.props.state.language, Strings.reviewService.sendFeedbackMsg)}</button>

                                            <button onClick={this.goBackFromReview} className="review-go-back-button">{retrieveLanguageValue(this.props.state.language, Strings.reviewService.goBack)}</button>

                                        </AvForm>
									</div>
                                    <CookiePolicy state={this.props.state} cookiePolicyExpiration={service != null ? service.cookiePolicyExpiration : null} cookiePolicy={service != null ? service.cookiePolicy : null} cookiePolicyTitle={service != null ? service.cookiePolicyTitle : null}/>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<TopNavigation name="reviewService" globalGetNavigationState={this.props.globalGetNavigationState} globalSetNavigationState={this.props.globalSetNavigationState} globalLang={this.props.globalLang} setSessionId={this.props.setSessionId} state={this.props.state} />
					<MyMenu state={this.props.state} name="reviewService" />
                    {this.renderWindowResizeHandler(this.handleWindowChange)}
					<div className="review-service-wrapper">
						<div className="review-wrapper">
							<div className="review-service-bg-image" />

							<div className="review-block">
								{(this.props.state.sessionId !== null && this.props.state.sessionId !== undefined && this.state.useScore) ? (
									<div className="review-upper-block">
										<div className="review-icons">
											<div className="review-icons-title">{retrieveLanguageValue(this.props.state.language, Strings.reviewService.serviceExperience)}</div>
											<div className="review-icons-grid">
												<div value='5' onClick={this.handleIconClick}>
													{this.state.score === 5 ? (
														<div className="icon1">
															<img src={require('../images/naama-1-iso-color.svg')} className="naama-1-iso" alt={retrieveLanguageValue(this.props.state.language, Strings.reviewService.excellent)} />
															<span className="loistavasti" style={{fontWeight: '500'}}>{retrieveLanguageValue(this.props.state.language, Strings.reviewService.excellent)}</span>
														</div>
													) : (
														<div className="icon1">
															<img src={require('../images/naama-1-iso.svg')} className="naama-1-iso" alt={retrieveLanguageValue(this.props.state.language, Strings.reviewService.excellent)} />
															<span className="loistavasti">{retrieveLanguageValue(this.props.state.language, Strings.reviewService.excellent)}</span>
														</div>
													)
													}
												</div>

												<div value='4' onClick={this.handleIconClick}>
													{this.state.score === 4 ? (
														<div className="icon2">
															<img src={require('../images/naama-2-iso-color.svg')} className="naama-1-iso" alt={retrieveLanguageValue(this.props.state.language, Strings.reviewService.good)} />
															<span className="loistavasti" style={{fontWeight: '500'}}>{retrieveLanguageValue(this.props.state.language, Strings.reviewService.good)}</span>
														</div>
													) : (
														<div className="icon2">
															<img src={require('../images/naama-2-iso.svg')} className="naama-1-iso" alt={retrieveLanguageValue(this.props.state.language, Strings.reviewService.good)} />
															<span className="loistavasti">{retrieveLanguageValue(this.props.state.language, Strings.reviewService.good)}</span>
														</div>
													)
													}
												</div>

												<div value='3' onClick={this.handleIconClick}>
													{this.state.score === 3 ? (
														<div className="icon3">
															<img src={require('../images/naama-3-iso-color.svg')} className="naama-1-iso" alt={retrieveLanguageValue(this.props.state.language, Strings.reviewService.ok)} />
															<span className="loistavasti" style={{fontWeight: '500'}}>{retrieveLanguageValue(this.props.state.language, Strings.reviewService.ok)}</span>
														</div>
													) : (
														<div className="icon3">
															<img src={require('../images/naama-3-iso.svg')} className="naama-1-iso" alt={retrieveLanguageValue(this.props.state.language, Strings.reviewService.ok)} />
															<span className="loistavasti">{retrieveLanguageValue(this.props.state.language, Strings.reviewService.ok)}</span>
														</div>
													)
													}
												</div>

												<div value='2' onClick={this.handleIconClick}>
													{this.state.score === 2 ? (
														<div className="icon4">
															<img src={require('../images/naama-4-iso-color.svg')} className="naama-1-iso" alt={retrieveLanguageValue(this.props.state.language, Strings.reviewService.notGood)}/>
															<span className="loistavasti" style={{fontWeight: '500'}}>{retrieveLanguageValue(this.props.state.language, Strings.reviewService.notGood)}</span>
														</div>
													) : (
														<div className="icon4">
															<img src={require('../images/naama-4-iso.svg')} className="naama-1-iso" alt={retrieveLanguageValue(this.props.state.language, Strings.reviewService.notGood)}/>
															<span className="loistavasti">{retrieveLanguageValue(this.props.state.language, Strings.reviewService.notGood)}</span>
														</div>
													)
													}
												</div>

												<div value='1' onClick={this.handleIconClick}>
													{this.state.score === 1 ? (
														<div className="icon5">
															<img src={require('../images/naama-5-iso-color.svg')} className="naama-1-iso" alt={retrieveLanguageValue(this.props.state.language, Strings.reviewService.bad)}/>
															<span className="loistavasti" style={{fontWeight:'500'}}>{retrieveLanguageValue(this.props.state.language, Strings.reviewService.bad)}</span>
														</div>
													) : (
														<div className="icon5">
															<img src={require('../images/naama-5-iso.svg')} className="naama-1-iso" alt={retrieveLanguageValue(this.props.state.language, Strings.reviewService.bad)}/>
															<span className="loistavasti">{retrieveLanguageValue(this.props.state.language, Strings.reviewService.bad)}</span>
														</div>
													)
													}
												</div>
											</div>
											<div className="review-icons-footer">
												{retrieveLanguageValue(this.props.state.language, Strings.reviewService.feedbackDescText)}
												<Link exact to="/" className="text-style">
													{retrieveLanguageValue(this.props.state.language, Strings.reviewService.backToFrontPage)}
												</Link>
											</div>
										</div>
									</div>
								) : ""}
								<div className="review-lower-block">
									<div className="review-lower-block-title">{retrieveLanguageValue(this.props.state.language, Strings.reviewService.sendFeedbackMsg)}</div>
									<AvForm onValidSubmit={this.handleSubmit}>
										<div className="review-lower-block-divisions">
											<div className="review-lower-left">

												{(this.state.useServiceProvider) ? (
													<FormGroup>
														<Label for="office-selection" className="label-style">{retrieveLanguageValue(this.props.state.language, Strings.reviewService.chooseOffice)}</Label>
														<Input type="select" name="select" id="office-selection" onChange={this.chooseServiceProvider} value={this.state.serviceProviderId}>
															{serviceOffices.forEach((office) => {
																if(office.allowFeedback === true) {
																	return <option key={office.id} value={office.id}>{retrieveLanguageValue(this.props.state.language, office.name)}</option>
																}
															})}
														</Input>
													</FormGroup>
													) : ""}
												<FormGroup>
													<Label for="feedback-title" className="label-style">{retrieveLanguageValue(this.props.state.language, Strings.reviewService.feedbackSubject)}</Label>
													<AvField
														type="text"
														name="feedback-title"
														id="feedback-title"
														className="feedback-title"
														validate={{ required: {value: true, errorMessage: retrieveLanguageValue(this.props.state.language, Strings.generic.cannotBeEmpty)} }}
														onChange={this.handleFeedbackTitle} />
												</FormGroup>

												<FormGroup>
													<Label for="feedback" className="label-style">{retrieveLanguageValue(this.props.state.language, Strings.reviewService.feedbackMsg)}</Label>
													<AvField
														className="review-text-area"
														type="textarea"
														name="feedback"
														id="feedback"
														maxLength="1000"
														validate={{ required: {value: true, errorMessage: retrieveLanguageValue(this.props.state.language, Strings.generic.cannotBeEmpty)} }}
														onChange={this.handleFeedback} />
												</FormGroup>

												<button className="review-feedback-button float-left">{retrieveLanguageValue(this.props.state.language, Strings.reviewService.sendFeedbackMsg)}</button>

                                                <button onClick={this.goBackFromReview} className="review-go-back-button">{retrieveLanguageValue(this.props.state.language, Strings.reviewService.goBack)}</button>

                                            </div>

											<div className="review-lower-right">
												{(this.state.useRecipient) ? (
													<FormGroup>
														<Label for="feedback-recipient" className="label-style">{retrieveLanguageValue(this.props.state.language, Strings.reviewService.feedbackRecipient)}</Label>
														<AvField type="select" name="select" id="feedback-recipient" onChange={this.chooseRecipient}>
															<option>{retrieveLanguageValue(this.props.state.language, Strings.reviewService.officeStaff)}</option>
															<option>{retrieveLanguageValue(this.props.state.language, Strings.reviewService.sysAdmin)}</option>
														</AvField>
													</FormGroup>
												) : ""}
												<FormGroup>
													<Label for="reviewer-email-address" className="label-style">
														{retrieveLanguageValue(this.props.state.language, Strings.reviewService.email)}
													</Label>
													<AvField
														type="email"
														name="reviewer-email-address"
														id="reviewer-email-address"
														className="reviewer-email-address"
														errorMessage={retrieveLanguageValue(this.props.state.language, Strings.generic.emailExample)}
														validate={{ email: true, required: {value: true, errorMessage: retrieveLanguageValue(this.props.state.language, Strings.generic.cannotBeEmpty)} }}
														onChange={this.handleFeedbackEmail} />
												</FormGroup>
											</div>
										</div>
									</AvForm>
								</div>
                                <CookiePolicy state={this.props.state} cookiePolicyExpiration={service != null ? service.cookiePolicyExpiration : null} cookiePolicy={service != null ? service.cookiePolicy : null} cookiePolicyTitle={service != null ? service.cookiePolicyTitle : null}/>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}

}

export default ReviewService;
