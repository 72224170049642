//import React from 'react';
import store from "store";

export const CHOOSE_A_SERVICE = 'chooseAService';
export const CHOOSE_AN_OFFICE = 'chooseAnOffice';
export const CHOOSE_A_TIME = 'chooseATime';
export const SELECT_TIME = 'selectTime';
export const ENTER_INFO = 'enterInfo';
export const CHECK_INFO = 'checkInfo';
export const CONFIRM_INFO = 'confirmInfo';

export const CHANGE_RESERVATION_TIME = 'changeReservationTime';
export const EDIT_INFORMATION = 'editInformation';

export const MENU_ITEM_STORAGE_KEY = 'MenuItem_V3';

export function GlobalIsMenuStateAccessed(stateName) {
    let menuStateObject = store.get(MENU_ITEM_STORAGE_KEY);
    let state = menuStateObject.states[stateName];
    if (state) {
        return state.accessed;
    }
    return false;
}

export function GlobalIsMenuStateCompleted(stateName) {
    let menuStateObject = store.get(MENU_ITEM_STORAGE_KEY);
    let state = menuStateObject.states[stateName];
    if (state) {
        return state.completed;
    }
    return false;
}



export function GlobalSetMenuState(stateName, newStates) {

    let menuStateObject = store.get(MENU_ITEM_STORAGE_KEY);
    let state = menuStateObject.states[stateName];
    if (state) {
        let changed = false;
        if (newStates) {
            if (newStates.accessed) {
                state.accessed = newStates.accessed;
                changed = true;
            }
            if (newStates.completed) {
                state.completed = newStates.completed;
                changed = true;
            }
        }
        if (changed) {
//            console.log('>> new menuStateObject, changed: ' + stateName, menuStateObject);
            store.set(MENU_ITEM_STORAGE_KEY, menuStateObject);
        } else {
//            console.log('>> nothing chanaged!');
        }

    } else {
        throw new Error('invalid stateName: ' + stateName);
    }

}

export function GlobalSetStateEntered(stateName) {
    let menuStateObject = store.get(MENU_ITEM_STORAGE_KEY);
    menuStateObject.lastStateEntered = stateName;
    store.set(MENU_ITEM_STORAGE_KEY, menuStateObject);
}

export function GlobalGetStateEntered() {
    let menuStateObject = store.get(MENU_ITEM_STORAGE_KEY);
    return menuStateObject.lastStateEntered;
}

export function GlobalGetMenuStateObject() {
    let menuStateObject = store.get(MENU_ITEM_STORAGE_KEY);
    return menuStateObject;
}


export function GlobalInitMenuStateObject() {

    let menuStateObject = store.get(MENU_ITEM_STORAGE_KEY);
    if (menuStateObject === null || menuStateObject === undefined) {
//        console.log('>>> APP.js: initMenuItem !! ')
        menuStateObject = getInitializeMenuStateData();
        store.set(MENU_ITEM_STORAGE_KEY, menuStateObject);
    }

//    console.log('>>> APP.js: menuStateObject', menuStateObject)
}



function getInitializeMenuStateData() {
    let menuStateObject = {
        menuArray: null,
        states: {},
        lastStateEntered: null
    };

    menuStateObject.states[CHOOSE_A_SERVICE] = {
        accessed: false,
        completed: false
    };
    menuStateObject.states[CHOOSE_AN_OFFICE] = {
        accessed: false,
        completed: false
    };
    menuStateObject.states[CHOOSE_A_TIME] = {
        accessed: false,
        completed: false
    };
    menuStateObject.states[SELECT_TIME] = {
        accessed: false,
        completed: false
    };
    menuStateObject.states[ENTER_INFO] = {
        accessed: false,
        completed: false
    };
    menuStateObject.states[CHECK_INFO] = {
        accessed: false,
        completed: false
    };
    menuStateObject.states[CONFIRM_INFO] = {
        accessed: false,
        completed: false
    };
    menuStateObject.states[CHANGE_RESERVATION_TIME] = {
        accessed: false,
        completed: false
    };
    menuStateObject.states[EDIT_INFORMATION] = {
        accessed: false,
        completed: false
    };

    let menuArray = [
        {name: 'chooseAService', accessed: true},
        {name: 'chooseAnOffice', accessed: false},
        {name: 'chooseATime', accessed: false},
        {name: 'selectTime', accessed: false},
        {name: 'enterInfo', accessed: false},
        {name: 'checkInfo', accessed: false},
        {name: 'confirmInfo', accessed: false},

        {selected: 'chooseAService'},
        {lastCompleted: null},
    ];
    menuStateObject.menuArray = menuArray;
    return menuStateObject;
}

