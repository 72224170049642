import React from 'react';
import RMSForm from "./RMSForm";
import FormDataJson from "./reserve.json";
import {retrieveLanguageValue} from "../utils/helper";
import Strings from "../utils/strings";


const NewEnterInformation = (props) => {


    const reservationInfo = {
        dateWeekDay: "Perjantai",
        date: "05.11.2021",
        time: "07:00 - 07:10",
        selectedService: "Veri- ja virtsakokeet",
        selectedOffice: "Heinävesi, terveyskeskuksen laboratorio"
    }

    const rmsFormProps = {
        json: FormDataJson,
        headerText: retrieveLanguageValue(props.language, Strings.enterInfo.reservationCustomerInfo),
        reservationInfo: reservationInfo,
        submitButtonText: retrieveLanguageValue(props.language, Strings.enterInfo.continueToConfirmBtn),
        editInfo: true
    }

    return (
        <>
            <div className={"enter-form"}>
                <RMSForm rmsFormProps={rmsFormProps}/>
            </div>
        </>
    )

}

export default NewEnterInformation;
