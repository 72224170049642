import React, {Component} from 'react';
import MyMenu from '../Menu/MyMenu';
import TopNavigation from '../TopNavigation/TopNavigation';
import CookiePolicy from '../CookiePolicy/CookiePolicy';
import {Redirect} from 'react-router';
import url from '../utils/path';
import homepath from '../utils/homepath';
import {
    get_css_class_and_with_variant,
    get_ui_variant,
    get_weekday_name_from_date,
    getStaticImage,
    retrieveLanguageValue,
    retrieveLanguageValueWithReplacements,
    retrieveLargeImage
} from '../utils/helper';
import Strings from '../utils/strings';
import store from 'store';

import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import parse from 'html-react-parser';

import './CheckInformation.css';

import {Prompt} from 'react-router-dom'
import {Beforeunload} from 'react-beforeunload';

import {isMobile} from "react-device-detect";
import {CHECK_INFO, GlobalSetMenuState, GlobalSetStateEntered} from "../utils/components/GlobalMenuState";
import WindowResizeHandler from "../utils/components/WindowResizeHandler";

class CheckInformation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toConfirmInfo: false,
            serviceId: '',
            sessionId: '',
            reservationCode: '',
            dataUpdated: false,
            data: null,
            selectedImage: store.get('selectedImage'),
            selectedOffice: '',
            selectedService: '',
            backgroundImage: null
        }

        this.routeChange = this.routeChange.bind(this);
        this.handleWindowChange = this.handleWindowChange.bind(this);

    }

    homePage = () => {
        let logoutUrl = store.get('logoutUrl');
        store.clearAll();
        store.set('logoutUrl', logoutUrl);
        this.props.history.push(homepath);
    }

    routeChange() {

//        console.log('>> this.state.data', this.state.data);

        fetch(url.concat('/reservation/').concat(this.state.reservationCode).concat('/realize').concat('?sessionId=').concat(this.state.sessionId), {
            method: 'post',
            headers: {'Content-type': 'application/json', 'Accept': 'application/json'},
            body: JSON.stringify({"reservation": this.state.data})
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            /*			if(response.status === 403) {
                            //Session has expired
                            confirmAlert({
                                message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.sessionHasExpired),
                                buttons: [{label: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.returnToFrontPage), onClick: () => this.homePage()}],
                                closeOnEscape: false,
                                closeOnClickOutside: false,
                            });
                        }*/
            if (response.status === 404) {
                //Cannot create reservation
                confirmAlert({
                    message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.reservationSessionExpired),
                    buttons: [{
                        label: retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.ok),
                        onClick: () => this.props.history.goBack()
                    }],
                    closeOnEscape: true,
                    closeOnClickOutside: true,
                });
            }
            if (response.status === 409) {
                //Cannot create reservation
                confirmAlert({
                    message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.reservationValidationFailed),
                    buttons: [{
                        label: retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.ok),
                        onClick: () => this.props.history.goBack()
                    }],
                    closeOnEscape: true,
                    closeOnClickOutside: true,
                });
            }
            throw response.json();
        }).then(data => {
            this.setState({
                data: data,
                toConfirmInfo: true
            });
        }).catch(error => {
            console.log("Error:", error);
            return error;
        });
    }

    getServiceData(serviceUrl) {

//        console.log('serviceUrl', serviceUrl);

        fetch(serviceUrl, {
            method: 'get',
            headers: {'Content-Type': 'application/json; charset=utf-8', 'Accept': 'application/json; charset=utf-8'},

        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            if (response.status === 404) {
                confirmAlert({
                    message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.noAvailableServiceProvidersFound),
                    buttons: [{label: retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.ok)}],
                    closeOnEscape: true,
                    closeOnClickOutside: true,
                });
            }
            throw response.json();
        }).then(data => {
            this.setState({
                backgroundImage: data.backgroundImage
            });

        }).catch(error => {
            return error;
        });
    }

    componentDidMount() {

        window.scrollTo(0, 0);

        let sessionId = store.get('sessionId');
        if (sessionId === null || sessionId === undefined) {
            this.props.history.push(homepath);
            return;
        }

        let serviceUrl = store.get('serviceUrl');
        this.getServiceData(serviceUrl);

        GlobalSetMenuState(CHECK_INFO, {accessed: true})
        GlobalSetStateEntered(CHECK_INFO);


        if (store.get('CheckInformation') !== null && store.get('CheckInformation') !== undefined) {

            let checkedInfo = JSON.parse(store.get("CheckInformation"));
            delete checkedInfo['toConfirmInfo'];
            this.setState(checkedInfo);

        } else {
            let reservationCode = this.props.location.state.reservationCode;
            let serviceId = this.props.location.state.serviceId;
            let sessionId = this.props.location.state.sessionId;
            this.setState({
                reservationCode: reservationCode,
                serviceId: serviceId,
                sessionId: sessionId,
                selectedService: this.props.location.state.selectedService,
                selectedOffice: this.props.location.state.selectedOffice
            });

            if (this.props.location.state.updated !== undefined) {
                this.setState({
                    dataUpdated: this.props.location.state.updated
                });
            }
            if (this.props.location.state.data !== undefined && this.props.location.state.data !== null) {
                this.setState({
                    data: this.props.location.state.data
                });
            }

            let theUrl = url.concat('/reservation/').concat(reservationCode).concat('?sessionId=').concat(sessionId);
//			console.log('>>>> theUrl', theUrl);
            fetch(theUrl, {
                method: 'get',
                headers: {'Content-type': 'application/json', 'Accept': 'application/json'}
            }).then(response => {
//                console.log('>>>> response', response);
                if (response.status === 200) {
                    return response.json();
                }
                if (response.status === 403) {
                    //Session has expired
                    confirmAlert({
                        message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.sessionHasExpired),
                        buttons: [{
                            label: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.returnToFrontPage),
                            onClick: () => this.homePage()
                        }],
                        closeOnEscape: false,
                        closeOnClickOutside: false,
                    });
                }
                if (response.status === 404) {
                    //Reservation code was not found
                    confirmAlert({
                        message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.reservationNotFound),
                        buttons: [
                            {
                                label: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.reloadPage),
                                onClick: () => window.location.reload()
                            },
                            {
                                label: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.returnToFrontPage),
                                onClick: () => this.homePage()
                            }
                        ],
                        closeOnEscape: false,
                        closeOnClickOutside: false,
                    });
                }
                this.props.globalBackEndError(true);
                throw response.json();
            }).then(data => {

                let myDataValues = this.state.data.values;
                let vals = data.values;

                for (let i = 0; i < vals.length; i++) {
                    if (vals[i].propertyGroupId === 'contactInformation') {
                        myDataValues.push(vals[i]);
                    }
                }

                let myData = this.state.data;
                myData.properties = data.properties;
                myData.values = myDataValues;
                this.setState({
                    data: myData
                });
            }).catch(error => {
                console.log('Fetch reservation data failed: error', error);
                return error;
            })
        }
    }

    componentWillUnmount() {
        let json = JSON.stringify(this.state);
        delete json['backgroundImage'];
        store.set('CheckInformation', json);
    }

    getRegisterUrl() {
        let replacements;
        if (this.state.data.registerLink) {
            switch (this.props.state.language) {
                case 'FI':
                    replacements = {"%URL%": this.state.data.registerLink.valueFinnish};
                    break;
                case 'SV':
                    replacements = {"%URL%": this.state.data.registerLink.valueSwedish};
                    break;
                case 'EN':
                    replacements = {"%URL%": this.state.data.registerLink.valueEnglish};
                    break;
                default:
                    replacements = {"%URL%": this.state.data.registerLink.valueDefault};
            }
        } else {
            replacements = {"%URL%": "#"};
        }

        if (!replacements["%URL%"]) {
            return parse(retrieveLanguageValueWithReplacements(this.props.state.language, Strings.checkInfo.consent, replacements));
        } else {
            return parse(retrieveLanguageValueWithReplacements(this.props.state.language, Strings.checkInfo.consent_url_placeholder, replacements));
        }
    }

    renderWindowResizeHandler(callBack) {
        return (
            <>
                <WindowResizeHandler callBack={callBack}/>
            </>
        )
    }

    handleWindowChange(dimensions) {
        this.setState({
            dimensions: dimensions
        })
    }


    getMobileSubmitButton() {
        return (
            <>
                <div className="save-booking-confirmation-b">
                    <button className={`${get_css_class_and_with_variant('submit-Btn-bg')}  bk-confirm-btn`}
                            onClick={this.routeChange}>
                        {retrieveLanguageValue(this.props.state.language, Strings.checkInfo.save)}
                    </button>
                </div>
            </>
        )
    }

    getSubmitButton() {
        return (
            <button
                className={`${get_css_class_and_with_variant('submit-Btn-bg')}  ${get_css_class_and_with_variant('bk-confirm-btn')} ${get_css_class_and_with_variant('bk-confirm-btn-extra')}`}
                onClick={this.routeChange}>
                {retrieveLanguageValue(this.props.state.language, Strings.checkInfo.save)}
            </button>
        )
    }


    getMobileDvvSubmitButton() {
        if (get_ui_variant() === 'DVV') {
            return this.getMobileSubmitButton();
        } else {
            return (
                <></>
            )
        }


    }

    getDvvSubmitButton() {
        if (get_ui_variant() === 'DVV') {
            return this.getSubmitButton();
        } else {
            return (
                <></>
            )
        }
    }


    render() {

        if (store.get('sessionId') === null || store.get('sessionId') === undefined) {
            console.log('>>> no sessionID -> redirect homepage!');
            window.location.assign(homepath);
            return "";
        } else {
            let img = '';
            if (get_ui_variant() === 'ISLAB') {
                if (this.state.backgroundImage) {
                    img = 'data:image/' + this.state.backgroundImage.type + ';base64,' + this.state.backgroundImage.data;
                } else {
//                    console.log('Logo not loaded yet!');
                }
            } else {
                if (this.state.selectedImage) {
                    img = getStaticImage(this.state.selectedImage);
                } else if (this.state.data) {
                    let service = this.state.data.service;
                    let largeImage = retrieveLargeImage(service.name.valueDefault);
                    img = getStaticImage(largeImage);
                }
            }

            if (this.state.toConfirmInfo === true) {

                GlobalSetMenuState(CHECK_INFO, {completed: true})

                return <Redirect push to={{
                    pathname: '/confirm-information',
                    state: {
                        reservationCode: this.state.reservationCode,
                        data: this.state.data,
                        serviceId: this.state.serviceId,
                        sessionId: this.state.sessionId,
                        updated: this.state.dataUpdated
                    }
                }}/>
            }

            let isMobileReal = isMobile;
            if (!isMobile) {
                if (window.innerWidth < this.props.state.mobileBreakPoint) {
                    isMobileReal = true;
                }
            }

            let userInfo = [];
            let locationInfo;
            let contactInformationTitle;
            let contactInformationContent = [];
            if (this.state.data !== null && this.state.data !== undefined) {
                let split = this.state.data.date.split("-");
                let date = split[2] + '.' + split[1] + '.' + split[0];
//                console.log('>>> this.state.data', this.state.data);
                let time = this.state.data.startTime + " - " + this.state.data.endTime;
                const serviceData = JSON.parse(store.get('serviceData'));
                locationInfo =
                    <div>
                        <CheckHeader isMobile={isMobileReal}
                                     title={retrieveLanguageValue(this.props.state.language, Strings.calendar.weekday[get_weekday_name_from_date(this.state.data.date)])}
                                     info={date} image={require('../images/kalenteri-ikoni.svg')}/>
                        <CheckHeader isMobile={isMobileReal}
                                     title={retrieveLanguageValue(this.props.state.language, Strings.calendar.at)}
                                     info={time} image={require('../images/aika-iso-ikoni.svg')}/>
                        <CheckHeader isMobile={isMobileReal}
                                     title={retrieveLanguageValue(this.props.state.language, serviceData.selectedService)}
                                     info={retrieveLanguageValue(this.props.state.language, serviceData.selectedOffice)}
                                     image={require('../images/organisaatio-iso-ikoni.svg')}/>
                    </div>

                let properties = this.state.data.properties;
                properties.map((props, m) => {
                    if (props.name.valueDefault === 'Yhteystiedot') {
                        contactInformationTitle = retrieveLanguageValue(this.props.state.language, props.name);
                        let contactProps = props.properties;
                        contactProps.sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : (a.orderNumber === b.orderNumber) ? ((a.propertyId > b.propertyId) ? 1 : -1) : -1);
                        contactInformationContent.push(contactProps.map(prop => {
                            let values = this.state.data.values;
                            for (let i = 0; i < values.length; i++) {
                                if (values[i].propertyId === prop.propertyId) {
                                    return <p
                                        key={prop.propertyId}>{retrieveLanguageValue(this.props.state.language, prop.name)}{": "} {values[i].propertyValue}</p>
                                }
                            }
                            return null;
                        }));
                    } else {
                        userInfo.push(<p key={m}>
                            <strong>{retrieveLanguageValue(this.props.state.language, props.name)}</strong></p>);
                        userInfo.push(props.properties.map(prop => {
                            let values = this.state.data.values;
                            for (let i = 0; i < values.length; i++) {
                                if (prop.propertyType === 'radio' || prop.propertyType === 'pulldown') {
                                    if (values[i].propertyId === prop.propertyId) {
                                        for (let u = 0; u < prop.options.length; u++) {
                                            if (prop.options[u].value === values[i].propertyValue) {
                                                return <p
                                                    key={prop.propertyId}>{retrieveLanguageValue(this.props.state.language, prop.name)}{": "} {retrieveLanguageValue(this.props.state.language, prop.options[u].name)}</p>
                                            } else if (values[i].propertyValue === "0") {
                                                return <p
                                                    key={prop.propertyId}>{retrieveLanguageValue(this.props.state.language, prop.name)}{": "} {values[i].defaultValue}</p>
                                            }
                                        }
                                    }
                                } else if (values[i].propertyId === prop.propertyId) {
                                    return <p
                                        key={prop.propertyId}>{retrieveLanguageValue(this.props.state.language, prop.name)}{": "} {values[i].propertyValue}</p>
                                }
                            }
                            return null;
                        }));
                    }
                    return null;
                });
            }

//			console.log('NO data!?');

            let registerUrlLink = '';
            if (this.state.data) {
                registerUrlLink = this.getRegisterUrl();
            }

            let service = this.props.state.services;
            if (isMobileReal) {
                return (
                    <div>
                        <TopNavigation globalGetNavigationState={this.props.globalGetNavigationState}
                                       globalSetNavigationState={this.props.globalSetNavigationState}
                                       globalBackEndError={this.props.globalBackEndError}
                                       globalLang={this.props.globalLang} setSessionId={this.props.setSessionId}
                                       state={this.props.state}/>
                        <div id={'islab-color-' + get_ui_variant()}>
                            <MyMenu state={this.props.state} name="checkInfo"/>
                        </div>
                        {this.renderWindowResizeHandler(this.handleWindowChange)}
                        <div className="mobile-check-info-wrapper">
                            <div className="mobile-check-content-wrapper">
                                <div
                                    className="mobile-check-major-title">{retrieveLanguageValue(this.props.state.language, Strings.checkInfo.checkReservationInfo)}</div>
                                <div
                                    className="check-major-desc">{retrieveLanguageValue(this.props.state.language, Strings.checkInfo.crossCheckInfoText)}</div>
                                {this.getMobileDvvSubmitButton()}
                                <div>
                                    <div
                                        className="mobile-check-major-title">1. {retrieveLanguageValue(this.props.state.language, Strings.checkInfo.timeOffice)}</div>
                                    <div className="check-major-items">
                                        {locationInfo}
                                    </div>
                                </div>
                                <div>
                                    <div
                                        className="mobile-check-major-title">2. {retrieveLanguageValue(this.props.state.language, Strings.checkInfo.reservationInfo)}</div>
                                    <div className="check-major-address">
                                        {userInfo}
                                    </div>
                                </div>
                                <div>
                                    <div className="mobile-check-major-title">3. {contactInformationTitle}</div>
                                    <div className="check-major-address">
                                        {contactInformationContent}
                                    </div>
                                </div>
                                <div className="check-major-save-booking">
                                    <div
                                        className="mobile-check-major-title">4. {retrieveLanguageValue(this.props.state.language, Strings.checkInfo.saveReservation)}</div>
                                    <div className="save-booking">
                                        <div className="save-booking-confirmation-text">
                                            {registerUrlLink}
                                            <p>{retrieveLanguageValue(this.props.state.language, Strings.checkInfo.additionalInfo)}</p>
                                        </div>
                                        {this.getMobileSubmitButton()}
                                    </div>
                                </div>
                            </div>
                            <CookiePolicy state={this.props.state} cookiePolicyExpiration={service != null ? service.cookiePolicyExpiration : null} cookiePolicy={service != null ? service.cookiePolicy : null} cookiePolicyTitle={service != null ? service.cookiePolicyTitle : null}/>
                        </div>

                        <Beforeunload
                            onBeforeunload={() => retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.checkReservation)}/>
                        <Prompt when={true}
                                message={retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.checkReservation)}/>
                    </div>
                );
            } else {
                return (
                    <div>
                        <TopNavigation globalGetNavigationState={this.props.globalGetNavigationState}
                                       globalSetNavigationState={this.props.globalSetNavigationState}
                                       globalLang={this.props.globalLang} setSessionId={this.props.setSessionId}
                                       state={this.props.state}/>
                        <div id={'islab-color-' + get_ui_variant()}>
                            <MyMenu state={this.props.state}/>
                        </div>
                        {this.renderWindowResizeHandler(this.handleWindowChange)}

                        <div className="check-info-wrapper">

                            <div className="check-wrapper">
                                <img src={img} className="check-info-top-image" alt="[taustakuva]"/>

                                <div className="check-content-wrapper">

                                    <div className="row">
                                        <div className="col-lg-10">
                                            <div className="check-major-title">
                                            {retrieveLanguageValue(this.props.state.language, Strings.checkInfo.checkReservationInfo)}
                                            </div>
                                        </div>
                                        <div className="col-lg-2">
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-10">
                                            <div className="check-major-desc">
                                                {retrieveLanguageValue(this.props.state.language, Strings.checkInfo.crossCheckInfoText)}
                                            </div>

                                        </div>
                                        <div className="col-lg-2">
                                            {this.getDvvSubmitButton()}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-10">
                                            <div className="check-major-title">
                                                1. {retrieveLanguageValue(this.props.state.language, Strings.checkInfo.timeOffice)}
                                            </div>
                                        </div>
                                        <div className="col-lg-2">
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-10">
                                            <div className="check-major-items">
                                                {locationInfo}
                                            </div>

                                        </div>
                                        <div className="col-lg-2">
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-lg-10">
                                            <div className="check-major-title">
                                                2. {retrieveLanguageValue(this.props.state.language, Strings.checkInfo.reservationInfo)}
                                            </div>
                                        </div>
                                        <div className="col-lg-2">
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-10">
                                            <div className="check-major-address">
                                                {userInfo}
                                            </div>

                                        </div>
                                        <div className="col-lg-2">
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-lg-10">
                                            <div className="check-major-title">
                                                3. {contactInformationTitle}
                                            </div>
                                        </div>
                                        <div className="col-lg-2">
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-10">
                                            <div className="check-major-address">
                                                {contactInformationContent}
                                            </div>
                                        </div>
                                        <div className="col-lg-2">
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-10">
                                            <div className="check-major-title">
                                                4. {retrieveLanguageValue(this.props.state.language, Strings.checkInfo.saveReservation)}
                                            </div>
                                        </div>
                                        <div className="col-lg-2">
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-10">
                                            <div className="check-major-address">
                                                {registerUrlLink}
                                            </div>
                                        </div>
                                        <div className="col-lg-2">
                                            {this.getSubmitButton()}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-10">
                                            <div className="check-major-address">
                                                {retrieveLanguageValue(this.props.state.language, Strings.checkInfo.additionalInfo)}
                                            </div>
                                        </div>
                                        <div className="col-lg-2">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <CookiePolicy state={this.props.state} cookiePolicyExpiration={service != null ? service.cookiePolicyExpiration : null} cookiePolicy={service != null ? service.cookiePolicy : null} cookiePolicyTitle={service != null ? service.cookiePolicyTitle : null}/>
                        </div>

                        <Beforeunload
                            onBeforeunload={() => retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.checkReservation)}/>
                        <Prompt when={true}
                                message={retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.checkReservation)}/>
                    </div>
                );
            }
        }
    }


}


function CheckHeader(props) {
    if (props.isMobile) {
        return (
            <div className="mobile-check-header">
                <img src={props.image} className="check-thumbnail" alt=""/>
                <div className="check-date-time-loc">
                    <p>{props.title}</p>
                    <p>{props.info}</p>
                </div>
            </div>
        );
    } else {
        return (
            <div className="check-header">
                <img src={props.image} className="check-thumbnail" alt=""/>
                <div className={get_css_class_and_with_variant('check-date-time-loc')}>
                    <p>{props.title}</p>
                    <p>{props.info}</p>
                </div>
            </div>
        );
    }
}


export default CheckInformation;
