import "./CookiePolicy.css";

import React, {Component} from 'react';
import store from 'store';
import {retrieveLanguageValue} from '../utils/helper';
import moment from 'moment';
import _ from 'lodash';


class CookiePolicy extends Component {
    constructor(props) {
        super(props);

        let cookiePolicyVisible = true;
        let cookiePolicySet = null;
        let cookiePolicy = store.get('cookie-policy');
        if (cookiePolicy) {
            cookiePolicyVisible = false;
            cookiePolicySet = cookiePolicy.at;
        } else {
            store.set('cookie-policy', {at: moment()});
        }
        
        this.state = {
            cookiePolicyVisible: cookiePolicyVisible,
            cookiePolicySet: cookiePolicySet
        };

    }
    
    componentDidUpdate() {
        if (this.state.cookiePolicyVisible) {
            return;
        }
        
        let expirationDate = moment(this.props.cookiePolicyExpiration);
        if (expirationDate.isValid()) {
            let at = moment(this.state.cookiePolicySet);
            if (at && at.isAfter(expirationDate)) {
                this.setState({cookiePolicyVisible: true});                
            }
        }
    }
    
    render() {
        const cookiePolicyTitle = retrieveLanguageValue(this.props.state.language, this.props.cookiePolicyTitle);
        const cookiePolicy = retrieveLanguageValue(this.props.state.language, this.props.cookiePolicy);
        const cookiePolicyHidden = _.isEmpty(cookiePolicy);
        return (<div id="cookiePolicy" class={cookiePolicyHidden ? "cookie-policy-hidden" : "cookie-policy"}>
                    {   !this.state.cookiePolicyVisible ? <span id="cookiePolicyTitle" onClick={() => this.toggleCookiePolicy()}>{cookiePolicyTitle}</span> : null }
                    {   this.state.cookiePolicyVisible ?
                        (<div>
                            <h4>{cookiePolicyTitle}</h4>
                            <p dangerouslySetInnerHTML={this.cookiePolicyAsHtml(cookiePolicy)}></p><button onClick={() => this.toggleCookiePolicy()} class="office-button">OK</button>
                        </div>)
                        : null
                    }
                </div>);
    }
  
    cookiePolicyAsHtml(cookiePolicy) { 
        return {__html: cookiePolicy};
    }
    
    toggleCookiePolicy() {
        let cookiePolicyVisible = this.state.cookiePolicyVisible;
        this.setState({cookiePolicyVisible: !cookiePolicyVisible});
        store.set('cookie-policy', {at: moment()});
    }
}

export default CookiePolicy;