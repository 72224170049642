import React, {Component} from 'react';

import {Form, FormGroup, Input} from 'reactstrap';
import {Redirect} from 'react-router-dom';
import store from 'store';
import homepath from '../utils/homepath';


import MyMenu from '../Menu/MyMenu';
import TopNavigation from '../TopNavigation/TopNavigation';
import CookiePolicy from '../CookiePolicy/CookiePolicy';
import url from '../utils/path';
import rmsUrl from '../utils/rmsurl';

import {
    get_css_class_and_with_variant,
    get_ui_variant,
    getStaticImage,
    retrieveLanguageValue,
    retrieveLargeImage
} from '../utils/helper';
import Strings from '../utils/strings';

import parse from 'html-react-parser';
import {css} from '@emotion/core';
import {ClipLoader} from 'react-spinners';

import {confirmAlert} from 'react-confirm-alert';

import 'react-confirm-alert/src/react-confirm-alert.css';
import './ChooseAnOffice.css';
import {isMobile} from "react-device-detect";

import Conf from '../utils/conf';
import {
    CHOOSE_A_SERVICE, CHOOSE_AN_OFFICE,
    GlobalGetMenuStateObject,
    GlobalSetMenuState, GlobalSetStateEntered
} from "../utils/components/GlobalMenuState";
import WindowResizeHandler from "../utils/components/WindowResizeHandler";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class ChooseAnOffice extends Component {

    constructor(props) {

        super(props);

//        console.log('>> props', props);
        try {

        this.state = {
            backgroundImage: '',
            dimensions: null,
            service: '',
            serviceCategory: '',
            dropdownValue: '',
            selectedCategoryServices: [],
            services: [],
            offices: [],
            serviceData: '',
            serviceName: null,
            selectedOffice: '',
            uniqueServiceId: null,
            sessionId: null,
            officeLoading: false,
            language: this.props.state.language,
            selectedImage: store.get('selectedImage'),
            canContinue: false,
            goHome: false
        }
        } catch (e) {
            console.log('EXCEPTION', e);
            this.state = {
                goHome: true
            }
        }


        this.handleWindowChange = this.handleWindowChange.bind(this);
        this.changeCategory = this.changeCategory.bind(this);
        this.changeService = this.changeService.bind(this);
        this.changeOffice = this.changeOffice.bind(this);
        this.selectTimeClick = this.selectTimeClick.bind(this);
    }


    /* changeValue(e) sets the currently clicked item as the selected service */
    changeCategory(e) {
        const selectedCategory = e.currentTarget.value;
        this.setState({
            serviceCategory: selectedCategory
        });

        this.setCategorySelected(selectedCategory);
    }

    changeService(e) {
        const selectedService = e.currentTarget.value;
        this.setState({
            service: selectedService
        });
        this.retrieveOffices(selectedService);
    }

    changeOffice(e) {
        const selectedOffice = e.currentTarget.value;
//        console.log('>> selectedOffice', selectedOffice);

        if (selectedOffice === 'XXXXX') {
            this.setState({
                selectedOffice: selectedOffice,
                canContinue: false
            });
        } else {
            this.setState({
                selectedOffice: selectedOffice,
                canContinue: true
            });
            this.getServiceData(this.state.service, selectedOffice);
        }
    }

    setCategorySelected(selectedCategory) {
        var list = this.state.services.filter((value) => {
            return retrieveLanguageValue(this.props.state.language, value.name) === selectedCategory ? value : '';
        });

        this.setState({
            selectedCategoryServices: list
        });

        const currentService = list[0].services[0].id;
        this.setState({
            service: currentService
        });

        this.retrieveOffices(currentService);
    }

    selectTimeClick(e) {
        e.preventDefault();
        this.retrieveServiceIdFromServer(this.state.service, this.state.selectedOffice);
    }

    cleanData = () => {
//        console.log('>> cleanData() << ');
        store.remove("Calendar");
        store.remove("TimeSelect");
        store.remove("ChangeDate")
        store.remove('EnterInformation');
        store.remove('CheckInformation');
        store.remove('selectedImage'); // clear all existing data
        store.remove('ChooseAnOffice');
        store.remove('logoutUrl');
    }

    retrieveOffices(selectedService) {
        /* retrieve all offices that provide the selected service */
        fetch(url.concat('/service').concat(rmsUrl).concat('/available-service-providers'), {
            method: 'post',
            headers: {'Content-Type': 'application/json; charset=utf-8', 'Accept': 'application/json; charset=utf-8'},
            body: JSON.stringify({'id': selectedService}),
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            if (response.status === 404) {
                confirmAlert({
                    message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.noAvailableServiceProvidersFound),
                    buttons: [{label: retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.ok)}],
                    closeOnEscape: true,
                    closeOnClickOutside: true,
                });
            }
            throw response.json();
        }).then(offices => {

            if (offices.length === 1) {

                this.setState({
                    offices: offices,
                    selectedOffice: offices[0].id,
                    canContinue: true
                });

                this.getServiceData(selectedService, offices[0].id);

            } else {
                let choose = {
                    "id": "XXXXX",
                    "name": {
                        "valueDefault":"Valitse toimipiste",
                        "valueFinnish":"Valitse toimipiste",
                        "valueSwedish":"Välj serviceställe",
                        "valueEnglish":"Select service location"
                    },
                    "allowFeedback": null
                }

                offices.unshift(choose);

                this.setState({
                    offices: offices,
                    selectedOffice: offices[0].id
                });

                this.getServiceData(selectedService, offices[1].id);

            }


        }).catch(error => {
            return error;
        })
    }

    getServiceData(service, serviceProvider) {
        service = encodeURIComponent(service);
        let serviceUrl = url.concat('/service').concat(rmsUrl).concat('?serviceId='.concat(service).concat('&serviceProviderId=').concat(serviceProvider));
        store.set('serviceUrl', serviceUrl);

        fetch(serviceUrl, {
            method: 'get',
            headers: {'Content-Type': 'application/json; charset=utf-8', 'Accept': 'application/json; charset=utf-8'},

        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            if (response.status === 404) {
                confirmAlert({
                    message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.noAvailableServiceProvidersFound),
                    buttons: [{label: retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.ok)}],
                    closeOnEscape: true,
                    closeOnClickOutside: true,
                });
            }
            throw response.json();
        }).then(data => {
            this.setState({
                serviceName: data.name,
                serviceData: data.instructions,
                backgroundImage: data.backgroundImage
            });
        }).catch(error => {
            return error;
        });

    }

    retrieveServiceIdFromServer(serviceId, serviceProvider) {
        fetch(url.concat('/service').concat(rmsUrl).concat('/login'), {
            method: 'post',
            headers: {'Content-Type': 'application/json; charset=utf-8', 'Accept': '*/*; charset=utf-8'},
            body: JSON.stringify(
                {
                    'service': {
                        'id': serviceId
                    },
                    'serviceProvider': {
                        'id': serviceProvider
                    }
                }
            )
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            if (response.status === 404) {
                confirmAlert({
                    message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.noAvailableServicesFound),
                    buttons: [{label: retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.ok)}],
                    closeOnEscape: true,
                    closeOnClickOutside: true,
                });
            }
            throw response.json();
        }).then(id => {
            this.cleanData();
            store.set('selectedImage', this.state.selectedImage);
            this.props.setSessionId(id.sessionId);

            let state = this.props.globalGetNavigationState();
            state.loggedIn = true;
            this.props.globalSetNavigationState(state);

            this.setState({
                uniqueServiceId: id.serviceId,
                sessionId: id.sessionId
            });
            store.set('uniqueServiceId', id.serviceId);

        }).catch(error => {
            console.log('retrieveServiceIdFromServer(): error', error);
            return error;
        })
    }

    componentDidMount() {

        if (this.state.goHome === true) {
            return;
        }

        GlobalSetStateEntered(CHOOSE_AN_OFFICE);
        GlobalSetMenuState(CHOOSE_AN_OFFICE, {accessed: true})

        let menuStateObject = GlobalGetMenuStateObject();
//        console.log('>>> menuStateObject', menuStateObject);

        if (menuStateObject.states[CHOOSE_A_SERVICE].completed === false) {
            this.setState({
                goHome: true
            })
            console.log('Previous state not completed: CHOOSE_A_SERVICE -> redirect home!')
            return;
        }

        window.scrollTo(0, 0);

        /* Retrieve data (selected service and its category) passed down to current
         * component from prev page */

        let state = this.props.globalGetNavigationState();
        const serviceId = state.serviceId;
        const serviceCategory = state.serviceCategory;

        this.retrieveOffices(serviceId);
        this.setState({
            service: serviceId,
            serviceCategory: serviceCategory
        });

        /* fetch all available services */
        let service = this.props.state.services;
        if (service == null) {
            confirmAlert({
                message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.noAvailableServicesFound),
                buttons: [{label: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.reloadPage)}],
                closeOnEscape: false,
                closeOnClickOutside: false,
            });
            return;
        }
        
        let allServices = service.serviceGroups.map(svs => {
            return svs;
        });

        let otherServices = {
            name: {
                valueDefault: "Muut palvelut",
                valueFinnish: "Muut palvelut",
                valueSwedish: "Andra tjänster",
                valueEnglish: "Other Services",
            },
            services: service.otherServices
        }

        allServices.push(otherServices);

        this.setState({
            services: allServices
        });

        /* Filter out and display all services within selected service category */
        let servicesList = allServices.filter((value) => {
            return retrieveLanguageValue(this.props.state.language, value.name) === serviceCategory ? value : '';
        });

        this.setState({
            selectedCategoryServices: servicesList
        });

        /* Filter out the selected service and set as selected service from among
         * all services that fall within the selected service category */
        servicesList.forEach((value) => {
            value.services.forEach(data => {
                if (data.name.valueDefault === serviceId) {
                    this.setState({
                        service: data.name.valueDefault //retrieveLanguageValue(this.props.state.language, data.name)
                    });
                }
            });
        });
    }

    componentWillUnmount() {
        store.set('selectedImage', this.state.selectedImage);
    }

    renderWindowResizeHandler(callBack) {
        return (
            <>
                <WindowResizeHandler callBack={callBack}/>
            </>
        )
    }

    handleWindowChange(dimensions) {
//        console.log('dimensions: ', dimensions);
        this.setState({
            dimensions: dimensions
        })
    }


    render() {

        if (this.state.goHome === true) {
            console.log('>>> No sessionId, window.location.assign ', homepath);
            window.location.assign(homepath);
            return "";
        }

        let img = '';
        if (get_ui_variant() === 'ISLAB') {
            if (this.state.backgroundImage) {
                img = 'data:image/' + this.state.backgroundImage.type + ';base64,' + this.state.backgroundImage.data;
            } else {
//                console.log('Logo not loaded yet!');
            }
        } else {
            if (this.state.selectedImage) {
                img = getStaticImage(this.state.selectedImage);
            } else if (this.state.data) {
                let service = this.state.data.service;
                let largeImage = retrieveLargeImage(service.name.valueDefault);
                img = getStaticImage(largeImage);
            }
        }

        const categoryServices = this.state.selectedCategoryServices;
        const serviceOffices = this.state.offices;

        if (this.state.uniqueServiceId !== null && this.state.sessionId !== null) {
            let selectedOffice, selectedOfficeId, selectedService;
            for (let i = 0; i < serviceOffices.length; i++) {
                if (serviceOffices[i].id === this.state.selectedOffice) {
                    selectedOffice = serviceOffices[i].name;
                    selectedOfficeId = serviceOffices[i].id;
                }
            }
            let catServices = categoryServices[0].services;
            for (let i = 0; i < catServices.length; i++) {
                if (catServices[i].id === this.state.service) {
                    selectedService = catServices[i].name;
                }
            }

            store.set('serviceData', JSON.stringify({
                serviceId: this.state.uniqueServiceId,
                sessionId: this.state.sessionId,
                selectedService: selectedService,
                selectedOffice: selectedOffice,
                selectedOfficeId: selectedOfficeId
            }));

            GlobalSetMenuState(CHOOSE_AN_OFFICE, {completed: true});

            return (
                <Redirect push to={{
                    pathname: '/choose-a-time',
                    state: {
                        serviceId: this.state.uniqueServiceId,
                        sessionId: this.state.sessionId,
                        selectedService: selectedService,
                        selectedOffice: selectedOffice,
                    }
                }}/>

            );
        }

        let descriptiveServiceName;
        if (this.state.canContinue === false) {
//            console.log('>>> this', this);
            if (this.state.serviceName !== null) {
                descriptiveServiceName = retrieveLanguageValue(this.state.language, this.state.serviceName);
            }
        } else {
            if (categoryServices[0] !== null && categoryServices[0] !== undefined) {
                let catServices = categoryServices[0].services;
                for (let i = 0; i < catServices.length; i++) {
                    if (catServices[i].id === this.state.service) {
                        descriptiveServiceName = catServices[i].name;
                    }
                }
            }
        }


        let isMobileReal = isMobile;
        if (!isMobile) {
            if (window.innerWidth < this.props.state.mobileBreakPoint) {
                isMobileReal = true;
            }
        }

        function get_info_text(t) {
            if (t.state.canContinue === false) {
                return "";
            }
            let textHtml = "";
            if (t.state.serviceData) {
                let infoText = retrieveLanguageValue(t.props.state.language, t.state.serviceData);
                if (infoText) {
                    try {
                        textHtml = parse(infoText);
                    } catch (e) {
                        console.log('Info text can not be parsed, invalid HTML below >>>');
                        console.log(infoText);
                        console.log('<<< EOF INVALID HTML');
                        textHtml = parse("<strong>INFO TEKSTIN HTML-MUOTOILU VIRHEELLINEN!<strong>");
                    }
                }
            }
            return textHtml;
        }

        let service = this.props.state.services;
        if (isMobileReal) {
            return (
                <div>
                    <TopNavigation globalGetNavigationState={this.props.globalGetNavigationState} globalSetNavigationState={this.props.globalSetNavigationState} globalLang={this.props.globalLang} setSessionId={this.props.setSessionId}
                                   dimensions={this.state.dimensions}
                                   state={this.props.state}/>
                    <MyMenu state={this.props.state} name="chooseAnOffice" dimensions={this.state.dimensions} />
                    {this.renderWindowResizeHandler(this.handleWindowChange)}
                    <div className="mobile-choose-office-wrapper">
                        <div className="mobile-office-wrapper">
                            <div className="mobile-office-form">
                                <div className="mobile-office">
                                    <Form>
                                        <div
                                            className="mobile-office-title">{retrieveLanguageValue(this.props.state.language, Strings.chooseOffice.selectedService)}</div>
                                        {this.state.officeLoading ?
                                            <div className='sweet-loading'
                                                 style={{display: 'flex', alignSelf: 'center', marginTop: '30px'}}>
                                                <ClipLoader
                                                    css={override}
                                                    sizeUnit={"px"}
                                                    size={50}
                                                    color={'#1e6fca'}
                                                    loading={this.state.officeLoading}
                                                />
                                            </div> :
                                            <b>
                                                <p className="text-style-head">{retrieveLanguageValue(this.props.state.language, descriptiveServiceName)}</p>
                                            </b>
                                        }
                                        {this.state.officeLoading ?
                                            <div className='sweet-loading'
                                                 style={{display: 'flex', alignSelf: 'center', marginTop: '30px'}}>
                                                <ClipLoader
                                                    css={override}
                                                    sizeUnit={"px"}
                                                    size={50}
                                                    color={'#1e6fca'}
                                                    loading={this.state.officeLoading}
                                                />
                                            </div> :
                                            <div></div>
                                        }
                                    </Form>
                                    <Form>
                                        <div
                                            className="mobile-office-title">{retrieveLanguageValue(this.props.state.language, Strings.chooseOffice.selectOffice)}</div>
                                        <p>{retrieveLanguageValue(this.props.state.language, Strings.chooseOffice.selectOfficeNote)}</p>
                                        {this.state.officeLoading ?
                                            <div className='sweet-loading'
                                                 style={{display: 'flex', alignSelf: 'center', marginTop: '30px'}}>
                                                <ClipLoader
                                                    css={override}
                                                    sizeUnit={"px"}
                                                    size={50}
                                                    color={'#1e6fca'}
                                                    loading={this.state.officeLoading}
                                                />
                                            </div> :
                                            <FormGroup className="dropdown-top">
                                                <Input type="select" name="select" id="office2-first-dropdown"
                                                       onChange={this.changeOffice} value={this.state.selectedOffice}>
                                                    {serviceOffices.map((office) => {
                                                        return <option key={office.id}
                                                                       value={office.id}>{retrieveLanguageValue(this.props.state.language, office.name)}</option>
                                                    })}
                                                </Input>
                                            </FormGroup>
                                        }
                                        {this.state.canContinue === true ?
                                            <button className={get_css_class_and_with_variant('mobile-office-button')}
                                                    onClick={this.selectTimeClick}>{retrieveLanguageValue(this.props.state.language, Strings.chooseOffice.selectTime)}</button>
                                            :
                                            <button className={get_css_class_and_with_variant('mobile-office-button-disabled')} disabled={true}>{retrieveLanguageValue(this.props.state.language, Strings.chooseOffice.selectTime)}</button>
                                        }
                                    </Form>
                                </div>
                            </div>

                            <div
                                className="mobile-office-lower-block-header">{retrieveLanguageValue(this.props.state.language, Strings.chooseOffice.selectedServiceInfo)}</div>

                            <div className="mobile-office-desc-text">
                                <span className="mobile-text-style-head">{retrieveLanguageValue(this.state.language, this.state.serviceName)}</span>
                                <span>
                                   {get_info_text(this)}
                                </span>
                            </div>
                            <CookiePolicy state={this.props.state} cookiePolicyExpiration={service != null ? service.cookiePolicyExpiration : null} cookiePolicy={service != null ? service.cookiePolicy : null} cookiePolicyTitle={service != null ? service.cookiePolicyTitle : null}/>
                        </div>
                    </div>
                </div>
            );
        } else {
            // ISLAB
            if (Conf.name === 'ISLAB') {

                return (
                    <div>
                        <TopNavigation globalGetNavigationState={this.props.globalGetNavigationState} globalSetNavigationState={this.props.globalSetNavigationState} globalLang={this.props.globalLang} setSessionId={this.props.setSessionId}
                                       state={this.props.state}/>
                        <div id={'islab-color-' + get_ui_variant()}>
                            <MyMenu state={this.props.state}/>
                        </div>

                        {this.renderWindowResizeHandler(this.handleWindowChange)}

                        <div className="choose-office-wrapper">
                            <div className="office-wrapper">

                                <img src={img} className="choose-office-top-image" alt="[LOGO NOT SET]"/>
                                <div className="office-form">

                                    <div className="office-upper-block">
                                        <div className="office1">
                                            <div
                                                className="office1-title">{retrieveLanguageValue(this.props.state.language, Strings.chooseOffice.selectedService)}</div>

                                            <div className="text-style-head">
                                                <b>
                                                    <p className="text-style-head">{retrieveLanguageValue(this.state.language, this.state.serviceName)}</p>
                                                </b>
                                            </div>

                                            <Form>
                                                <div
                                                    className="office1-title">{retrieveLanguageValue(this.props.state.language, Strings.chooseOffice.selectOffice)}</div>
                                                <p>{retrieveLanguageValue(this.props.state.language, Strings.chooseOffice.selectOfficeNote)}</p>
                                                {this.state.officeLoading ?
                                                    <div className='sweet-loading' style={{
                                                        display: 'flex',
                                                        alignSelf: 'center',
                                                        marginTop: '30px'
                                                    }}>
                                                        <ClipLoader
                                                            css={override}
                                                            sizeUnit={"px"}
                                                            size={50}
                                                            color={'#1e6fca'}
                                                            loading={this.state.officeLoading}
                                                        />
                                                    </div> :
                                                    <FormGroup className="dropdown-top">
                                                        <Input type="select" name="select" id="office2-first-dropdown"
                                                               onChange={this.changeOffice}
                                                               value={this.state.selectedOffice}>
                                                            {serviceOffices.map((office) => {
                                                                return <option key={office.id}
                                                                               value={office.id}>{retrieveLanguageValue(this.props.state.language, office.name)}</option>
                                                            })}
                                                        </Input>
                                                    </FormGroup>
                                                }
                                                {this.state.canContinue === true ?
                                                    <button className={get_css_class_and_with_variant('office-button')}
                                                            disabled={false}
                                                            onClick={this.selectTimeClick}>
                                                        {retrieveLanguageValue(this.props.state.language, Strings.chooseOffice.selectTime)}
                                                    </button>
                                                    :
                                                    <button className={get_css_class_and_with_variant('office-button-disabled')}
                                                            disabled={true}
                                                            onClick={this.selectTimeClick}>
                                                        {retrieveLanguageValue(this.props.state.language, Strings.chooseOffice.selectTime)}
                                                    </button>
                                                }
                                            </Form>

                                        </div>

                                        <div className="office2">
                                            <div
                                                className="office2-title">{retrieveLanguageValue(this.props.state.language, Strings.chooseOffice.selectedServiceInfo)}</div>
                                            <div className="office-desc-text">
                                                <p className="text-style-head">{retrieveLanguageValue(this.state.language, this.state.serviceName)}</p>
                                                <span>
                                                    {get_info_text(this)}
                                                </span>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                );
            }

            // OTHERS
            else {
                return (
                    <div>
                        <TopNavigation globalGetNavigationState={this.props.globalGetNavigationState} globalSetNavigationState={this.props.globalSetNavigationState} globalLang={this.props.globalLang} setSessionId={this.props.setSessionId}
                                       state={this.props.state}/>
                        <MyMenu state={this.props.state}/>

                        <div className="choose-office-wrapper">

                            <div className="office-wrapper">

                                <img src={img} className="choose-office-top-image" alt=""/>

                                <div className={get_css_class_and_with_variant('office-form')}>

                                    <div className="office-upper-block">
                                        <div className="office1">

                                            {
                                                this.state.serviceCategory ?
                                                    <div>
                                                        <div
                                                            className="office1-title">{retrieveLanguageValue(this.props.state.language, Strings.chooseOffice.selectedServiceGroup)}</div>
                                                        <div className="text-style-head">
                                                            <b>
                                                                <p className="text-style-head">{this.state.serviceCategory}</p>
                                                            </b>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }

                                            <div
                                                className="office1-title">{retrieveLanguageValue(this.props.state.language, Strings.chooseOffice.selectedService)}</div>
                                            <div className="text-style-head">
                                                <b>
                                                    <p className="text-style-head">{retrieveLanguageValue(this.state.language, this.state.serviceName)}</p>
                                                </b>
                                            </div>

                                        </div>

                                        <div className="office2">
                                            <Form>
                                                <div
                                                    className="office2-title">{retrieveLanguageValue(this.props.state.language, Strings.chooseOffice.selectOffice)}</div>
                                                <p>{retrieveLanguageValue(this.props.state.language, Strings.chooseOffice.selectOfficeNote)}</p>
                                                {this.state.officeLoading ?
                                                    <div className='sweet-loading' style={{
                                                        display: 'flex',
                                                        alignSelf: 'center',
                                                        marginTop: '30px'
                                                    }}>
                                                        <ClipLoader
                                                            css={override}
                                                            sizeUnit={"px"}
                                                            size={50}
                                                            color={'#1e6fca'}
                                                            loading={this.state.officeLoading}
                                                        />
                                                    </div> :
                                                    <FormGroup className="dropdown-top">
                                                        <Input type="select" name="select" id="office2-first-dropdown"
                                                               onChange={this.changeOffice}
                                                               value={this.state.selectedOffice}>
                                                            {serviceOffices.map((office) => {
                                                                return <option key={office.id}
                                                                               value={office.id}>{retrieveLanguageValue(this.props.state.language, office.name)}</option>
                                                            })}
                                                        </Input>
                                                    </FormGroup>
                                                }
                                                {this.state.canContinue === true ?
                                                    <button className={get_css_class_and_with_variant('office-button')}
                                                            disabled={false}
                                                            onClick={this.selectTimeClick}>
                                                        {retrieveLanguageValue(this.props.state.language, Strings.chooseOffice.selectTime)}
                                                    </button>
                                                    :
                                                    <button className={get_css_class_and_with_variant('office-button-disabled')}
                                                            disabled={true}
                                                            onClick={this.selectTimeClick}>
                                                        {retrieveLanguageValue(this.props.state.language, Strings.chooseOffice.selectTime)}
                                                    </button>
                                                }
                                            </Form>
                                        </div>
                                    </div>
                                    <div className="office-lower-block-DVV">

                                        <p className="text-style-head">{retrieveLanguageValue(this.props.state.language, Strings.chooseOffice.selectedServiceInfo)}</p>

                                        <div className="office-desc-text">
                                            <p className="text-style-head">{retrieveLanguageValue(this.state.language, this.state.serviceName)}</p>
                                            <span>
                                                {get_info_text(this)}
                                            </span>
                                        </div>
                                    </div>
                                    <CookiePolicy state={this.props.state} cookiePolicyExpiration={service != null ? service.cookiePolicyExpiration : null} cookiePolicy={service != null ? service.cookiePolicy : null} cookiePolicyTitle={service != null ? service.cookiePolicyTitle : null}/>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }
}

export default ChooseAnOffice;