import React, {Component} from 'react';
import {NavItem} from 'reactstrap';
import {NavLink, withRouter} from 'react-router-dom';
import store from 'store';
import Strings from '../utils/strings';
import {get_ui_variant, retrieveLanguageValue} from '../utils/helper';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons'

import "./MyMenu.css";

import {isMobile} from "react-device-detect";
import {
    CHECK_INFO,
    CHOOSE_A_SERVICE, CHOOSE_A_TIME, CHOOSE_AN_OFFICE,
    GlobalGetStateEntered, GlobalIsMenuStateAccessed, GlobalIsMenuStateCompleted,
} from "../utils/components/GlobalMenuState";
import WindowResizeHandler from "../utils/components/WindowResizeHandler";

class MyMenu extends Component {
    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.handleWindowChange = this.handleWindowChange.bind(this);

        this.state = {
            dimensions: null,
            collapsed: true,
        };

    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    handlePrev = (e) => {
        e.preventDefault();
        store.set('backclick', true);
//        this.props.location.state.updated = null;
        this.props.history.goBack();
    };

    handleNext = (e) => {
        e.preventDefault();
        this.props.history.goForward();
    };

    getCSS = () => {
        let css;
        if (get_ui_variant() === "ISLAB") {
            css = "navbar navbar-expand-md navbar-light head head-ISLAB"
        } else {
            css = "navbar navbar-expand-md navbar-light head"
        }
        return css;
    }


    componentDidMount() {
    }

    componentWillUnmount() {
    }

    renderWindowResizeHandler(callBack) {
        return (
            <>
                <WindowResizeHandler callBack={callBack}/>
            </>
        )
    }

    handleWindowChange(dimensions) {
        this.setState({
            dimensions: dimensions
        })
    }

    render() {
        let isMobileReal = isMobile;
        if (!isMobile) {
            if (window.innerWidth < this.props.state.mobileBreakPoint) {
                isMobileReal = true;
            }
        }


//        let menuStateObject = GlobalGetMenuStateObject();
        let menuKey = GlobalGetStateEntered();
        if (isMobileReal) {

            let menuEntry = Strings.menu[menuKey];
            return (
                <nav className={this.getCSS()}>
                    {menuKey === CHOOSE_A_SERVICE ?
                        <div></div>
                        :
                        <i style={{marginLeft: '10px', fontSize: '18px'}} onClick={this.handlePrev}>
                            <FontAwesomeIcon icon={faAngleLeft} color="#fff"/>
                        </i>
                    }
                    <div
                        className="menu-reservation-info">{retrieveLanguageValue(this.props.state.language, menuEntry)}</div>
                    {this.renderWindowResizeHandler(this.handleWindowChange)}
                </nav>
            )
        } else {
            if (this.props.name === "reviewService") {
                return (
                    <nav className={this.getCSS()}>
                        <div
                            className="menu-reservation-info">{retrieveLanguageValue(this.props.state.language, Strings.reviewService.menuTitle)}</div>
                        {this.renderWindowResizeHandler(this.handleWindowChange)}
                    </nav>
                );
            } else if (this.props.name === "confirmInformation") {
                return (
                    <nav className={this.getCSS()}>
                        <div
                            className="menu-reservation-info">{retrieveLanguageValue(this.props.state.language, Strings.confirmInfo.reservationInfo)}</div>
                        {this.renderWindowResizeHandler(this.handleWindowChange)}
                    </nav>
                );
            } else if (this.props.name === "changeReservationDate") {
                return (
                    <nav className={this.getCSS()}>
                        <div
                            className="menu-reservation-info">{retrieveLanguageValue(this.props.state.language, Strings.changeReservationDate.menuTitle)}
                            <a href="#" className="menu-reservation-info"
                               onClick={this.handlePrev}>({retrieveLanguageValue(this.props.state.language, Strings.editInfo.returnWithoutChanges)})</a>
                        </div>
                        {this.renderWindowResizeHandler(this.handleWindowChange)}
                    </nav>
                );
            } else if (this.props.name === "editInformation") {
                return (
                    <nav className={this.getCSS()}>
                        <div
                            className="menu-reservation-info">{retrieveLanguageValue(this.props.state.language, Strings.editInfo.menuTitle)}
                            <a href="#" className="menu-reservation-info"
                               onClick={this.handlePrev}>({retrieveLanguageValue(this.props.state.language, Strings.editInfo.returnWithoutChanges)})</a>
                        </div>
                        {this.renderWindowResizeHandler(this.handleWindowChange)}
                    </nav>
                );
            } else {
                /* DESKTOPVIEW */

                let menuItems = [];
                let i = 0;

                // Valitse palvelu
                if (GlobalIsMenuStateAccessed(CHOOSE_A_SERVICE)) {
                    menuItems.push(
                        <li className="nav-item equal-width-accessed" key={i}>
                            <NavLink className="nav-clickable" exact
                                     to="/">{retrieveLanguageValue(this.props.state.language, Strings.menu.chooseAService)}</NavLink>
                        </li>
                    );
                } else {
                    menuItems.push(
                        <li className="nav-item equal-width" key={i}>
                            <NavLink className="nav-link" exact
                                     to="/">{retrieveLanguageValue(this.props.state.language, Strings.menu.chooseAService)}</NavLink>
                        </li>
                    );
                }

                i++;
                // Valitse toimipiste
                if (GlobalIsMenuStateCompleted(CHOOSE_A_SERVICE)) {
                    menuItems.push(
                        <NavItem className="nav-item equal-width-accessed" key={i}>
                            <NavLink className="nav-clickable"
                                     to="/choose-an-office">{retrieveLanguageValue(this.props.state.language, Strings.menu.chooseAnOffice)}</NavLink>
                        </NavItem>
                    );
                } else {
                    menuItems.push(
                        <NavItem className="nav-item equal-width" key={i}>
                            <NavLink className="nav-link"
                                     to="/choose-an-office">{retrieveLanguageValue(this.props.state.language, Strings.menu.chooseAnOffice)}</NavLink>
                        </NavItem>
                    );
                }

                i++;
                // Valitse aika
                if (GlobalIsMenuStateCompleted(CHOOSE_AN_OFFICE)) {
                    menuItems.push(
                        <li className="nav-item equal-width-accessed" key={i}>
                            <NavLink className="nav-clickable"
                                     to="/choose-a-time">{retrieveLanguageValue(this.props.state.language, Strings.menu.chooseATime)}</NavLink>
                        </li>
                    );
                } else {
                    menuItems.push(
                        <li className="nav-item equal-width" key={i}>
                            <NavLink className="nav-link"
                                     to="/choose-a-time">{retrieveLanguageValue(this.props.state.language, Strings.menu.chooseATime)}</NavLink>
                        </li>
                    );
                }

                i++;
                // Täytä tiedot
                if (GlobalIsMenuStateCompleted(CHOOSE_A_TIME)) {
                    menuItems.push(
                        <li className="nav-item equal-width-accessed" key={i}>
                            <NavLink className="nav-clickable"
                                     to="/enter-information">{retrieveLanguageValue(this.props.state.language, Strings.menu.enterInfo)}</NavLink>
                        </li>
                    );
                } else {
                    menuItems.push(
                        <li className="nav-item equal-width" key={i}>
                            <NavLink className="nav-link"
                                     to="/enter-information">{retrieveLanguageValue(this.props.state.language, Strings.menu.enterInfo)}</NavLink>
                        </li>
                    );
                }

                i++;
                // Tarkista tiedot
                if (GlobalIsMenuStateCompleted(CHECK_INFO)) {
                    menuItems.push(
                        <li className="nav-item equal-width-accessed" key={i}>
                            <NavLink className="nav-clickable"
                                     to="/check-information">{retrieveLanguageValue(this.props.state.language, Strings.menu.checkInfo)}</NavLink>
                        </li>
                    );
                } else {
                    menuItems.push(
                        <li className="nav-item equal-width" key={i}>
                            <NavLink className="nav-link"
                                     to="/check-information">{retrieveLanguageValue(this.props.state.language, Strings.menu.checkInfo)}</NavLink>
                        </li>
                    );
                }

//                console.log('push menuItems', menuItems);
                return (
                    <nav className={this.getCSS()}>
                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon">-</span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav" style={{width: '100%'}}>
                                {menuItems}
                            </ul>
                        </div>
                        {this.renderWindowResizeHandler(this.handleWindowChange)}
                    </nav>
                );
            }
        }
    }
}

export default withRouter(MyMenu);
