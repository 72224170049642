import React, {Component} from 'react';
import MyMenu from '../Menu/MyMenu';
import CookiePolicy from '../CookiePolicy/CookiePolicy';
import store from 'store';
import TopNavigation from '../TopNavigation/TopNavigation';
import {css} from '@emotion/core';
import {ClipLoader} from 'react-spinners';
import {Redirect} from 'react-router-dom';
import url from '../utils/path';
import rmsUrl from '../utils/rmsurl';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';
import {confirmAlert} from 'react-confirm-alert';
import parse from 'html-react-parser';
import {
    get_css_class_and_with_variant,
    get_ui_variant,
    retrieveImage,
    retrieveLanguageValue,
    retrieveLargeImage
} from '../utils/helper';
import Strings from '../utils/strings';

import "./ChooseAService.css";

import {isMobile} from "react-device-detect";

import _ from 'lodash';
import {CHOOSE_A_SERVICE, GlobalSetMenuState, GlobalSetStateEntered} from "../utils/components/GlobalMenuState";
import WindowResizeHandler from "../utils/components/WindowResizeHandler";
import queryString from "query-string";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class ChooseAService extends Component {

	constructor(props) {

        super(props);

		this.state = {
			data: null,
            dimensions: null,
			frontTitle: 'front title',
			frontDesc: 'front desc',
			serviceId: '',
			toOffice: false,
			selectedImage: '',
			loading: false,
			language: props.language,
			sessionId: null,
			reservationLimit: null,
			reservationCode: null,
			logoData: null,
			logoLoaded: null,
			serviceCode: null
		}

        const parsed = queryString.parse(this.props.location.search);
        const userLang = parsed['lang'];
        if (userLang !== undefined) {
            props.globalLang(userLang);
        }
        const serviceCode = parsed['serviceCode'];
        if (serviceCode) {
			this.state.serviceCode = serviceCode;
		}

        this.selectImage = this.selectImage.bind(this);
        this.handleWindowChange = this.handleWindowChange.bind(this);
        this.loadLogoData();

    }

	loadLogoData(){
		fetch(url.concat('/images').concat(rmsUrl).concat('/front-page'))
			.then((response) => response.json())
			.then((responseJson) => {
				if (!responseJson.errorCode) {
					this.setState({ logoData : responseJson })
				}
			})
			.catch((error) => {
				console.error('>>error', error);
			})
			.finally(() => {
				this.setState({ logoLoaded : true })
			});
	}

	selectImage(e) {
		e.preventDefault();
		let data = JSON.parse(e.currentTarget.getAttribute("value"));
		this.gotoChooseOffice(data);
    }
    
    gotoChooseOffice(data) {
		store.remove('selectedImage');
		store.remove('ChooseAnOffice');
		let largeImage = retrieveLargeImage(data.img);
		store.set('selectedImage', '../images/' + largeImage);

        let state = this.props.globalGetNavigationState();

        state.serviceId = data.serviceId;
        state.serviceCategory = data.serviceCategory;

        delete state.enterInformationData;
        delete state.calendarData;

        this.props.globalSetNavigationState(state);

        GlobalSetMenuState(CHOOSE_A_SERVICE, {completed: true});

        this.props.history.push({
            pathname: '/choose-an-office',
        });	
	}

	login = (reservationCode) => {
		fetch(url.concat('/reservation').concat(rmsUrl).concat('/login'), {
			method: 'post',
			headers: {'Content-type':'application/json', 'Accept': 'application/json'},
			body: JSON.stringify({"reservationCode": reservationCode})
		}).then(response => {
			if(response.status === 200) {
				return response.json();
			}
			if(response.status === 404) {
				confirmAlert({
					message: retrieveLanguageValue(this.props.state.language, Strings.errorMessages.reservationNotFound),
					buttons: [{label: 'Ok'}],
					closeOnEscape: true,
					closeOnClickOutside: true,
				});
			}
			throw response.json();
		}).then(data => {
			this.props.setSessionId(data.sessionId);
			this.setState({
				reservationLimit: data.reservationLimit,
				sessionId: data.sessionId,
				serviceId: data.serviceId,
			});
            let state = this.props.globalGetNavigationState();
            state.loggedIn = true;
            this.props.globalSetNavigationState(state);
        }).catch(error => {
			return error;
		}).then(err => {
			this.setState({
				loginError: 'Reservation code not found'
			});
		});
	}

	componentDidMount() {
        GlobalSetStateEntered(CHOOSE_A_SERVICE);
        GlobalSetMenuState(CHOOSE_A_SERVICE, {accessed: true});

        const query = new URLSearchParams(this.props.location.search);
		let reservationCode = query.get('reservationCode');
		if(reservationCode !== null && reservationCode !== undefined) {
			this.setState({
				reservationCode: reservationCode
			});
			this.login(reservationCode);
		}
	}
	
	componentDidUpdate() {
        if (this.state.data !== null) {
            return;
        }
        
        let services = this.props.state.services;
        if (services != null) {
            this.setState({
                data: services,
                frontTitle: retrieveLanguageValue(this.props.state.language, services.title),
                frontDesc: retrieveLanguageValue(this.props.state.language, services.description),
            });

            if (this.state.serviceCode != null) {
                let serviceGroupByCode = _.find(services.serviceGroups, data => {
                    return _.some(data.services, service => service.code === this.state.serviceCode);
                });
                
                if (serviceGroupByCode) {
                    let serviceByCode = _.find(serviceGroupByCode.services, service => {
                        return service.code === this.state.serviceCode;
                    });
                    
                    if (serviceByCode) {
                        let data = {
                            serviceId: serviceByCode.id,
                            serviceCategory: retrieveLanguageValue(this.props.state.language, serviceGroupByCode.name),
                            img: serviceGroupByCode.name.valueDefault
                        }
                        console.log("Forwarding by service code", this.state.serviceCode, "to:", serviceByCode, "in:", serviceGroupByCode);
                        this.gotoChooseOffice(data);
                        return;
                    }
                }
            }
        }
    }

    renderWindowResizeHandler(callBack) {
        return (
            <>
                <WindowResizeHandler callBack={callBack}/>
            </>
        )
    }

    handleWindowChange(dimensions) {
        this.setState({
            dimensions: dimensions
        })
    }

    render() {

		if (this.state.sessionId !== null && this.state.reservationLimit !== null) {
			return (
				<Redirect to={{
					pathname: "/confirm-information",
					state: {
		            	serviceId: this.state.serviceId,
		            	sessionId: this.state.sessionId,
		            	reservationCode: this.state.reservationCode,
		            	data: null
		            }
		        }} />
			);
		}

        let isMobileReal = isMobile;
        if (!isMobile) {
            if (window.innerWidth < this.props.state.mobileBreakPoint) {
                isMobileReal = true;
            }
        }

        let service = this.state.data;

		let myservices = [];
		if (service !== null) {
			
			let serviceGroups = service.serviceGroups;
			serviceGroups.sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : (a.name.valueDefault === b.name.valueDefault) ? ((a.name.valueDefault > b.name.valueDefault) ? 1 : -1) : -1);

			serviceGroups.forEach((data, r) => {
				let img;
				let path = retrieveImage(data.id);
				img = require('../images/' + path + '.png');
				myservices.push(
					<div key={r}>
						<div className="media">
						  <img width={70} height={70} src={img} className={`align-self-start mr-3 ${isMobileReal ? 'mobile-myleft' : 'myleft'}`} alt="..."/>
						  <div className={`${isMobileReal ? 'media mobile-myright' : 'media myright'}`}>
						    <div className={`${isMobileReal ? 'mobile-myright-title' : 'myright-title'}`} key={data.name.valueDefault}>{retrieveLanguageValue(this.props.state.language, data.name)}</div>
					    	{data.services.sort().map((svs) => {
								return(
									<div className={`${isMobileReal ? 'mobile-link-style' : 'link-style'}`} key={svs.id}>
										<i><FontAwesomeIcon icon={faAngleRight} color="#fa9e5f" style={{marginRight: '5px'}}/></i>
										<a href='/' value={JSON.stringify({serviceId: svs.id, serviceCategory: retrieveLanguageValue(this.props.state.language, data.name), img: data.name.valueDefault})} onClick={this.selectImage}>{retrieveLanguageValue(this.props.state.language, svs.name)}</a>
									</div>
								);
							})}
						  </div>
						</div>
					</div>
				);
			});

			if (!_.isEmpty(service.otherServices)) {
				myservices.push(
					<div key={99}>
						<div className="media">

						  <div className={`${isMobileReal ? 'media mobile-myright' : 'media myright'}`}>

					    	{service.otherServices.sort().map((data) => {
								let imageUrl = '';
					    		if (data.icon) {
									imageUrl = 'data:image/' + data.icon.type + ';base64,' + data.icon.data;
								} else {
									imageUrl = require('../images/no_icon_data.png');
								}

								return(
									<div className={`${isMobileReal ? 'mobile-link-style' : 'link-style'}`} key={data.id}>
										<table>
											<tbody>
                                              {this.renderServiceTrWithVariant(imageUrl, data)}
											</tbody>
										</table>
									</div>
								);
							})}
						  </div>
						</div>
					</div>
				);
			}
		}
		let descriptionText = ['..'];
		let descriptionTitle = '..';
		if (!this.state.loading) {
			if (!_.isEmpty(this.state.data)) {
				descriptionText = [];
				descriptionText.push(retrieveLanguageValue(this.props.state.language, this.state.data.description));
			}
			if (!_.isEmpty(this.state.data)) {
				descriptionTitle = retrieveLanguageValue(this.props.state.language, this.state.data.title);
			}
		}

		if(isMobileReal) {
			return(
				<div>
					<TopNavigation globalGetNavigationState={this.props.globalGetNavigationState} globalSetNavigationState={this.props.globalSetNavigationState} globalLang={this.props.globalLang} setSessionId={this.props.setSessionId} state={this.props.state} />
					<div id={'islab-color-' + get_ui_variant()}>
                        <MyMenu state={this.props.state} name="chooseAService" />
                    </div>
                    {this.renderWindowResizeHandler(this.handleWindowChange)}

                    <div className="mobile-choose-a-service-main">
						<div className="mobile-jumbo-heading">{descriptionTitle}</div>
						<div className="mobile-main-desc">
							<div className="mobile-main-description">
								<div className="mobile-main-description-text">
									{descriptionText.map((i) => {
										return <p>{parse("" + i + "")}</p>
									})}
								</div>
								{this.state.data && this.state.data.accessibilityLink ? <div><a href={retrieveLanguageValue(this.props.state.language, this.state.data.accessibilityLink)}>{retrieveLanguageValue(this.props.state.language, Strings.chooseService['accessibility-document'])}</a></div> : <div></div>}
							</div>

							{this.state.loading ?
								<div className='sweet-loading' style={{display: 'flex', alignSelf: 'center', marginTop: '30px'}}>
							        <ClipLoader
							          css={override}
							          sizeUnit={"px"}
							          size={50}
							          color={'#1e6fca'}
							          loading={this.state.loading}
							        />
							     </div> :
								<div className="mobile-double">
									{myservices}
								</div>
							}
							<CookiePolicy state={this.props.state} cookiePolicyExpiration={service != null ? service.cookiePolicyExpiration : null} cookiePolicy={service != null ? service.cookiePolicy : null} cookiePolicyTitle={service != null ? service.cookiePolicyTitle : null}/>
						</div>
					</div>
				</div>
			);
		} else {
			let imageUrl = '';
            let divStyle = {};
            let divClass = '';

			if (this.state.logoLoaded) {
				if (this.state.logoData) {
					imageUrl = 'data:' + this.state.logoData.type + ';base64,' + this.state.logoData.data;
					divStyle = {
						minHeight: '500px',
						width: '100%',
						position: 'absolute',
						zIndex: '1',
						display: 'flex',
						flexFlow: 'row',
						justifyContent: 'center',
						backgroundImage: 'url(' + imageUrl + ')',
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
						alignItems: 'center'
					};
				} else {
					divClass = get_css_class_and_with_variant('jumbo-bg');
				}
			}
			return (
			<div>
                <TopNavigation dimensions={this.state.dimensions} globalGetNavigationState={this.props.globalGetNavigationState} globalSetNavigationState={this.props.globalSetNavigationState} globalBackEndError={this.props.globalBackEndError} globalLang={this.props.globalLang} setSessionId={this.props.setSessionId} state={this.props.state} />
                <div id={'islab-color-' + get_ui_variant()}>
                    <MyMenu dimensions={this.state.dimensions}  state={this.props.state} />
                </div>
                {this.renderWindowResizeHandler(this.handleWindowChange)}

                <div className="choose-a-service-main">
						<div className="wrapper">
							<div className={divClass} style={divStyle}>
								<div className={get_css_class_and_with_variant("jumbo-heading")}>{descriptionTitle}</div>

								<div className={get_css_class_and_with_variant("main-desc")}>
									<div className="main-description">
										<div className="main-description-text">
											{descriptionText.map((i) => {
												return <div key={i}>{parse("" + i + "")}</div>
											})}
										</div>
										{this.state.data && this.state.data.accessibilityLink ? <div><a href={retrieveLanguageValue(this.props.state.language, this.state.data.accessibilityLink)}>{retrieveLanguageValue(this.props.state.language, Strings.chooseService['accessibility-document'])}</a></div> : <div></div>}
									</div>

									{this.state.loading ?
										<div className='sweet-loading' style={{display: 'flex', alignSelf: 'center', marginTop: '30px'}}>
									        <ClipLoader
									          css={override}
									          sizeUnit={"px"}
									          size={200}
									          color={'#1e6fca'}
									          loading={this.state.loading}
									        />
									     </div> :
										<div>
											<div className="service-content-header">{retrieveLanguageValue(this.props.state.language, Strings.chooseService.serviceListings)}</div>
											<div className="double">
												{myservices}
											</div>
										</div>
									}
                                    <CookiePolicy state={this.props.state} cookiePolicyExpiration={service != null ? service.cookiePolicyExpiration : null} cookiePolicy={service != null ? service.cookiePolicy : null} cookiePolicyTitle={service != null ? service.cookiePolicyTitle : null}/>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}

    renderServiceTrWithVariant(imageUrl, data) {
        if (get_ui_variant() === 'DVV') {
            return (
                <tr>
                    <td>
                        <a href='/' value={JSON.stringify({serviceId: data.id, serviceCategory: retrieveLanguageValue(this.props.state.language, Strings.chooseService.otherServices), img: retrieveLanguageValue('GH', Strings.chooseService.otherServices)})} onClick={this.selectImage}>
                            <img width={70} height={70} src={imageUrl}  alt={"LOGO"}/>
                        </a>
                    </td>
                    <td>
                        <a href='/' value={JSON.stringify({serviceId: data.id, serviceCategory: retrieveLanguageValue(this.props.state.language, Strings.chooseService.otherServices), img: retrieveLanguageValue('GH', Strings.chooseService.otherServices)})} onClick={this.selectImage}>{retrieveLanguageValue(this.props.state.language, data.name)}</a>
                    </td>
                </tr>
            );
        }
        return (
            <tr>
                <td>
                    <img width={70} height={70} src={imageUrl}  alt={"LOGO"}/>
                </td>
                <td>
                    <a href='/' value={JSON.stringify({serviceId: data.id, serviceCategory: retrieveLanguageValue(this.props.state.language, Strings.chooseService.otherServices), img: retrieveLanguageValue('GH', Strings.chooseService.otherServices)})} onClick={this.selectImage}>{retrieveLanguageValue(this.props.state.language, data.name)}</a>
                </td>
            </tr>
        );
    }
}

export default ChooseAService;