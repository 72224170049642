import React, {useState} from 'react';

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import {retrieveLanguageValue} from "../utils/helper";
import Strings from "../utils/strings";

import sv from 'date-fns/locale/sv';
import fi from 'date-fns/locale/fi';
import en from 'date-fns/locale/en-US';
import {format} from "date-fns";

import WindowResizeHandler from "../utils/components/WindowResizeHandler";
import ShowTimeHeader from "./ShowTimeHeader"
import {ClipLoader} from "react-spinners";


const NewCalendarThemedCss = (props) => {

    const SHORT_VS_LONG_WIDTH = 850;

    const [slotMinTime, setSlotMinTime] = useState(props.eventData ? props.eventData.eventsEarliestTime : null);
    const [slotMaxTime, setSlotMaxTime] = useState(props.eventData ? props.eventData.eventsLatestTime : null);

    const [slotDuration, setDuration] = useState(props.eventData ? props.eventData.slotDuration : props.calendarData.slotDuration);

    const [nextFreeTimeSlot, setNextFreeTimeSlot] = useState(props.eventData && props.eventData.nextFreeTime ? new Date(props.eventData.nextFreeTime.start) : null);
    const [nextFreeTimeSlotVisible, setNextFreeTimeSlotVisible] = useState(false);

    const [browsingLimitForCustomerDate, setBrowsingLimitForCustomerDate] = useState(props.eventData ? props.eventData.browsingLimitForCustomerDate : null)

    const [calendarEvents, setCalendarEvents] = useState(props.eventData ? props.eventData.eventList : null);

    const [time, setTime] = useState(null);
    const [currentView, setCurrentView] = useState(props.calendarData.currentView);

    const [weekViewHeaderFormats, setWeekViewHeaderFormats] = useState(
        {
            dayGrid_dayHeaderFormat_weekday: window.outerWidth > SHORT_VS_LONG_WIDTH ? 'long' : 'short',
            weekView_dayHeaderFormat_weekday: window.outerWidth > SHORT_VS_LONG_WIDTH ? 'long' : 'short'
        }
    )

    const localeLanguage = props.calendarData.language;
    const service = props.calendarData.service;
    const servicePlace = props.calendarData.servicePlace;

    const now = new Date();

    const month = now.getMonth() + 1;
    const pad = month < 10 ? "0" : "";

    const day = now.getDate();
    const dayPad = day < 10 ? "0" : "";

    const validRangeStart = now.getFullYear() + "-" + pad + month + "-" + dayPad + day;  // yyyy-mm-dd
    const validRangeEnd = browsingLimitForCustomerDate;

//    console.log('validRangeStart > ', validRangeStart);
//    console.log('validRangeEnd > ', validRangeEnd);

    const handleEventClick = (e) => {
        if (e.event.extendedProps === null) {
            console.log('>> empty cell clicked!');
            return;
        }
        let eventClick = {
            slotId: e.event.extendedProps.slotId,
            startTime: e.event.extendedProps.extStartTime,
            date: e.event.extendedProps.extDate
        }
        console.log('handle event clicked1: ', eventClick);
        props.changeSelectedTime(eventClick);
    }

    const nextFreeTimeClicked = (e) => {
        console.log('nextFreeTimeClicked', e);
        props.calendarData.handleNextFreeTime(format(nextFreeTimeSlot, 'yyyy-MM-dd'), currentView);
    }

    const renderNextFreeTimeSlot = () => {
//        console.log('nextFreeTimeSlot ->', nextFreeTimeSlot);
//        console.log('nextFreeTimeSlotVisible ->', nextFreeTimeSlotVisible);

        if (nextFreeTimeSlot === null || nextFreeTimeSlotVisible === false) {
            return (
                <>
                </>
            );
        } else {
            return (
                <>
                    <div className="new-calendar-next-free-time-div">
                        <br/>
                        {retrieveLanguageValue(props.calendarData.language, Strings.generic.nextFreeTime)}
                        &nbsp;
                        <button onClick={nextFreeTimeClicked}>
                            {format(nextFreeTimeSlot, 'dd.MM.yyyy')}
                        </button>
                    </div>
                </>
            );
        }
    }

    const handleDatesSet = (datesSetArg) => {
//        console.log('>> handleDatesSet', datesSetArg);
//        console.log('>> handleDatesSet, view.type -> ', datesSetArg.view.type);

        setTime(datesSetArg.startStr);
        setCurrentView(datesSetArg.view.type);

        if (nextFreeTimeSlot !== null) {
            if (nextFreeTimeSlot > datesSetArg.end) {
//                console.log('>> handleDatesSet is after end!!', datesSetArg);
                setNextFreeTimeSlotVisible(true);
            } else {
//                console.log('>> handleDatesSet is before end!!', datesSetArg);
                setNextFreeTimeSlotVisible(false);
            }
        }
    }

    const renderEventContent = (eventInfo) => {
        switch (eventInfo.view.type) {
            default:
            case 'dayGridMonth':
                if (eventInfo.event.title === 'NO_EVENTS_FOR_DAY') {
                    return (
                        <>
                            <div className="new-calendar-daygrid-event-no-times-text">
                                {retrieveLanguageValue(props.calendarData.language, Strings.calendar.noTimes)}
                            </div>
                        </>
                    )
                } else {
                    const time = eventInfo.event.extendedProps.extStartTime + " - " + eventInfo.event.extendedProps.extEndTime;
//                  &nbsp;&nbsp;{eventInfo.event.extendedProps.extStartTime} - {eventInfo.event.extendedProps.extEndTime}
                    return (
                        <>
                            <div className="new-calendar-daygrid-events">
                                {time}
                            </div>
                        </>
                    );
                }

            case 'timeGridWeek':
                if (eventInfo.event.title === 'NO_EVENTS_FOR_DAY') {
                    return (
                        <>
                        </>
                    )
                } else {
                    const time = eventInfo.event.extendedProps.extStartTime + " - " + eventInfo.event.extendedProps.extEndTime;
                    return (
                        <>
                            <div className="new-calendar-time-grid-week-fc-event-time">
                                {time}
                            </div>
                        </>
                    )
                }
        }
    }

    const handleDimensionsCB = (dimensions) => {
        if (dimensions.width > SHORT_VS_LONG_WIDTH) {
            setWeekViewHeaderFormats({
                dayGrid_dayHeaderFormat_weekday: 'long',
                weekView_dayHeaderFormat_weekday: 'long'
            });
        } else {
            setWeekViewHeaderFormats({
                dayGrid_dayHeaderFormat_weekday: 'short',
                weekView_dayHeaderFormat_weekday: 'short'
            });
        }
    }

    // ------------

    return (
        <>
            <div className="new-calendar-container-div">
                <div>
                    <table width="100%">
                        <tbody>
                        <tr>
                            <td>
                                <div className="new-calendar-service-office">
                                    <center>
                                        <span><strong>{retrieveLanguageValue(props.calendarData.language, Strings.generic.service)}:</strong> {service}</span>
                                        <br/>
                                        <span><strong>{retrieveLanguageValue(props.calendarData.language, Strings.generic.office)}:</strong> {servicePlace}</span>
                                        {renderNextFreeTimeSlot()}
                                    </center>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="new-calendar-show-time-header">
                                    <center>
                                        <ShowTimeHeader localeLanguage={localeLanguage} view={currentView} time={time}/>
                                        <br/>
                                    </center>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <WindowResizeHandler callBack={handleDimensionsCB}/>
                {
                    calendarEvents === null
                        ?
                        <center>
                            <ClipLoader
                                sizeUnit={"px"}
                                size={50}
                                color={props.calendarData.spinnerColor}
                                loading={true}
                            />
                        </center>
                        :
                        <FullCalendar
                            buttonText={{
                                prev: "<",
                                next: ">",
                                today: retrieveLanguageValue(localeLanguage, Strings.newCalendar.today),
                                month: retrieveLanguageValue(localeLanguage, Strings.newCalendar.month),
                                week: retrieveLanguageValue(localeLanguage, Strings.newCalendar.week),
                                day: retrieveLanguageValue(localeLanguage, Strings.newCalendar.day)
                            }}
                            dayMaxEventRows={props.calendarData.dayMaxEventRows}
                            datesSet={(datesSetArg) => handleDatesSet(datesSetArg)}
                            events={calendarEvents}
                            eventClick={(e) => handleEventClick(e)}
                            eventContent={renderEventContent}
                            firstDay={props.calendarData.firstDay}
                            height={props.calendarData.height}
                            headerToolbar={{
                                start: 'prev next today',
//                        center: 'title',
                                end: 'dayGridMonth timeGridWeek',
                            }}
                            hiddenDays={props.calendarData.hiddenDays}
                            initialDate={props.calendarData.initialDate}
                            initialView={props.calendarData.initialView === null ? '' : props.calendarData.initialView}
                            lazyFetching={false}
                            locales={[fi, sv, en]}
                            locale={localeLanguage}
                            moreLinkText={retrieveLanguageValue(localeLanguage, Strings.newCalendar.moreLinksAvailable)}
                            nowIndicator
                            //                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            plugins={[dayGridPlugin, timeGridPlugin]}
                            showNonCurrentDates={props.calendarData.showNonCurrentDates}
                            slotMinTime={slotMinTime}
                            slotMaxTime={slotMaxTime}
                            slotLabelFormat={{
                                hour: '2-digit',
                                minute: '2-digit',
                                omitZeroMinute: false,
                                meridiem: 'narrow'
                            }}
                            slotDuration={slotDuration}
                            weekends={props.calendarData.weekends}
                            weekNumbers={props.calendarData.weekNumbers}
                            weekText={retrieveLanguageValue(localeLanguage, Strings.newCalendar.week)}
                            views={{
                                dayGrid: {
                                    titleFormat: {year: 'numeric', month: 'long'},
                                    dayHeaderFormat: {
                                        weekday: weekViewHeaderFormats.dayGrid_dayHeaderFormat_weekday
                                    }
                                },
                                week: {
                                    titleFormat: {week: 'short'},
                                    allDaySlot: false,
                                    dayHeaderFormat: {
                                        day: '2-digit',
                                        month: '2-digit',
                                        weekday: weekViewHeaderFormats.weekView_dayHeaderFormat_weekday
                                    }
                                }
                            }}
                            validRange={
                                {
                                    start: validRangeStart, // don't allow navigate to past this time, < button disabled
                                    end: validRangeEnd
                                }

                            }

                        />
                }

            </div>
        </>
    )

}

export default NewCalendarThemedCss;